import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useStore, useLanguage, useAuth } from '../../hooks'
import { Button, InputField } from '../UI'
import { MultiDropdown, CheckBox } from '../NewUI'
import { Feedback } from './'

interface Dictionary<T> { [key: string]: T }

const SFormView = styled.div`
	height: 30rem;
    display: grid;
	grid-template-rows: auto min-content;
	grid-gap: 1rem;
`

const SGridHolder = styled.div`
	overflow: auto;
	overflow-x: hidden;
`

const SGrid = styled.div`
    height: min-content;
    display: grid;
    grid-gap: ${p => p.theme.gridGap};
`

interface IState {
	name: string,
	lastName: string,
	extraInfo: {},
	aditionalLink: string,
}

export default function FormView(props: { feedback?: string, isEdit?: boolean, confirmHandler(name: string, lastName: string, extraInfo: {}, aditionalLink: string): void }) {
	const language = useLanguage() as any
	const [state, set] = useState<IState>({
		name: '',
		lastName: '',
		extraInfo: {},
		aditionalLink: '',
	})
	const [errorState, setErrorState] = useState({
		name: {
			valid:  true,
			displayMessage: false,
			customMessage: "name field is required"
		},
		lastName: {
			valid:  true,
			displayMessage: false,
			customMessage: "Lastname field is required"
		},
		additionalLink: {
			valid:  true,
			displayMessage: false,
			customMessage: "Additional Link Field is required"
		}
	})
	const [store] = useStore()
	const auth = useAuth()
	const unfilledFields = {};

	// Set current user values if is editing.
	useEffect(() => {
		if (props.isEdit && auth?.user) {
			set(p => ({
				...p,
				name: auth?.user?.name ?? '',
				lastName: auth?.user?.lastName ?? '',
				extraInfo: auth?.user?.extraInfo ?? {},
				aditionalLink: auth?.user?.aditionalLink ?? '',
			}))
		}
	}, [props.isEdit])

	function confirmHandler(): void {
		// Validation for extraInfo fields
		for (const field of requiredFields) {
			if ((!((field.id as string) in state.extraInfo)
			|| state.extraInfo[field.id] === undefined
				|| state.extraInfo[field.id] === '') && !errorState[field.id]) {
				errorState[field.id] = {
					valid: false, 
					displayMessage: true,
					customMessage: 'Este campo es obligatorio'
				}
			}
		}


		// If after these validations there are errors, return
		if (Object.values(errorState).filter(field => field.valid === false).length > 0) {
			return 
		} else {
		//Otherwise,continue to save the data
			props.confirmHandler(state.name, state.lastName, state.extraInfo, state.aditionalLink)
		}

	}

	function setErrors (fieldId, value, errorMessage = 'Este campo es obligatorio') {
		if (!value) {
			setErrorState(errorState => ({...errorState, 
				[fieldId]: {
					valid:  false,
					displayMessage: true,
					customMessage: errorMessage
				}
			}))
		} else {
			setErrorState(errorState => ({...errorState, 
				[fieldId]: {
					valid:  true,
					displayMessage: false,
					customMessage: ''
				}
			}))
		}	
	}

	let requiredFields = [];

	function getExtraData() {
		interface IOption {
			id: string,
			name: string,
			value: boolean
		}
		if (!store?.configurations?.userExtraInfo) return []

		// Get required fields in the form
		requiredFields = (Object.values(store?.configurations?.userExtraInfo) as any[])
		.filter(p => p.required)


		return (Object.values(store.configurations.userExtraInfo) as any[])
			.filter((info) => info.id !== 'additionalLink')
			.sort((a, b) => {
				const av = a.order ? parseFloat(a.order) : Number.MAX_SAFE_INTEGER
				const bv = b.order ? parseFloat(b.order) : Number.MAX_SAFE_INTEGER
				return av > bv ? 1 : -1
			})
			.map(info => {
				if (info.type === 'boolean') {
					if (!errorState[info.id]) errorState[info.id] = {
						valid: info.required ? false : true, 
						displayMessage: info.required ? true : false,
						customMessage: 'Este campo es obligatorio'
					}
					return(
							<CheckBox
								key={info.id}
								isRequired={info.required}
								errorState={errorState[info.id]}
								onClick={() => {
									// Validation on click
									setErrorState(p => ({ ...p, [info.id]: {
									    valid: true, 
										displayMessage: false,
										customMessage: 'Este checkbox es obligatorio'
								} }))
	
									state.extraInfo[info.id] = !(state.extraInfo[info.id] ? state.extraInfo[info.id] : false)
									set({ ...state })
								}}
								title={info.required ? info.name + ' *' : info.name}
								value={state?.extraInfo ? state.extraInfo[info.id] : false}
							/>
						)
				}

				else if (info.type === 'dropdown' && info.options) {
					if (!errorState[info.id]) errorState[info.id] = {
						valid: info.required ? false : true, 
						displayMessage: info.required ? true : false,
						customMessage: 'Elige al menos una opción'
					}
					return (
						<MultiDropdown
							key={info.id}
							isRequired={info.required}
							errorState={errorState[info.id] || {}}
							collapseText = {true}
							singleOption={!info.multi}
							title={info.required ? info.name + ' *' : info.name}
							options={Object.values(info.options).reduce((obj: Dictionary<IOption>, option: IOption) => ({
								...obj,
								[option.id]: {
									id: option.id,
									name: option.name,
									value: (state?.extraInfo && state?.extraInfo[info.id]) ? option.id in state?.extraInfo[info.id] : false
								}
							}), {}) as Dictionary<IOption>}
							clickHandler={id => {
								if (!state.extraInfo) state.extraInfo = {}
								if (!state.extraInfo[info.id]) state.extraInfo[info.id] = {}
	
								if (!errorState[info.id]) errorState[info.id] = {}
								setErrors(info.id, id, "Este campo es obligatorio")
	
								if (id in state.extraInfo[info.id]) delete state.extraInfo[info.id][id]
								else state.extraInfo[info.id][id] = id
	
								set({ ...state })
							}}
							style={{ width: '100%' }}
						/>)
				}

				else return (<InputField
					key={info.id}
					isRequired={info.required}
					errorState = {errorState[info.id] || {}}
					setErrors={setErrors}
					title={info.required ? info.name + ' *' : info.name}
					value={state.extraInfo[info.id]}
					onKeyDown={e => { if (e.keyCode === 13) confirmHandler() }}
					onChange={v => {
						setErrors(info.id, v, `El campo ${info.name} es obligatorio`)
						state.extraInfo[info.id] = v
						set({ ...state })
					}}
				/>)
			})
	}

	return (
		<SFormView>
			<SGridHolder>
				<SGrid>
					<InputField
						key="name"
						title={`${language.getText('Nombre')} *`}
						isRequired ={true}
						value={state.name}
						errorState={errorState["name"]}
						setErrors={setErrors}
						onChange={v => {
							if (!v) {
								setErrorState(p => ({ ...p, name: {
									    valid: false, 
										displayMessage: true,
										customMessage: 'El campo Nombre es obligatorio'
								} }))
							} else {
								setErrorState(p => ({ ...p, name: {
									    valid: true, 
										displayMessage: false,
										customMessage: 'El campo Nombre es obligatorio'
								} }))
							}
							set(p => ({ ...p, name: v }))}
						}
						onKeyDown={e => { if (e.keyCode === 13) confirmHandler() }}
					/>
					<InputField
						key="lastName"
						title={`${language.getText('Apellido')} * `}
						isRequired ={true}
						errorState={errorState["lastName"]}
						setErrors={setErrors}
						value={state.lastName}
						onChange={v => {
							if (!v) {
								setErrorState(p => ({ ...p, lastName: {
									    valid: false, 
										displayMessage: true,
										customMessage: 'El campo Apellido es obligatorio'
								} }))
							} else {
								setErrorState(p => ({ ...p, lastName: {
									    valid: true, 
										displayMessage: false,
										customMessage: 'El campo Apellido es obligatorio'
								} }))
							}
							set(p => ({ ...p, lastName: v }))
						}}
						onKeyDown={e => { if (e.keyCode === 13) confirmHandler() }}
					/>
					{getExtraData()}
					{store?.configurations?.additionalLinkEnable && 
					<InputField
						key="additionalLink"
						isRequired= {store?.configurations?.userExtraInfo?.additionalLink?.required}
						errorState = {errorState['additionalLink']}
						setErrors={setErrors}
						title={`${language.getText(store?.configurations?.userExtraInfo?.additionalLink.name)}`}
						value={state.aditionalLink}
						onChange={v => {
							if (!v) {
								setErrorState(p => ({ ...p, additionalLink: {
									    valid: false, 
										displayMessage: true,
										customMessage: `El campo ${store?.configurations?.userExtraInfo?.additionalLink.name} es obligatorio`
								} }))
							} else {
								setErrorState(p => ({ ...p, additionalLink: {
									    valid: true, 
										displayMessage: false,
										customMessage: 'El link adicional es un campo obligatorio'
								} }))
							}
							set(p => ({ ...p, aditionalLink: v }))
						}}
						onKeyDown={e => { if (e.keyCode === 13) confirmHandler() }}
					/>}				

					{props.feedback && <Feedback feedback={props.feedback} />}
				</SGrid>
			</SGridHolder>
			<Button onClick={confirmHandler}>{language.getText(props.isEdit ? 'Guardar' : 'Crear Usuario')}</Button>
		</SFormView>
	)
}
