import React from 'react'
import styled, { css } from 'styled-components'

import { useCompanyStands, useIsPortrait, useLanguage } from '../hooks'

import { FadeInUp, Text, TextIcon, IconButton, Image, SVG } from './UI'

import closeIcon from '../graphics/icons/close.svg'

import facebookIcon from '../graphics/icons/social-facebook.svg'
import linkedinIcon from '../graphics/icons/social-linkedin.svg'
import twitterIcon from '../graphics/icons/social-twitter.svg'
import youtubeIcon from '../graphics/icons/social-youtube.svg'
import instagramIcon from '../graphics/icons/social-instagram.svg'

import Linkify from 'react-linkify'

const Container = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
	top: 0;
	left: 0;
    z-index: 3;

    background-color: rgba(0, 0, 0, .8);

    display: grid;
    grid-template-columns: 20rem auto 15rem;
    ${p => p.isPortrait && css`
        grid-template-columns: 1fr;
        grid-template-rows: 10rem auto auto;
    `}
`

const Left = styled.div`
    border-right: .1rem solid ${p => p.theme.light};
    padding: 3rem;
`

const Logo = styled.div`
    height: 10rem;
`

const Middle = styled.div`
    display: grid;
    grid-gap: 1rem;
    padding: 3rem 2rem;
`

const MiddleContainer = styled.div`
    overflow: scroll;
`

const Right = styled.div`
    border-left: .1rem solid ${p => p.theme.light};
    padding: 1rem;
    overflow: scroll;
`

const Stands = styled.div`
    height: min-content;
    display: grid;
    grid-auto-rows: 15rem;
    grid-gap: 1rem;
`

const CloseButton = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
`

const SocialMediaContainer = styled.div`
    height: 3rem;
    display: grid;
    grid-auto-columns: 3rem;
    grid-gap: 1rem;
    grid-auto-flow: column;
`

const SocialMediaButton = styled.div`
    cursor: pointer;
    transition: .3s;
    :hover {
        transform: translateY(-.3rem);
    }

    display: grid;
    place-items: center;
    * {
        width: 70%;
        height: 70%;
    }
`

export default function Company(props) {
	const stands = useCompanyStands(props.company)
	const isPortrait = useIsPortrait()
	const company = props.company
	const language = useLanguage()

	return (
		<Container isPortrait={isPortrait}>
			<CloseButton><IconButton onClick={props.onClose} image={closeIcon} /></CloseButton>
			<Left> <FadeInUp> <Logo><Image contain url={company.logo} /></Logo> </FadeInUp> </Left>
			<MiddleContainer>
				<FadeInUp>
					<Middle>
						<Text H1 bold>{company.name}</Text>
						<div />
						<Linkify>
							<Text H3 bold>{company.description}</Text>
						</Linkify>
						<div />
						<Text H2 bold>{language.getText('Teléfono')}</Text>
						<Text H3 bold>{company.phone}</Text>
						<div />
						<Text H2 bold>Email</Text>
						<Text onClick={() => window.open(`mailto:${company.email}`)} H3 bold>{company.email}</Text>
						<div />
						<Text H2 bold>Web</Text>
						<TextIcon onClick={() => window.open(company.web)} inverse H3 bold>{company.web}</TextIcon>
						<div />
						<Text H2 bold>{language.getText('Redes')}</Text>
						<SocialMediaContainer>
							{company.facebook && <SocialMediaButton onClick={() => window.open(company.facebook)}><SVG light image={facebookIcon} /></SocialMediaButton>}
							{company.instagram && <SocialMediaButton onClick={() => window.open(company.instagram)}><SVG light image={instagramIcon} /></SocialMediaButton>}
							{company.linkedin && <SocialMediaButton onClick={() => window.open(company.linkedin)}><SVG light image={linkedinIcon} /></SocialMediaButton>}
							{company.twitter && <SocialMediaButton onClick={() => window.open(company.twitter)}><SVG light image={twitterIcon} /></SocialMediaButton>}
							{company.youtube && <SocialMediaButton onClick={() => window.open(company.youtube)}><SVG light image={youtubeIcon} /></SocialMediaButton>}
						</SocialMediaContainer>
					</Middle>
				</FadeInUp>
			</MiddleContainer>
			<Right>
				<FadeInUp>
					<Stands>{stands.map(s => <Stand key={s.id} stand={s} onClick={() => props.onStandClick(s.id)} />)}</Stands>
				</FadeInUp>
			</Right>
		</Container>
	)
}

const StandContainer = styled.div`
    display: grid;
    grid-template-rows: auto 2rem;
    background-color: ${p => p.theme.light};

    cursor: pointer;

    transition: .3s;
    :hover {
        transform: scale(1.05);
    }
`

function Stand(props) {
	const getBackgroundUrl = () => {
		if (props.stand.background) return props.stand.background
		if (props.stand.backgrounds && props.stand.backgrounds.images) return Object.values(props.stand.backgrounds.images)[0]
	}

	return (
		<StandContainer onClick={props.onClick}>
			<Image url={getBackgroundUrl()} />
			<Text center dark bold H2>{props.stand.name}</Text>
		</StandContainer>
	)
}