import React, { useRef, useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { FadeInUp, Text, TextIcon, IconButton, Image, Button, SVG } from './UI'
import closeIcon from '../graphics/icons/close.svg'
import { useUserMoreInfo, usePersonalChat, useVideoCall, useStore, useAuth, useIsPortrait, useElementsManager, useLanguage } from '../hooks'
import profileIcon from '../graphics/icons/profile.svg'
import html2canvas from 'html2canvas'

import Profile from './profile'

const Container = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 7;
    background-color: ${p => p.screenShot ? p.theme.dark : 'rgba(0, 0, 0, .8)'};
    overflow: auto;
`

const Grid = styled.div`
    display: grid;
    ${p => p.isPortrait && css`
        grid-template-rows: ${p => p.screenShot ? 'auto auto' : 'auto auto auto'};
        padding: 1rem;
        overflow-y: auto;
        padding-bottom: 10rem;
    `}
    ${p => !p.isPortrait && css`
        grid-template-columns: ${p => p.screenShot ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'};
        height: 100%;
    `}
`

const LeftContainer = styled.div`
    border-right: .1rem solid ${p => p.theme.light};
    display: grid;
`

const Left = styled.div`
    margin: auto;
    width: 100%;
    display: grid;
    grid-gap: 1rem;
`

const ProfilePicture = styled.div`
    border-radius: 50%;
    height: 15rem;
    width: 15rem;
    background-color: ${p => p.theme.light};
    margin: auto;
    overflow: hidden;
`

const Middle = styled.div`
    display: grid;
    grid-gap: .5rem;
    padding: 3rem 2rem;
`

const MiddleContainer = styled.div`
    overflow: scroll;
`

const Right = styled.div`
    display: grid;
    width: 80%;
    margin: auto;
    grid-gap: 1rem;
`

const RightContainer = styled.div`
    border-left: .1rem solid ${p => p.theme.light};
    display: grid;
`

const CloseButton = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
`

const StatusCircle = styled.div`
    background-color: ${p => p.online ? 'green' : 'gray'};
    border-radius: 50%;
    margin: .4rem;
`

const StatusRow = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-template-columns: auto 2rem;
    width: min-content;
    margin: auto;
    height: 2rem;
`

const SAditionalLink = styled.div`
    height: 20rem;
    width: 100%;
    border: .2rem solid ${p => p.theme.primary};
`

export default function UserMoreInfo(props) {
	const [videoCall, setVideoCall] = useVideoCall() // Get video call context.
	const [screenShot, setScreenShot] = useState(false)
	const eCard = useRef()
	const [userMoreInfo] = useUserMoreInfo()
	const personalChat = usePersonalChat()
	const [store] = useStore()
	const auth = useAuth()
	const user = (store.users && store.users[userMoreInfo.user.id]) ? store.users[userMoreInfo.user.id] : userMoreInfo.user
	const isPortrait = useIsPortrait()
	const language = useLanguage()
	const elementsManager = useElementsManager()

	const callHandler = () => {
		if (onACall) return
		videoCall.startCall(user.id)
	}

	useEffect(() => {
		elementsManager.setBoth(!userMoreInfo?.user?.id)
	}, [userMoreInfo?.user?.id])

	const getExtraInfo = () => {
		const components = []
		try {
			for (const extraData of Object.values(store.configurations.userExtraInfo).sort((a, b) => {
				const av = a.order ? parseFloat(a.order) : Number.MAX_SAFE_INTEGER
				const bv = b.order ? parseFloat(b.order) : Number.MAX_SAFE_INTEGER
				return av > bv ? 1 : -1
			})) {

				if (extraData.type === 'dropdown') {
					if (!user.extraInfo[extraData.id] || !extraData.options) continue
					components.push(<Text bold H2>{extraData.name}</Text>)
					Object.values(user.extraInfo[extraData.id]).forEach(id => {
						if (extraData?.options[id]) components.push(<Text bold H2>{extraData.options[id].name}</Text>)
					})
					components.push(<div />)
				}

				else if (extraData.type === 'boolean') {
					components.push(<Text bold H2>{extraData.name}</Text>)
					components.push(<Text bold H2>{user.extraInfo[extraData.id] ? 'Si' : 'No'}</Text>)
					components.push(<div />)
				}

				else {
					if (!user.extraInfo[extraData.id]) continue
					components.push(<Text bold H2>{extraData.name}</Text>)
					components.push(<Text H2>{user.extraInfo[extraData.id]}</Text>)
					components.push(<div />)
				}
			}
		} catch { }

		return components
	}

	function getECardHandler() {
		if (!eCard.current) return
		setScreenShot(true)
		const done = () => setScreenShot(false)

		setTimeout(() => {
			html2canvas(eCard.current, { scale: 1 }).then(canvas => {
				const element = document.createElement("a")
				element.href = canvas.toDataURL()
				element.download = `${user.name || ''}-vCard.png`
				document.body.appendChild(element);
				element.click();
				done()
			}).catch(e => done())
		}, 10)
	}

	const isPrivate = store?.configurations?.allowPrivateInfo && user.isPrivate

	const chatUsers = Object.keys(store.chat || {});
	const isOnline = chatUsers.find(id => id === user.id);

	const callUsers = Object.keys(store.call || {});
	const onACall = callUsers.find(id => id === user.id);

	const content = (
		<Container isPortrait={isPortrait} screenShot={screenShot}>
			<Grid isPortrait={isPortrait} screenShot={screenShot}>
				{!screenShot && <CloseButton><IconButton onClick={userMoreInfo.close} image={closeIcon} /></CloseButton>}
				<LeftContainer>
					<Left>
						<ProfilePicture>
							{
								userMoreInfo.user.profilePicture ?
									<Image url={userMoreInfo.user.profilePicture} /> :
									<SVG style={{ backgroundColor: 'gray', maskSize: '70%', webkitMaskSize: '70%' }} image={profileIcon} />
							}
						</ProfilePicture>
						<Text bold H1 center>{`${user.name} ${user.lastName}`}</Text>
						<StatusRow>
							<Text bold H2 middle center style={{ position: 'relative' }}>{user.online ? 'Online' : 'Offline'}</Text>
							<StatusCircle online={user.online} />
						</StatusRow>
					</Left>
				</LeftContainer>
				<MiddleContainer>
					{!isPrivate ?
						<Middle>
							<Text bold H2>Email</Text>
							<Text H2>{user.email}</Text>
							{getExtraInfo()}
						</Middle> : <Middle><Text bold H2>{language.getText('Perfil Privado')}</Text></Middle>
					}
				</MiddleContainer>
				{!screenShot &&
					<RightContainer>
						<Right>
							{(store.configurations.allowVideoCalls && auth.user && auth.user.id) && (onACall ? <Text middle center bold H2>{language.getText('Ocupado en llamada')}</Text> : !user.online ? <Text middle center bold H2>{language.getText('Desconectado')}</Text> : <Button onClick={callHandler} style={{ height: '2rem' }}>Video Call</Button>)}
							<Button onClick={() => {
								personalChat.openChat(user)
								userMoreInfo.close()
							}} style={{ height: '2rem' }}>Chat</Button>
							{!isPrivate && <Button onClick={() => window.open(`mailto:${user.email}`)} style={{ height: '2rem' }}>Email</Button>}
							{!isPrivate && <Button onClick={() => getECardHandler()} style={{ height: '2rem' }}>V-Card</Button>}
							{(!isPrivate && user.aditionalLink) && <SAditionalLink><iframe src={user.aditionalLink} width='100%' height='100%' frameBorder={0} /></SAditionalLink>}
						</Right>
					</RightContainer>}
			</Grid>
		</Container>
	)

	return userMoreInfo.user.id ? <Profile
		ref={eCard}
		user={userMoreInfo.user}
		onClose={() => userMoreInfo.close()}
		canVideoCall={store.configurations.allowVideoCalls && auth.user && auth.user.id && !onACall && isOnline}
		videoCallHandler={() => callHandler()}
		messageHandler={() => {
			personalChat.openChat(user)
			userMoreInfo.close()
		}}
		emailHandler={() => window.open(`mailto:${user.email}`)}
		vCardHandler={() => getECardHandler()}
	/> : null
}