import React, { useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useHistory } from 'react-router-dom'

import { URLs } from '../context/constants'

import { IconButton, Image, InputField, Text, SVG } from './UI'
import ProfileButton from './ProfileButton'
import Menu from './topbar/Menu'
import Login from './login/'

import { useAuth, useSearch, useStore, usePersonalChat, useIsPortrait, useShoppingList, useElementsManager, useLanguage, useOutsideAlerter } from '../hooks'

import { messaging } from '../firebase'

import menuIcon from '../graphics/icons/burgerMenu.svg'
import chatIcon from '../graphics/icons/chat.svg'
import backIcon from '../graphics/icons/arrow-right.svg'
import shoppingIcon from '../graphics/icons/shopping.svg'

import icons from '../graphics/icons'

import EditAdminButton from './EditAdminButton'
import PersonalChat from './PersonalChat'
import ShoppingList from './ShoppingList'

import useSound from 'use-sound'
import messageSound from '../sounds/message.wav'

import SearchBar from './topbar/SearchBar'
import Notifications from './topbar/Notifications'
import PoweredBy from '../components/PoweredBy'
import ProfileMenu from './topbar/ProfileMenu'
import Profile from './profile'

const TOPBAR = 0
const MENU = 1
const LOGIN = 2

const Container = styled.div`
    position: fixed;
    height: 2.5rem;
    left: .5rem;
	top: .5rem;
    right: ${p => p.sideMenuActive ? '20.5rem' : '.5rem'};
    transition: .3s;
    z-index: 2;
`

const SCenter = styled.div`
    position: absolute;
    height: 100%;
    width: 25rem;
    left: 50%;
    transform: translateX(-50%);
	top: .2rem;
`

const Left = styled.div`
    position: absolute;
    height: 100%;
    width: min-content;
    display: grid;
    grid-auto-flow: column;
    grid-gap: .5rem;
`

const Right = styled.div`
    position: absolute;
    right: 0;
    height: 100%;
    width: min-content;
    display: grid;
    grid-auto-flow: column;
    grid-gap: .5rem;
`

const IconButtonContainer = styled.div`
    position: relative;
    display: grid;
    background-color: ${p => p.theme.primary};
    grid-gap: 1rem;

    grid-template-columns: ${p => p.right ? 'auto 2.5rem' : '2.5rem auto'};

    padding-right: ${p => p.right ? '0' : '1rem'};
    padding-left: ${p => p.right ? '1rem' : '0'};
	border-radius: ${p => p.theme.roundCorners};

    ${p => p.isPortrait && css`
        grid-template-columns: 2.5rem;
        padding: 0;
    `}

    cursor: pointer;
	z-index: 2;

	:hover {
		background-color: ${p => p.theme.primaryHover};
	}
`

const Home = styled.div`
    display: grid;
    /* grid-template-columns: 7rem 2.5rem; */
    grid-template-columns: 7rem;
	border-radius: ${p => p.theme.roundCorners};
    background-color: ${p => p.theme.light};

	${p => p.isPortrait && css`
		position: absolute;
		top: 3.3rem;
		left: 19.9rem;
		height: 2rem;
	`}
`

const Logo = styled.div`
    cursor: pointer;
    transition: ${p => p.theme.transitionDuration};
    :hover {
        transform: scale(1.05);
    }
`

const SearchBackground = styled.div`
    background-color: ${p => p.theme.primary};
`

const UnreadMessagesHolder = styled.div`
    background-color: ${p => p.theme.light};
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
`

function getChatId(a, b) {
	return a.id > b.id ? a.id + b.id : b.id + a.id
}

export default function TopBar() {
	const [state, setState] = useState(TOPBAR)
	const history = useHistory()
	const auth = useAuth()
	const [search, setSearch] = useSearch()
	const [unreadMessages, setUnreadMessages] = useState(0)
	const [lastUnreadMessages, setLastUnreadMessages] = useState(Number.MAX_SAFE_INTEGER)
	const [store] = useStore()
	const personalChat = usePersonalChat()
	const shoppingList = useShoppingList()
	const [playMessageSound] = useSound(messageSound)
	const isPortrait = useIsPortrait()
	const elementsManager = useElementsManager()
	const language = useLanguage()
	const [notificationsState, setNotificationsState] = useState(false)
	const [profileMenuState, setProfileMenuState] = useState(false)
	const [profileState, setProfileState] = useState(false)

	const menuRef = useRef()
	useOutsideAlerter(menuRef, () => {
		if (notificationsState || MENU) toggleSideMenu('hide-all')
	})

	useEffect(() => {
		if (store.configurations.forceLogin && auth.isTrackingAuthState && !auth.user && state != LOGIN) setState(LOGIN)
	}, [state, auth.isTrackingAuthState, store.configurations.forceLogin])

	useEffect(() => {
		if (!auth.user) return

		let count = 0
		const users = getUsers();

		if (users) {
			for (let user of getUsers()) {
				const chatId = getChatId(auth.user, user)

				// Ignore open chat.
				if (personalChat.selected && user.id === personalChat.selected.id) continue

				if (store.chatGroups && chatId && chatId in store.chatGroups && store.chatGroups[chatId].messages) {
					count += Object.values(store.chatGroups[chatId].messages).filter(m => !m.read && m.user != auth.user.id).length
				}
			}
		}

		if (count != unreadMessages) setUnreadMessages(count)
	}, [store.chatGroups])

	// Play message sound.
	useEffect(() => {
		if (unreadMessages > lastUnreadMessages) playMessageSound()
		setLastUnreadMessages(unreadMessages)
	}, [unreadMessages])

	const getUsers = () => {
		if (!auth.user) return []
		if (store.users) return Object.values(store.users)
			.filter(u => u.name)
			.filter(u => u.id != auth.user.id) || {}
	}

	const hasInformationUser = () => {
		if (!auth.user) return []
		if (store.users) return Object.values(store.users)
			.filter(u => auth.user.email === u.email && (u.name && u.lastName)).length;
	}

	const sideMenuActive = personalChat.active || shoppingList.state.active

	useEffect(() => {
		elementsManager.setBoth(state === TOPBAR)
	}, [state])

	// I don't like this.
	function toggleSideMenu(menu) {
		if (menu === 'chat') {
			if (!personalChat.active && shoppingList.state.active) shoppingList.toggle()
			personalChat.toggle()
		}

		if (menu === 'shopping') {
			if (!shoppingList.state.active && personalChat.active) personalChat.toggle()
			shoppingList.toggle()
		}

		if (menu === 'notifications') {
			setNotificationsState(!notificationsState)
		}

		if (menu === 'hide-all') {
			setNotificationsState(false)
			setState(TOPBAR)
		}
	}

	const userMissingInformation = !hasInformationUser();

	return (
		<>
			{
				(state === TOPBAR || state === MENU) &&
				<Container sideMenuActive={sideMenuActive} ref={menuRef}>
					{!(isPortrait && sideMenuActive) && <Left>
						{store.configurations.showMainMenuButton &&
							<IconButtonContainer isPortrait={isPortrait} onClick={() => setState(p => p === TOPBAR ? MENU : TOPBAR)}>
								{/* <IconButton image={menuIcon} /> */}
								<SVG style={{ width: '60%', height: '60%', margin: 'auto' }} image={menuIcon} />
								{!isPortrait && <Text H2 bold middle center>{language.getText('Menu')}</Text>}
							</IconButtonContainer>}
						<Menu active={state === MENU} close={() => setState(TOPBAR)} />
						{(state === MENU || !isPortrait) && <Home isPortrait={isPortrait}>
							<Logo data-tip='Ir a Home' onClick={() => history.push(URLs.home)}><Image style={{ margin: 'auto', width: '80%' }} url='logo.png' contain /></Logo>
							{/* <IconButton onClick={() => notificationHandler()} image={chatIcon} /> */}
						</Home>}
					</Left>}
					{(!(isPortrait && sideMenuActive) && store.configurations.showGlobalSearch) &&
						<SCenter>
							<SearchBar personalChatActive={personalChat.active} />
						</SCenter>
					}
					{!(isPortrait && sideMenuActive) && <Right>
						<IconButtonContainer isPortrait={true}>
							<IconButton image={icons.notification} onClick={() => toggleSideMenu('notifications')} />
							<Notifications active={notificationsState} />
						</IconButtonContainer>

						{
							(history.location.pathname === '/pavillion' && !isPortrait) &&
							<SearchBackground style={{ height: '2.5rem', borderRadius: '.5em' }}>
								<InputField
									value={search.value}
									onChange={v => setSearch(prev => { return { ...prev, value: v } })}
									placeholder={language.getText('Filtrar')}
									style={{ height: '2.5rem', width: '6.5rem', borderRadius: '.5em', fontSize: ' 1rem', border: 'none' }}
								/>
							</SearchBackground>
						}

						<ProfileButton loginHandler={() => setState(LOGIN)} setProfileMenuState={value => setProfileMenuState(value)} active={profileMenuState}>
							<ProfileMenu
								active={profileMenuState}
								close={() => setProfileMenuState(false)}
								profileHandler={() => setProfileState(true)}
							/>
						</ProfileButton>

						{(profileState || userMissingInformation) && <Profile
							onClose={() => setProfileState(false)}
							initialView={userMissingInformation? 'edit' : undefined}
							smallWidth = {true}
						/>}

						{auth.user &&
							<IconButtonContainer isPortrait={isPortrait} onClick={() => toggleSideMenu('chat')} right>
								{unreadMessages != 0 && <UnreadMessagesHolder><Text primary center>{unreadMessages}</Text></UnreadMessagesHolder>}
								{!isPortrait && <Text H2 bold middle center>Chat</Text>}
								{/* <IconButton image={personalChat.active ? backIcon : chatIcon} /> */}
								<SVG style={{ width: '60%', height: '60%', margin: 'auto' }} image={personalChat.active ? backIcon : chatIcon} />
							</IconButtonContainer>
						}
						{auth?.user?.shoppingList &&
							<IconButtonContainer isPortrait={isPortrait} onClick={() => toggleSideMenu('shopping')} right>
								{!isPortrait && <Text H2 bold middle center>{language.getText('Carrito')}</Text>}
								{/* <IconButton image={shoppingList.state.active ? backIcon : shoppingIcon} /> */}
								<SVG style={{ width: '60%', height: '60%', margin: 'auto' }} image={shoppingList.state.active ? backIcon : shoppingIcon} />
							</IconButtonContainer>
						}
					</Right>}
					<PersonalChat active={personalChat.active} />
					<ShoppingList />
					{!isPortrait && <EditAdminButton />}
					{!isPortrait && <PoweredBy />}
				</Container>
			}

			{state === LOGIN && <Login
				forceLogin={store?.configurations?.forceLogin}
				onClose={() => setState(TOPBAR)}
			/>}
		</>
	)
}
