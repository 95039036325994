const Theme = {
    primary: '#ff7c00',
    primaryHover: '#f40000',
    alternative: '#23ffe3',
    light: '#ffff',
    darkerLight: '#f8f8f8',
    // dark: '#272838',
    dark: '#111111',
    gray: '#969696',
    medium: '#4d4d4d',

    roundCorners: '.5em',
    rounderCorners: '1.5em',
    shadow: '0 .1em .2em rgb(1,1,1,.2)',

    transitionDuration: '.3s',
}

export default Theme;