import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useHistory } from 'react-router-dom'
import { key } from 'firebase-key'

import { Text, TextIcon, FadeInUp, IconButton, Image, Button, InputField, SVG, UploadFile } from '../components/UI'
import Dropdown from '../components/Dropdown'
import ResizableContainer from '../components/ResizableContainer'
import { uploadImage, uploadDownloadableFile } from '../firebase/storage'

import icons from '../graphics/icons'
import closeIcon from '../graphics/icons/close.svg'
import plusIcon from '../graphics/icons/plus.svg'
import settingsIcon from '../graphics/icons/settings.svg'

import imageIcon from '../graphics/icons/photo.svg'
import videoIcon from '../graphics/icons/video.svg'
import webIcon from '../graphics/icons/monitor.svg'
import chatIcon from '../graphics/icons/chat.svg'

import { useStore, useParam, useUrl, useNotifications } from '../hooks'
import { URLs } from '../context/constants'
import ViewSettings from '../components/ViewSettings'

const Container = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: ${p => p.theme.dark};    
    z-index: 3;
`

const Render = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`

const CornerButton = styled.div`
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;
    transition: .3s;
`

export default function AreaEditor(props) {
	const type = useParam('type')
	const id = useParam('id')
	const [store, dispatch] = useStore()
	const history = useHistory()
	const [object, setObject] = useState({})
	const [state, setState] = useState({
		addMenu: false,
		settingsMenu: false,
		editingPosition: false,
		editingListPosition: false,
		selected: {},
		selectedPosition: { width: '20%', height: '20%', x: '50%', y: '50%' },
	})

	if (!object.id && store && store[type] && id in store[type]) setObject(store[type][id])

	const saveObject = o => {
		setObject(o)
		function getType() {
			if (type === 'stands') return 'stand'
			if (type === 'videoRooms') return 'videoRoom'
			if (type === 'pavillions') return 'pavillion'
			if (type === 'areas') return 'area'
		}
		dispatch({ type: `set-${getType()}`, payload: o })
	}

	const addButton = () => {
		const newButton = { id: key(), type: 'message', icon: 'mail.svg' }
		saveButton(newButton)
		setState({ ...state, selected: newButton, selectedType: 'button' })
		toggleMenu('settings')
	}

	const addView = () => {
		const newView = { id: key(), items: { a: { type: 'video', name: 'Nuevo Item', id: 'a' } } }
		saveView(newView)
		setState({ ...state, selected: newView, selectedType: 'view' })
		toggleMenu('settings')
	}

	const toggleMenu = m => {
		let add = false
		let settings = false

		if (m === 'add') add = !state.addMenu
		if (m === 'settings') settings = !state.settingsMenu

		setState(prev => { return { ...prev, addMenu: add, settingsMenu: settings } })
	}

	const getViews = () => {
		if (!object.views) return
		return Object.values(object.views).map(v => <ViewElement
			updatePositionHandler={v => setState({ ...state, selectedPosition: v })}
			selectHandler={() => {
				if (state.editingPosition || state.editingListPosition) return
				setState(prev => { return { ...prev, selected: v, selectedType: 'view' } })
				if (!state.settingsMenu) toggleMenu('settings')
			}}
			key={v.id}
			view={v}
			editingPosition={state.editingPosition}
			selected={state.selected && state.selected.id === v.id} />)
	}

	function getLists() {
		if (!object.views) return
		return Object.values(object.views).filter(v => v.list && state?.selected?.id === v.id).map(v => <ListElement
			key={`${v.id}_list`}
			view={v}
			updatePositionHandler={v => setState({ ...state, selectedPosition: v })}
			editingPosition={state.editingListPosition}
			selected={state?.selected?.id === v.id}
		/>)
	}

	const getButtons = () => {
		if (!object.buttons) return
		return Object.values(object.buttons).map(b => <ButtonElement
			updatePositionHandler={v => setState({ ...state, selectedPosition: v })}
			selectHandler={() => {
				if (state.editingPosition || state.editingListPosition) return
				setState(prev => { return { ...prev, selected: b, selectedType: 'button' } })
				if (!state.settingsMenu) toggleMenu('settings')
			}}
			key={b.id}
			button={b}
			editingPosition={state.editingPosition}
			selected={state.selected && state.selected.id === b.id} />)
	}

	const saveButton = b => {
		let buttons = object.buttons ? object.buttons : {}
		buttons[b.id] = buttons[b.id] ? Object.assign(buttons[b.id], b) : b
		saveObject({ ...object, buttons: buttons })
	}

	const saveView = v => {
		let view = object.views ? object.views : {}
		view[v.id] = view[v.id] ? Object.assign(view[v.id], v) : v
		saveObject({ ...object, views: view })
	}

	function setEditingPosition(v, list = false) {
		if (state.editingListPosition) saveView({ ...object.views[state.selected.id], list: { position: { x: state.selectedPosition.x, y: state.selectedPosition.y }, size: { x: state.selectedPosition.width, y: state.selectedPosition.height } } })
		if (state.editingPosition) {
			if (state.selectedType === 'button') saveButton({ ...object.buttons[state.selected.id], position: { x: state.selectedPosition.x, y: state.selectedPosition.y }, size: { x: state.selectedPosition.width, y: state.selectedPosition.height } })
			if (state.selectedType === 'view') saveView({ ...object.views[state.selected.id], position: { x: state.selectedPosition.x, y: state.selectedPosition.y }, size: { x: state.selectedPosition.width, y: state.selectedPosition.height } })
		}

		if (!v) setState(prev => { return { ...prev, editingListPosition: false, editingPosition: false, settingsMenu: true } })
		else if (list) setState(prev => { return { ...prev, editingListPosition: v, settingsMenu: !v } })
		else setState(prev => { return { ...prev, editingPosition: v, settingsMenu: !v } })
	}

	const GetBackgroundRender = () => {
		if (object.backgrounds && object.backgrounds.images) return Object.values(object.backgrounds.images)[0]
		return object.background
	}

	function isEditingPosition() { return state.editingPosition || state.editingListPosition }

	return (
		<Container>
			<Render><Image fit url={GetBackgroundRender()} /></Render>
			<CornerButton style={{ top: 0, left: isEditingPosition() ? '-2.5rem' : 0 }}><IconButton onClick={() => history.goBack()} image={closeIcon} /></CornerButton>
			<CornerButton style={{ bottom: 0, right: isEditingPosition() ? '-2.5rem' : state.settingsMenu ? '20rem' : 0 }}><IconButton onClick={() => toggleMenu('add')} image={plusIcon} /></CornerButton>
			<CornerButton style={{ top: 0, right: isEditingPosition() ? '-2.5rem' : state.settingsMenu ? '20rem' : 0 }}><IconButton onClick={() => toggleMenu('settings')} image={settingsIcon} /></CornerButton>
			<CornerButton style={{ bottom: 0, right: isEditingPosition() ? 0 : '-10rem', width: '10rem' }}><Button onClick={() => setEditingPosition(false)}>Confirmar posición</Button></CornerButton>

			<AddMenu object={object} store={store} active={state.addMenu}
				addButton={addButton}
				addView={addView}
			/>

			<SettingsMenu
				active={state.settingsMenu}
				selected={state.selected}
				selectedType={state.selectedType}
				editPositionHandler={() => setEditingPosition(true)}
				editListPositionHandler={() => setEditingPosition(true, true)}
				saveButton={saveButton}
				saveView={saveView}
				deleteButton={() => {
					let o = object
					delete o.buttons[state.selected.id]
					dispatch({ type: 'delete-ranking', payload: { id: state.selected.id } })
					saveObject(o)
					setState(prev => { return { ...prev, selected: {} } })
					toggleMenu()
				}}
				deleteView={() => {
					let o = object
					delete o.views[state.selected.id]
					saveObject(o)
					setState(prev => { return { ...prev, selected: {} } })
					toggleMenu()
				}}
			/>
			{getViews()}
			{getButtons()}
			{getLists()}
		</Container>
	)
}

const ItemBackground = styled.div`
    background-color: ${p => p.theme.dark};
    height: 100%;
`

function ButtonElement(props) {
	const url = useUrl(props.button.icon)
	return (
		<ResizableContainer
			updatePositionHandler={props.updatePositionHandler}
			position={props.button.position}
			size={props.button.panelMode ? props.button.size : undefined}
			onClick={props.selectHandler}
			resizable={props.button.panelMode}
			editable={props.selected && props.editingPosition}>
			<ItemBackground>
				<SVG style={{ position: 'relative', height: '80%', width: '80%', top: '10%', left: '10%' }} contain primary={props.selected} image={url} />
			</ItemBackground>
		</ResizableContainer>
	)
}

function ViewElement(props) {
	const getImage = () => {
		if (!props?.view?.items?.a) return
		if (props.view.items.a.type === 'image') return imageIcon
		if (props.view.items.a.type === 'video') return videoIcon
		if (props.view.items.a.type === 'vimeo') return videoIcon
		if (props.view.items.a.type === 'web') return webIcon
		if (props.view.items.a.type === 'promos') return icons.game
		if (props.view.items.a.type === 'chat') return chatIcon
	}

	return (
		<ResizableContainer
			updatePositionHandler={props.updatePositionHandler}
			position={props.view.position}
			size={props.view.size}
			onClick={props.selectHandler}
			resizable
			editable={props.selected && props.editingPosition}>
			<ItemBackground>
				<SVG style={{ position: 'relative', height: '80%', width: '80%', top: '10%', left: '10%' }} contain primary={props.selected} image={getImage()} />
			</ItemBackground>
		</ResizableContainer>
	)
}

function ListElement(props) {
	return (
		<ResizableContainer
			updatePositionHandler={props.updatePositionHandler}
			position={props?.view?.list?.position}
			size={props?.view?.list?.size}
			resizable
			editable={props.selected && props.editingPosition}
		>
			<ItemBackground>
				<Text H1 bold middle center>List</Text>
			</ItemBackground>
		</ResizableContainer>
	)
}

const SettingsMenuContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 20rem;
    background-color: rgba(0, 0, 0, .8);
    box-sizing: border-box;
    z-index: 1;

    transition: .3s;
    ${p => !p.active && css`
        transform: translateX(100%);
    `}

    padding: 2rem;
    overflow: auto;
`

const SettingsGridContainer = styled.div`
    height: 100%;
    display: grid;
    grid-gap: 1rem;
`

const ImageHolder = styled.div`
    height: 12rem;
    border: .1rem solid ${p => p.theme.light};
`

const Checkbox = styled.div`
    background-color: ${p => p.selected ? p.theme.primary : p.theme.light};
    border: .2rem solid ${p => p.theme.light};
    box-sizing: border-box;
    transition: .3s;
`

const Warning = styled.div`
    height: 2rem;
    padding: 0 1rem;
    background-color: ${p => p.theme.dark};
`

function SettingsMenu(props) {
	const [state, setState] = useState({})
	const [button, setButton] = useState({})
	const [view, setView] = useState({})
	const [viewData, setViewData] = useState('')
	const [store, dispatch] = useStore()
	const notifications = useNotifications()

	const saveViewData = v => {
		let inputValue = v
		let data = v
		if (view.items.a.type === 'video' && data.includes('?')) {
			try {
				data = (new URL(data).searchParams).get('v')
				inputValue = data
			} catch { }
		}
		if (view.items.a.type === 'chat') {
			const chatGroup = view.id in store.chatGroups ? store.chatGroups[view.id] : { id: view.id }
			chatGroup.name = v
			dispatch({ type: 'set-chatGroup', payload: chatGroup })
			data = view.id
		}

		setViewData(inputValue)
		saveView({ ...view, items: { a: { ...view.items.a, data: data } } })
	}

	const getNavegationOptions = () => {
		let options = {
			'/': { name: 'Home', id: '/', icon: 'home.svg' },
		}

		function addArea(area) {
			const id = `${URLs.area}?id=${area.id}`
			options[id] = { name: area.name, id: id, icon: 'lobby.svg' }
		}

		function addPavillion(pavillion) {
			const id = `${URLs.pavillion}?id=${pavillion.id}`
			options[id] = { name: pavillion.name, id: id, icon: 'pabellon.svg' }
		}

		function addVideoRoom(videoRoom) {
			const id = `${URLs.videoRoom}?id=${videoRoom.id}`
			options[id] = { name: videoRoom.name, id: id, icon: 'video.svg' }
		}

		if (store.pavillions) Object.values(store.pavillions).forEach(addPavillion)
		if (store.videoRooms) Object.values(store.videoRooms).forEach(addVideoRoom)
		if (store.areas) Object.values(store.areas).forEach(a => {
			if (a.id != 'home') addArea(a)
		})

		return options
	}

	function getCatalogOptions() {
		const options = {}
		if (store.stands) Object.values(store.stands).forEach(s => {
			options[s.id] = { name: s.name, id: s.id }
		})
		return options
	}

	const iconOptions = {
		'mail.svg': { name: 'Mensaje', id: 'mail.svg' },
		'lobby.svg': { name: 'Lobby', id: 'lobby.svg' },
		'home.svg': { name: 'Home', id: 'home.svg' },
		'pabellon.svg': { name: 'Pabellón', id: 'pabellon.svg' },
		'networking.svg': { name: 'Networking', id: 'networking.svg' },
		'video.svg': { name: 'Video', id: 'video.svg' },
		'download.svg': { name: 'Descarga', id: 'download.svg' },
		'info.svg': { name: 'Info', id: 'info.svg' },
		'web-programming.svg': { name: 'Web', id: 'web-programming.svg' },
		'link.svg': { name: 'Link', id: 'link.svg' },
		'shopping-cart.svg': { name: 'Shopping', id: 'shopping-cart.svg' },
		'flyer.svg': { name: 'Flyer', id: 'flyer.svg' },
		'chat.svg': { name: 'Chat', id: 'chat.svg' },
		'instagram.svg': { name: 'Instagram', id: 'instagram.svg' },
		'facebook.svg': { name: 'Facebook', id: 'facebook.svg' },
		'linkedin.svg': { name: 'Linkedin', id: 'linkedin.svg' },
		'twitter.svg': { name: 'Twitter', id: 'twitter.svg' },
		'youtube.svg': { name: 'Youtube', id: 'youtube.svg' },
		'whatsapp.svg': { name: 'Whatsapp', id: 'whatsapp.svg' },
		'star.svg': { name: 'Estrella', id: 'star.svg' },
		'like.svg': { name: 'Like', id: 'like.svg' },
	}

	const buttonOptions = {
		'message': { name: 'Mensaje', id: 'message', dataDescription: 'Emails', icon: 'mail.svg' },
		'navegation': { name: 'Navegación', id: 'navegation', dataDescription: 'Dirección', icon: 'lobby.svg' },
		'download': { name: 'Descarga', id: 'download', dataDescription: 'Link de descarga', icon: 'download.svg' },
		'link': { name: 'link Externo', id: 'link', dataDescription: 'Link', icon: 'web-programming.svg' },
		'internalLink': { name: 'Link Interno', id: 'internalLink', dataDescription: 'Link', icon: 'link.svg' },
		'chat': { name: 'Chat Directo', id: 'chat', dataDescription: 'Email del usuario', icon: 'chat.svg' },
		'ranking': { name: 'Votación', id: 'ranking', dataDescription: 'Nombre de la Votación', icon: 'like.svg' },
		'catalog': { name: 'Catálogo', id: 'catalog', dataDescription: 'Stand', icon: 'cart.svg' },
	}

	useEffect(() => {
		if (props.selectedType === 'button') setButton(props.selected)
		if (props.selectedType === 'view') {
			setView(props.selected)
			if (props.selected.items) {
				let value = props.selected.items.a.data
				if (props.selected.items.a.type === 'chat' && value in store.chatGroups) value = store.chatGroups[value].name
				setViewData(value)
			}
		}
	}, [props.selected.id])

	const getTitle = () => {
		if (!props.selected) return 'Select and Item first'
		if (props.selectedType === 'view') return 'Configuración del Panel'
		if (props.selectedType === 'button') return 'Configuración del Botón'
	}

	const saveButton = b => {
		setButton(b)
		props.saveButton(b)
	}

	const saveView = v => {
		setView(v)
		props.saveView(v)
	}

	const uploadImageHandler = async () => {
		const image = state.backgroundImage
		setState({ ...state, backgroundImage: null, loading: true })
		try {
			const id = await uploadImage(image, view.items.a.data)
			saveViewData(id)
		} catch (error) {
			notifications.notify('La imagen no puede superar los 5mb')
		}
		setState({ ...state, loading: false })
	}

	const uploadFileHandler = async () => {
		const file = state.file
		setState({ ...state, file: null, loading: true })
		const id = await uploadDownloadableFile(file)
		saveButton({ ...button, data: id })
		setState({ ...state, loading: false })
	}

	const getContent = () => {
		if (!props.selected) return

		// Button. ----------------------------------
		if (props.selectedType === 'button') {
			let result = [
				<Button key='0' style={{ height: '2rem' }} onClick={props.editPositionHandler}>Configurar posición</Button>,
				<SToggleRow key='mode' onClick={() => saveButton({ ...button, panelMode: !button.panelMode })}>
					<Checkbox selected={button.panelMode} />
					<Text bold oneline middle>Panel Invisible</Text>
				</SToggleRow>,
				<SToggleRow key='desktop' onClick={() => saveButton({ ...button, hideInDesktop: !button.hideInDesktop })}>
					<Checkbox selected={!button.hideInDesktop} />
					<SVG image={icons.monitor} />
					<Text middle H2 bold>Desktop</Text>
				</SToggleRow>,
				<SToggleRow key='mobile' onClick={() => saveButton({ ...button, hideInMobile: !button.hideInMobile })}>
					<Checkbox selected={!button.hideInMobile} />
					<SVG image={icons.phone} />
					<Text middle H2 bold>Mobile</Text>
				</SToggleRow>,
				<Text key='1' H2>Nombre</Text>,
				<InputField
					key='2'
					value={button.name}
					placeholder='Nombre del botón'
					onChange={v => saveButton({ ...button, name: v })}
				/>,
				<Text key='9' H2>Descripción</Text>,
				<InputField
					key='10'
					value={button.description}
					placeholder='Agregar descripción del botón'
					onChange={v => saveButton({ ...button, description: v })}
				/>,
				<Text key='3' H2>Tipo de botón</Text>,
				<Dropdown
					key='4'
					options={Object.values(buttonOptions)}
					selected={buttonOptions[button.type]}
					onSelect={id => saveButton({ ...button, type: id, data: '', icon: buttonOptions[id].icon })}
				/>,
				<Text key='5' H2>{buttonOptions[button.type] && buttonOptions[button.type].dataDescription}</Text>,
			]

			if (button.type === 'download') {
				result.push(state.loading ? <Text key='34jif' H2 bold>Subiendo...</Text> : <UploadFile key='f09a8g' style={{ height: '2rem', width: '15rem' }} fileHanlder={file => setState({ ...state, file: file })}>Subir archivo</UploadFile>)
				result.push(state.file && <Button key='uvhasd' onClick={uploadFileHandler} style={{ height: '2rem', width: '15rem' }}>Subir</Button>)
			}

			if (button.type === 'link' || button.type === 'message' || button.type === 'chat' || button.type === 'internalLink' || button.type === 'ranking') {
				function changeHandler(v) {
					if (button.type === 'chat') v = v.trim().toLowerCase()
					saveButton({ ...button, data: v })
				}
				result.push(<InputField key='6' value={button.data} onChange={v => changeHandler(v)} />)
			}

			if (button.type === 'navegation') {
				result.push(<Dropdown
					key='selectArea'
					options={Object.values(getNavegationOptions())}
					selected={getNavegationOptions()[button.data]}
					placeholder='Seleccionar un destino'
					onSelect={id => saveButton({ ...button, data: id, icon: getNavegationOptions()[id].icon })}
				/>)
			}

			if (button.type === 'catalog') {
				result.push(<Dropdown
					key='selectCatalog'
					options={Object.values(getCatalogOptions())}
					selected={getCatalogOptions()[button.data]}
					placeholder='Seleccionar un Stand'
					onSelect={id => saveButton({ ...button, data: id })}
				/>)
			}

			result.push(<Text key='iconSelectorTitile' H2>Seleccionar Icono</Text>)
			result.push(<Dropdown
				key='iconSelector'
				options={Object.values(iconOptions)}
				selected={iconOptions[button.icon]}
				onSelect={id => saveButton({ ...button, icon: id })}
			/>)

			result.push(<Text key='7' H2>Otros</Text>)
			result.push(<Button key='8' onClick={props.deleteButton} style={{ height: '2rem' }}>Eliminar</Button>)

			return result
		}
	}

	return (
		<SettingsMenuContainer
			active={props.active}
		>
			{props.selectedType === 'button' &&
				<SettingsGridContainer>
					<Text H2 bold>{getTitle()}</Text>
					{getContent()}
				</SettingsGridContainer>
			}

			{props.selectedType === 'view' && <ViewSettings
				editPositionHandler={props.editPositionHandler}
				editListPositionHandler={props.editListPositionHandler}
				saveView={props.saveView}
				deleteView={props.deleteView}
				view={props.selected} />}
		</SettingsMenuContainer>
	)
}

const AddMenuContainer = styled.div`
    position: absolute;
    bottom: 3rem;
    right: 0;
    width: 13rem;

    display: grid;
    grid-gap: .5rem; 

    * {
        :hover {
            transform: translateX(-.3rem);
        }

        transition: .3s;
        ${p => !p.active && css`
            transform: translateX(100%);
            pointer-events: none;
        `}
    }
`

function AddMenu(props) {
	function hasExceeded(type) {
		if (!props.store?.configurations?.limitations) return false
		const max = props.store?.configurations?.limitations[type]
		if (!max || max === '') return false

		if (!props.object[type]) return false

		const amount = Object.values(props.object[type]).length

		return amount >= max
	}

	return (
		<AddMenuContainer active={props.active}>
			{hasExceeded('buttons') ? <Warning><Text center oneline bold H2 middle>Límite Excedido</Text></Warning> : <Button style={{ padding: '.5rem', transitionDelay: '.15s' }} onClick={props.addButton}>Botón</Button>}
			{hasExceeded('views') ? <Warning><Text center oneline bold H2 middle>Límite Excedido</Text></Warning> : <Button style={{ padding: '.5rem', transitionDelay: '.1s' }} onClick={props.addView}>Panel</Button>}
		</AddMenuContainer>
	)
}

const SToggleRow = styled.div`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 2rem 2rem auto;
    height: 2rem;
    cursor: pointer;
    transition: .3s;
    :hover {
        transform: translateY(.2rem);
    }
`