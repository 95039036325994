import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { IItem } from './control-panel/Stands/Catalog'
import { useIsPortrait, useShoppingList, useParam } from '../hooks'
import { IconButton, Image, Text, Button, SVG } from './UI'
import { IShoppingList } from '../context/ShoppingListContext'

import closeIcon from '../graphics/icons/close.svg'
import upIcon from '../graphics/icons/arrow-top.svg'
import downIcon from '../graphics/icons/arrow-bottom.svg'
import shoppingIcon from '../graphics/icons/shopping.svg'

import { useHistory } from 'react-router-dom'

interface Dictionary<T> { [Key: string]: T; }

const Container = styled.div<{ isPortrait?: boolean }>`
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 3;

    background-color: rgba(0, 0, 0, .8);

    display: grid;

    grid-template-columns: 20rem auto;
    ${p => p.isPortrait && css`
        grid-template-columns: 1fr;
    `}
`

const GridContainer = styled.div<{ isPortrait?: boolean }>`
    overflow: auto;
    ${p => !p.isPortrait && css`
        grid-column: 2/3;
    `}

    padding: 2rem 1rem;
    ${p => p.isPortrait && css`
        padding: 3rem;
    `}
`

const GridPanel = styled.div<{ isPortrait?: boolean }>`
    display: grid;

    grid-gap: 1rem;
    grid-template-columns: repeat(4, 1fr);

    ${p => p.isPortrait && css`
        grid-template-columns: 1fr;
    `}
`

const CloseButton = styled.div<{ up?: boolean }>`
    position: absolute;
    top: ${p => p.up ? '-2.5rem' : 0};
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
`

interface IProps {
	stand: { id: string, name?: string, catalog?: Dictionary<IItem> },
	closeHandler(): void,
}

interface IState {
	selected?: IItem,
}

const BackgroundContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
`

export default function Catalog({ stand, closeHandler }: IProps) {
	const [state, set] = useState<IState>({})
	const shoppingList = useShoppingList() as IShoppingList
	const isPortrait = useIsPortrait()
	const itemParam = useParam('item')
	const history = useHistory()

	useEffect(() => {
		if (itemParam) {
			// Select item.
			itemClickHandler(stand.catalog[itemParam])

			// Remove param from url.
			const params = new URLSearchParams(history.location.search)
			params.delete('item')
			history.location.search = params.toString()
		}
	}, [itemParam])

	function itemClickHandler(item: IItem): void {
		set(prev => { return { ...prev, selected: item } })
	}

	function addItemHandler(amount: number): void {
		shoppingList.addItem(stand.id, state.selected.id, amount) // Add new item to shopping list.
		itemClickHandler(undefined) // Clear selected item (close the panel).
	}

	return (
		<Container isPortrait={isPortrait}>
			<CloseButton><IconButton onClick={() => closeHandler()} image={closeIcon} /></CloseButton>
			<GridContainer isPortrait={isPortrait}>
				<GridPanel isPortrait={isPortrait}>
					{(Object.values(stand.catalog) as IItem[]).map(item => <ListItem key={item.id} item={item} clickHandler={() => itemClickHandler(item)} />)}
				</GridPanel>
			</GridContainer>

			{state.selected &&
				<BackgroundContainer>
					<SelectedItem
						item={state.selected}
						closeHandler={() => itemClickHandler(undefined)}
						addItemHandler={amount => addItemHandler(amount)}
					/>
				</BackgroundContainer>
			}
		</Container>
	)
}

const SelectedItemContainer = styled.div<{ isPortrait?: boolean }>`
    display: grid;
    background-color: ${p => p.theme.light};
    position: fixed;
    width: 50vw;
    height: 60vh;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    grid-template-columns: 1fr 1fr;

    ${p => p.isPortrait && css`
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        transform: translate(0);
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    `}
    box-sizing: border-box;
`

const SelectedInfoPanel = styled.div`
    display: grid;
    grid-template-rows: auto 2.5rem;
    box-sizing: border-box;
`

const InfoPanel = styled.div`
    display: grid;
    grid-template-rows: 2.5rem 2rem auto;
    padding: 1rem;
`

const SelectedBottomBar = styled.div`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 2.5rem 3rem 2.5rem auto;
    box-sizing: border-box;
    overflow: hidden;
`

function SelectedItem({ item, closeHandler, addItemHandler }: { item: IItem, closeHandler(): void, addItemHandler(amount: number): void }) {
	const isPortrait = useIsPortrait()
	const [state, set] = useState<{
		amount: number
	}>({
		amount: 1,
	})

	function moveAmount(positive: boolean): void {
		if (!positive && state.amount <= 1) return
		set(prev => { return { ...prev, amount: prev.amount + (positive ? 1 : -1) } })
	}

	return (
		<SelectedItemContainer isPortrait={isPortrait}>
			<CloseButton up={!isPortrait}><IconButton onClick={() => closeHandler()} image={closeIcon} /></CloseButton>
			<Image url={item?.image} expand />
			<SelectedInfoPanel>
				<InfoPanel>
					<Text oneline H1 bold dark middle>{item?.name}</Text>
					<Text oneline H2 bold primary middle>${item?.price}</Text>
					<Text H3 dark>{item?.description}</Text>
				</InfoPanel>
				<SelectedBottomBar>
					<Button onClick={() => moveAmount(false)} style={{ display: 'flex' }} icon><SVG style={{ height: '60%', width: '60%', margin: 'auto' }} contain image={downIcon} /></Button>
					<Text center middle dark bold H1>{state.amount}</Text>
					<Button onClick={() => moveAmount(true)} style={{ display: 'flex' }} icon><SVG style={{ height: '60%', width: '60%', margin: 'auto' }} contain image={upIcon} /></Button>
					<Button onClick={() => addItemHandler(state.amount)}>Agregar al Carrito</Button>
				</SelectedBottomBar>
			</SelectedInfoPanel>
		</SelectedItemContainer>
	)
}

const ListItemContainer = styled.div`
    display: grid;
    grid-template-rows: 15rem 2.5rem 2.5rem;
    background-color: ${p => p.theme.light};

    transition: .3s;
    cursor: pointer;
    :hover {
        transform: scale(1.04);
    }
`

const PriceTagContainer = styled.div`
    background-color: ${p => p.theme.primary};
    display: grid;
    grid-template-columns: auto 2rem;
    padding: 0 1rem;
    grid-gap: 1rem;
`

function ListItem({ item, clickHandler }: { item: IItem, clickHandler(): void }) {
	return (
		<ListItemContainer onClick={() => clickHandler()}>
			<Image url={item.image} expand />
			<Text middle center bold dark H2>{item.name}</Text>
			<PriceTagContainer>
				<Text H2 bold middle>${item.price}</Text>
				<SVG style={{ height: '70%', width: '70%', margin: 'auto' }} contain image={shoppingIcon} />
			</PriceTagContainer>
		</ListItemContainer>
	)
}