import React, { useState, useEffect } from 'react'
import { useAuth, useStore, useElementsManager } from '../hooks'
import { useHistory } from 'react-router-dom'

import Mixpanel from '../externalAnalytics/mixPanel'

// Import interfaces for analyticsProvider.
import { IAnalyticsProvider } from '../components/control-panel/ExternalAnalytics'


const initialState = {
	logButtonClick: undefined,
	logPanelView: undefined,
	identifyUser: undefined,
	setPeopleProps: undefined
}

const ExternalAnalytics = React.createContext(initialState)

interface IPageInfo {
	page?: PageType,
	id?: string,
	pageName?: string
}

interface IUserData {
	 userId: string,
	  email: string,
	  emailVerified: boolean, 
	  name: string,
	  lastName: string,
	  creationTime: string, 
	  type: string 
}

type PageType = 'areas' | 'pavillions' | 'video-rooms' | 'stands' | 'videoRooms'

interface IExternalAnalyticsContext {
	logButtonClick(button: any): void,
	logPanelView(panel: any): void,
	identifyUser(userID: string): void,
	setPeopleProps(userData: any): void,
}

export function ExternalAnalyticsContext(props) {
    const history = useHistory()
	const auth = useAuth()
	const [store] = useStore()
	const elementsManager = useElementsManager() as any
	const [provider, setProvider] = useState<IAnalyticsProvider>(
		{ 	providerName : '',
			workspaceName: '',
			writeKey: '',
			sourceId: '',
			token: '',
			verifiedAccount: false,
		});

	// Declare providerMethods, only when provided loaded, assign values
	let providerMethods;

	// Variable for testing propuses only
	let devMode = false;


	// Depending on externalAnalytics node, set provider
	useEffect(()=> {
		if (store?.configurations?.externalAnalytics) {
			setProvider(store?.configurations?.externalAnalytics)
		}
	}, [store?.configurations?.externalAnalytics])

	// If it's in the configurations, set analytics provider
	if (!provider.providerName || store?.configurations?.disableAnalytics) {
		console.warn("There's no analytics provider set in the configurations. External analytics will not be available.");
	} else {
		if (provider.providerName === 'mixpanel' && provider.token) {
			providerMethods = new Mixpanel(provider.token);
		}
	}

	const getUserData = (): IUserData => {
		if (auth.user && auth.user.id){
			let userData = {
				userId: auth.user.id,
				email: auth.user.email,
				emailVerified: auth.user.emailVerified,
				name: auth.user.name,
				lastName: auth.user.lastName,
				creationTime: auth.user.creationTime,
				type: auth.user.type
			} 

			return userData;
		} 
	}


	function getPageInfo(): IPageInfo {
		const params = new URLSearchParams(history.location.search)
		const id = params.get('id')
		const pathname: string = history.location.pathname
		const data: IPageInfo = { id: id }

		if (id && pathname === '/area') data.page = 'areas'
		if (id && pathname === '/pavillion') data.page = 'pavillions'
		if (id && pathname === '/video-room') data.page = 'videoRooms'
		if (id && pathname === '/stand') data.page = 'stands'
		if ((!id && (pathname === '/' || pathname === '/control-panel')) || pathname === '/area-editor') {
			data.page = 'areas'
			data.id = 'home'
		}

		let pageId = data.id;
		let pageType = data.page;

		let pageName;
		
		let storeReady = store.hasOwnProperty(pageType);
		if (!storeReady) return;
		
		if (pageId && Object.keys(store[pageType] || {}).length) {
			let pageReady = (store[pageType]).hasOwnProperty(pageId);
			if (!pageReady) return 
				pageName = store?.[pageType][pageId].name ? store?.[pageType][pageId].name : '';
		} else {
			// TODO: how to get store before analytics
			// pageName = 'none'
		}
		if (pageName) data.pageName = pageName;

		return data
	}
	
	function logVisit(data: IPageInfo, userData: IUserData): void {
		if (!providerMethods || !userData || !data) return;
		let visitName;
		const { userId, email, emailVerified, name, lastName, creationTime, type } = userData
		
		if (devMode !== true) {
			// Track Page Visit
			visitName = 'Visit | ' + (data.page ? data.page : '') + ' | '+ (data.pageName ?  data.pageName : '');

			providerMethods.track(visitName, {
				page: data.page,
				id: data.id,
				pageName: data.pageName,
				userId: userId,
				email: email,
				emailVerified: emailVerified,
				name: name,
				lastName: lastName,
				creationTime: creationTime,
				userType: type
			})
			// Also track userId and create a user Profile in Mixpanel
			providerMethods.identify(`${userId}`)
			// Also set properties for that user
			providerMethods.setPeople({
				$distinct_id : userId,
				$first_name: name, 
				$last_name: lastName,
				$name: `${name} ${lastName}`,
				$email: email,
				$created: creationTime,
				$userType: type
			})
		}
	}

	/**
	 * 
	 * @param userProps object property you want to set
	 * Visit for reservated property names: 
	 * https://help.mixpanel.com/hc/en-us/articles/115004708186-What-is-the-difference-between-Event-Properties-Super-Properties-and-People-Properties-and-which-should-I-use-#reserved-properties-for-user-profiles
	 * @returns 
	 */
	function setPeopleProps (userProps: object) {
		// if no providedNethods or no userData, return
		if (!providerMethods || !userProps) return;

		if (devMode !== true) {
			providerMethods.setPeople(
				userProps
			)
		}
	}

	function identifyUser (userId: string) {
		// If no methods provided, return
		if (!providerMethods || !userId) return;

		if (devMode !== true) {
			providerMethods.identify(userId)
		}
	}

	function logPanelView (panel: any) {
		// If no methods provided, return
		if (!providerMethods) return;

		const pageData = getPageInfo()
		const userData = getUserData()
		const { userId, email, emailVerified, name, lastName, creationTime, type } = userData;

		// Check if analytics are disabled.
		if (!Object.keys(store?.configurations).length || store?.configurations?.disableAnalytics) return

		// Can't log component of non existing page.
		if (!pageData.id || !pageData.page) return

		if (devMode !== true) {
			providerMethods.track(`View panel ${panel.name}`, {
				page: pageData.page,
				id: pageData.id,
				pageName: pageData.pageName,
				panelId: panel.id,
				panelData: panel.data,
				panelType: panel.type,
				panelName: panel.name,
				userId: userId,
				email: email,
				emailVerified: emailVerified,
				name: name,
				lastName: lastName,
				creationTime: creationTime,
				userType: type
			})
		}
	}

	function logButtonClick(button: any): void {
		// If no methods provided, return
		if (!providerMethods) return;

		const pageData = getPageInfo()
		const userData = getUserData()
		const { userId, email, emailVerified, name, lastName, creationTime, type } = userData;

		// Check if analytics are disabled.
		if (!Object.keys(store?.configurations).length || store?.configurations?.disableAnalytics) return

		// Can't log component of non existing page.
		if (!pageData.id || !pageData.page) return

		if (devMode !== true) {
			providerMethods.track(`Click button ${button.name}`, {
				page: pageData.page,
				id: pageData.id,
				pageName: pageData.pageName,
				buttonId: button.id,
				buttonData: button.data || '',
				buttonDescription: button.description || '',
				buttonType: button.type,
				userId: userId,
				email: email,
				emailVerified: emailVerified,
				name: name,
				lastName: lastName,
				creationTime: creationTime,
				userType: type
			})
		}
	}

	function onNewPage(): void {
		if (!elementsManager.state.pannels || !elementsManager.state.buttons) elementsManager.setBoth(true)

		// Check if analytics are disabled.
		if (!Object.keys(store?.configurations).length || store?.configurations?.disableAnalytics) return

		const pageData = getPageInfo()
		const userData = getUserData();

		// Log new page visit.
		if (pageData.page && pageData.id) logVisit(pageData, userData as any)
	}

	useEffect(onNewPage, [history.location, auth.user?.id])


	// console.log("pageInfo data:", getPageInfo())
	// Tracking 
	
	const state: IExternalAnalyticsContext = {
		logButtonClick: logButtonClick,
		setPeopleProps: setPeopleProps,
		identifyUser: identifyUser,
		logPanelView: logPanelView
	}

    return <ExternalAnalytics.Provider value={state}>{props.children}</ExternalAnalytics.Provider>
}

export default ExternalAnalytics;
