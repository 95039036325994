import React, { useRef, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { UserMediaError, useUserMedia } from '@vardius/react-user-media'

import { useVideoCall, useAuth, useStore, useElementsManager } from '../hooks'
import { FadeInUp, Text, TextIcon, IconButton, Image, Button, SVG } from './UI'

import Loading from './Loading'
import ChatView from './ChatView'

import closeIcon from '../graphics/icons/close.svg'
import phoneIcon from '../graphics/icons/phone.svg'
import phoneRingingIcon from '../graphics/icons/phoneRinging.svg'

import useSound from 'use-sound'
import callSound from '../sounds/call.wav'
import { useLanguage } from '../hooks/index';

const Container = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    pointer-events: none;
`
const VideoCallContainer = styled.div`
    height: 100%;
    pointer-events: all;
    background-color: rgba(0, 0, 0, .9);
    display: grid;
    position: relative;
    box-sizing: border-box;

    grid-template-columns: 1fr 3fr 3fr 1fr;
    grid-template-rows: 1rem 2.5fr 2rem 2.5fr 1rem;
    grid-row-gap: 1rem;
    grid-column-gap: 3rem;
`

const CloseButton = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
`

const VideoContainer = styled.div`
    position: relative;
    border: .2rem solid ${p => p.theme.primary};
    grid-column: ${p => p.left ? '2/3' : '3/4'};
    grid-row: 2/3;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    transform: scaleX(-1);
`

const ChatContainer = styled.div`
    height: 100%;
    box-sizing: border-box;
    grid-column: 2/4;
    grid-row: 4/5;
    overflow: auto;
    position: relative;
`

const LoadingContainer = styled.div`
    position: absolute;
    transform: scaleX(-1);
    height: 100%;
    width: 100%;
`

const LoadingMessage = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 1rem;
    * { height: 100%; }
`

export default function VideoCall(props) {
    const [videoCall, setVideoCall] = useVideoCall() // Get video call context.
    const auth = useAuth() // Get current user.
    const [store, dispatch] = useStore()
    const elementsManager = useElementsManager()

    const setUserStatus = value => {
        if (auth.user && store.users[auth.user.id])
            dispatch({ type: 'set-user', payload: { ...store.users[auth.user.id], onACall: value } })
    }

    const answerHandler = () => {
        videoCall.startAnswer()
    }

    const hangupHandler = () => {
        videoCall.hangup()
        setUserStatus(false)
    }

    useEffect(() => {
        elementsManager.setBoth(!videoCall.active)
    }, [videoCall.active])

    let targetUser = { name: '', lastName: '' }
    if (store.users && videoCall.firebaseCall && auth.user) {
        const targetId = videoCall.firebaseCall.from === auth.user.id ? videoCall.firebaseCall.to : videoCall.firebaseCall.from
        if (targetId in store.users) targetUser = store.users[targetId]
    }

    return (
        <Container>
            {videoCall.active && <VideoCallPanel
                videoCall={videoCall}
                setUserStatus={setUserStatus}
                hangupHandler={hangupHandler}
                auth={auth}
                targetUser={targetUser}
            />}
            <RingingCallAlert
                user={targetUser}
                active={videoCall.firebaseCall && videoCall.firebaseCall.ringing}
                owner={videoCall.firebaseCall && videoCall.firebaseCall.from == auth?.user?.id}
                answerHandler={answerHandler}
                hangupHandler={hangupHandler}
            />
        </Container>
    )
}

function getChatId(a, b) {
    try { return a.id > b.id ? a.id + b.id : b.id + a.id } catch { return 'null' }
}

function VideoCallPanel(props) {
    const { stream, error } = useUserMedia({ audio: true, video: true }) // Get media stream.
    const [state, set] = useState({ loading: true })
    const language = useLanguage();

    const setRemoteStream = remoteStream => {
        set(prev => {
            props.setUserStatus(true)
            return { ...prev, remoteStream: remoteStream, loading: false }
        })
    }

    useEffect(() => {
        if (error) props.hangupHandler()

        if (!props.videoCall.isCalling || !stream || !props.auth?.user || !props.auth.user.id) return

        // Call target, and get stream.
        props.videoCall.call(stream, setRemoteStream, props.auth.user.id)

    }, [props.videoCall.isCalling, stream, error])

    useEffect(() => {
        if (!props.videoCall.isAnswering || !stream) return

        props.videoCall.answer(stream, setRemoteStream)
    }, [props.videoCall, stream, error])

    useEffect(() => {
        if (!props.videoCall.firebaseCall && !props.videoCall.isCalling) props.hangupHandler()
    }, [props.videoCall.firebaseCall])

    return (
        <VideoCallContainer>
            <CloseButton><IconButton onClick={props.hangupHandler} image={closeIcon} /></CloseButton>
            {props.auth.user && <Text H2 bold center style={{ gridColumn: '2/3', gridRow: '3/4' }}>{`${props.auth.user.name} ${props.auth.user.lastName}`}</Text>}
            {props.targetUser && <Text H2 bold center style={{ gridColumn: '3/4', gridRow: '3/4' }}>{`${props.targetUser.name} ${props.targetUser.lastName}`}</Text>}
            <VideoContainer left>
                <Video muted stream={stream} />
            </VideoContainer>
            <VideoContainer>
                {state.remoteStream && <Video stream={state.remoteStream} />}
                {state.loading &&
                    <LoadingContainer>
                        <Loading />
                        <LoadingMessage><Text bottom center H1 bold>{language.getText('Iniciando Llamada...')}</Text></LoadingMessage>
                    </LoadingContainer>}
            </VideoContainer>
            {props.targetUser &&
                <ChatContainer>
                    {props?.targetUser?.id && <ChatView
                        personal
                        videoChat
                        id={getChatId(props.auth?.user, props?.targetUser)}
                        name='Chat'
                        target={props.targetUser}
                    />}
                </ChatContainer>
            }
        </VideoCallContainer>
    )
}

const RingingCallAlertContainer = styled.div`
    pointer-events: all;
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    height: 2rem;
    background-color: ${p => p.theme.light};

    padding: .8rem;
    display: ${p => p.active ? 'grid' : 'none'};
    grid-template-columns: ${p => p.owner ? '2rem auto' : '2rem auto 2rem 2rem'};
    grid-gap: 2rem;
`

const AnswerButton = styled.div`
    mask-image: url(${phoneIcon});
    mask-size: contain;
    mask-position: center;
    mask-repeat: no-repeat;
    background-color: ${p => p.answer ? 'green' : 'red'};
    height: 100%;

    ${p => !p.answer && css` transform: scale(-1); `}
    cursor: pointer;

    :hover {

    }
`

function RingingCallAlert(props) {
    const [startCallSound] = useSound(callSound)
    const language = useLanguage();
    useEffect(() => {
        let interval = null
        if (props.active) {
            startCallSound()
            interval = setInterval(startCallSound, 4000)
        }

        function cleanup() {
            if (interval) clearInterval(interval)
        }
        return cleanup
    }, [startCallSound, props.active])

    return (
        <RingingCallAlertContainer owner={props.owner} active={props.active}>
            <SVG contain image={phoneRingingIcon} primary />
            <Text middle bold primary H2>{props.owner ? language.getText('Llamando...') : `${props.user.name} ${props.user.lastName} te está llamando`}</Text>
            { !props.owner && <AnswerButton answer onClick={props.answerHandler} />}
            { !props.owner && <AnswerButton onClick={props.hangupHandler} />}
        </RingingCallAlertContainer>
    )
}

function Video({ stream, muted = false, autoPlay = true, ...props }) {
    const element = useRef(null)

    useEffect(() => {
        if (element.current && stream) {
            element.current.srcObject = stream
        }
    }, [stream, element])

    return <video style={{ height: '100%', width: '100%', minHeight: 0 }}{...props} autoPlay={autoPlay} playsInline muted={muted} ref={element} />
}