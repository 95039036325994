import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { useStore, useAuth, useIsPortrait } from '../../hooks'
import { Text } from '../UI'
import { SBackground, SArrow } from './SearchBar'
import { INotification } from '../control-panel/Notifications'
import { CloseButton } from '../NewUI'

const SNotifications = styled.div<{ active: boolean, isPortrait: boolean }>`
    position: absolute;

    width: 20rem;
    bottom: ${p => p.active ? '-1rem' : 0};
    right: -8rem;
    transform: translateY(100%);
    transition: .3s;
    opacity: ${p => p.active ? 1 : 0};
    pointer-events: ${p => p.active ? 'all' : 'none'};

    display: grid;
    grid-gap: 1rem;
    padding: 1rem;

	${p => p.isPortrait && css`
		position: fixed;
		height: 50vh;
		width: 80vw;
		top: 4rem;
		left: 1rem;
		right: 1rem;
		transform: translate(0);
	`}
`

const SGridHolder = styled.div`
    height: 18rem;
    overflow: auto;
`

const SGrid = styled.div`
    display: grid;
    grid-gap: .3rem;
    height: min-content;
`

export default function Notifications(props: { active: boolean }) {
	const [store, dispatch] = useStore()
	const auth = useAuth()
	const [counterNotification, setCounterNotification] = useState(0)
	const isPortrait = useIsPortrait()

	// Get only unread notifications.
	function getNotifications(): INotification[] {
		let allNotifications = store.notifications || {};
		let unreadNotifications = store.unreadNotifications;

		try {
			return (Object.values(allNotifications)as any[])
			.filter(({id}) => Object.values(unreadNotifications)
			.includes(id)).reverse()
		} catch { return [] } 
	}

	// Add notification to read ones.
	function closeHandler(id: string): void {
		store.unreadNotifications = store.unreadNotifications.filter(n => n !== id)
		dispatch({ type: 'set-user', payload: auth.user })

		deleteUnreadNotification(id);
	}

	/**
	 * Not used since ticket #90321
	 */
	function saveUser(): void {
		dispatch({ type: 'set-user', payload: auth.user })
	}

	function deleteUnreadNotification(id): void {
		const userId = auth.user.id;
		let data = {
					id : `${userId}/${id}`,
					data : {read : true}
				}
		dispatch({ type: 'new-unread', payload: data })
	}


	useEffect(() => {
		if (!store.unreadNotifications) return
		if (!store.notifications) return

		setCounterNotification(Object.keys(store.unreadNotifications).length)
	}, [store.notifications, store.unreadNotifications])

	return (
		<>
			{counterNotification > 0 && <SCounterNoty>{counterNotification}</SCounterNoty>}
			<SNotifications active={props.active} isPortrait={isPortrait}>
				<SBackground>
					<SArrow right='8.7rem' />
				</SBackground>

				<Text H2 bold>NOTIFICACIONES</Text>
				<SGridHolder>
					<SGrid>
						{
							getNotifications().map(n => <Tag key={n.id} notification={n} closeHandler={() => closeHandler(n.id)} />)
						}
					</SGrid>
				</SGridHolder>
			</SNotifications>
		</>
	)
}

const STag = styled.div`
    position: relative;
    background-color: ${p => p.theme.light};
    border-radius: ${p => p.theme.roundCorners};
    padding: 1rem;
`

function Tag(props: { notification: INotification, closeHandler(): void }) {
	const [store, dispatch] = useStore()
	return (
		<STag>
			<CloseButton
				onClick={() => props.closeHandler()}
			/>
			<Text dark H2>{store.notifications && store.notifications[props?.notification?.id]?.text}</Text>
		</STag>
	)
}

const SCounterNoty = styled.div`
	position: absolute;
	background: red;
	color: white;
	border-radius: 3px;
	left: 30px;
	top: -4px;
	padding: 0px 4px 0px 4px;
`
