import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import useState from 'react-usestateref'

import { Text, SVG } from './UI'
import { useOutsideAlerter, useIsMobile } from '../hooks'
import icons from '../graphics/icons'

const SIconButton = styled.div<{ icon: string, primary?: boolean }>`
    position: relative;
    width: 1.7rem;
    height: 1.7rem;

    cursor: pointer;
    :hover {
        * {
            background-color: ${p => !p.primary ? p.theme.primary : p.theme.light};
        }
    }

    ${p => p.primary && css`
        background-color: ${p => p.theme.primary};
        border-radius: ${p => p.theme.roundCorners};
        padding: .5rem;
    `}
`

export const SIcon = styled.div<{ icon: string, gray?: boolean, selected?: boolean }>`
    mask-image: url(${p => p.icon});
    mask-position: center;
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: ${p => p.gray ? p.theme.medium : p.selected ? p.theme.primary : p.theme.light};
    height: 100%;
    width: 100%;
`

const SNumber = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(30%, -30%);
    width: 1.3rem;
    height: 1.3rem;
    background-color: ${p => p.theme.primary};
    color: ${p => p.theme.light};
    border-radius: 50%;
    display: grid;
    place-content: center;
    font-size: .6rem;
`

export function IconButton(props: { icon: string, number?: string, onClick(): void, selected?: boolean, primary?: boolean, style?: any }) {
	return (
		<SIconButton onClick={() => props.onClick()} primary={props.primary} style={props.style}>
			<SIcon icon={props.icon} selected={props.selected} />
			{(props.number && props.number != '0') && <SNumber>{props.number}</SNumber>}
		</SIconButton>
	)
}

const SInputFieldHolder = styled.div<{ icon: string }>`
    border: .1rem solid ${p => p.theme.light};
    border-radius: ${p => p.theme.roundCorners};
    box-sizing: border-box;
    height: 2.2rem;
    display: grid;
    grid-auto-flow: column;
    grid-gap: .5rem;
    ${p => p.icon && css`
        grid-template-columns: 1.2rem auto;
    `}
    align-items: center;
    padding: 0 .5rem;
`

export const SInputfield = styled.input<{ dark?: boolean }>`
    color: ${p => p.dark ? p.theme.dark : p.theme.light};
    font-size: 18px;
    background-color: rgba(0, 0, 0, 0);
    border: none;

    ::placeholder {
        color: ${p => p.dark ? p.theme.dark : p.theme.light};
        opacity: .8;
    }
    width: 100%;
`

export const SInputfieldIcon = styled.div`
    width: 1.2rem;
    height: 1.2rem;
`

export function Inputfield(props: { onKeyDown?: any, placeholder?: string, value: string, onChange(v: string): void, type?: string, style?: any, icon?: string, ref?: any }) {
	return (
		<SInputFieldHolder icon={props.icon}>
			{props.icon && <SInputfieldIcon><SIcon icon={props.icon} /></SInputfieldIcon>}
			<SInputfield
				ref={props.ref}
				onKeyDown={props.onKeyDown}
				placeholder={props.placeholder}
				value={props.value ? props.value : ''}
				onChange={e => props.onChange(e.target.value)}
				type={props.type ? props.type : 'text'}
				style={props.style}
			/>
		</SInputFieldHolder>
	)
}

const SCloseButton = styled.div`
    position: absolute;
    top: .7rem;
    right: .7rem;
    width: .7rem;
    height: .7rem;
    cursor: pointer;
	z-index: 1;

    :hover {
        * {
            background-color: ${p => p.theme.primary};
        }
    }
`

export function CloseButton(props: { onClick(): void, style?: any }) {
	return (
		<SCloseButton onClick={() => props.onClick()} style={props.style}>
			<SIcon icon={icons.cross} gray />
		</SCloseButton>
	)
}

const SMultiDropdown = styled.div`
	position: relative;
`

const SMultiDropdownButton = styled.div<{ primary?: boolean, small?: boolean }>`
	border-radius: ${p => p.theme.roundCorners};
	${p => p.primary ? css`
		background-color: ${p => p.theme.primary};
		:hover {
			filter: brightness(110%);
		}
	` : css`
		border: .15rem solid ${p => p.theme.light};
		background-color: ${p => p.theme.light};
		:hover {
			* {
				color: ${p => p.theme.primary};
			}
		}
	`}
	box-sizing: border-box;
	height: 2.5rem;
	cursor: pointer;
	padding: ${p => p.registerDropdown ? "0 .8rem 0 0" : "0 .8rem" };
	display: ${p => p.flexy ? 'flex' : 'grid'};
	grid-gap: .5rem;
	grid-template-columns: min-content min-content;

	min-width: ${p => p.small ? 0 : '15rem'};
	${p => p.small && css`
		width: min-content;
	`}
`

const SMultiDropdownMenu = styled.div<{ active: boolean, primary?: boolean }>`
	position: absolute;
	border-radius: ${p => p.theme.roundCorners};
	width: 100%;
	min-width: 5rem;
	height: 10rem;
	/* background-color: ${p => p.primary ? '#f7f7f7' : p.theme.dark}; */
	background-color: ${p => p.theme.light};
	left: 0;
	bottom: -.5rem;
	transition: .3s;
	transform: translateY(${p => p.active ? '100%' : '95%'});
	opacity: ${p => p.active ? 1 : 0};
	overflow: auto;
	overflow-x: hidden;
	z-index: 1;
	${p => !p.active && css`
		pointer-events: none;
	`}
`

const SMultiDropdownList = styled.div`
	display: grid;
	grid-gap: .5rem;
`

const SMultiDropdownItem = styled.div`
	display: grid;
	grid-template-columns: auto 1rem;
	padding: .5rem 1rem;
	cursor: pointer;
	transition: .3s;
	:hover {
		transform: translateX(.2rem);
	}
`

const STriangle = styled.div<{ primary?: boolean, open?: boolean }>`
	width: 0;
	height: 0;
	border-left: .4rem solid transparent;
	border-right: .4rem solid transparent;

	border-top: .4rem solid ${p => p.primary ? p.theme.light : p.theme.primary};
	margin: auto;
	${p => p.open && css`
		transform: scaleY(-1);
	`}
`
const ErrorFieldContainer = styled.span`
    width: 100%;
    color: red;
    font-size: .75rem;
`

interface IMultiDropdownState {
	open: boolean,
}

interface Dictionary<T> {
	[Key: string]: T;
}

export function MultiDropdown(props: { errorState?: Dictionary<{ customMessage: string, valid: boolean, displayMessage: boolean }>, isRequired?: boolean, collapseText?: boolean, small?: boolean, primary?: boolean, options: Dictionary<{ id: string, name: string, value: boolean }>, title: string, clickHandler(id: string): void, style?: any, singleOption?: boolean }) {
	const [state, set, stateRef] = useState<IMultiDropdownState>({
		open: false,
	})


	const menuRef = useRef()
	useOutsideAlerter(menuRef, () => {
		if (stateRef.current.open) set(p => ({ ...p, open: false }))
	})

	// Get option items.
	function getItems() {
		return Object.values(props.options)
			.map(i =>
				<SMultiDropdownItem key={i.id} onClick={() => {
					props.clickHandler(i.id)

					// If is single option, clear other options and close it.
					if (props.singleOption) {
						Object.values(props.options).forEach(o => {
							if (o.value) props.clickHandler(o.id)
						})
						set(p => ({ ...p, open: false }))
					}
				}}>
					<CheckBox
						onClick={() => { }}
						title={i.name}
						right
						value={i.value}
					/>
				</SMultiDropdownItem>
			)
	}

	function getTitle(): string {
		if (props.singleOption)
			return Object.values(props.options).find(o => o.value)?.name ?? props.title

		return props.title
	}

	return (
		<SMultiDropdown style={props.style} ref={menuRef}>
			<SMultiDropdownButton registerDropdown={props.collapseText} flexy={props.collapseText} primary={props.primary} onClick={() => set(p => ({ ...p, open: !p.open }))} small={props.small}>
				<Text bold middle dark={!props.primary}>{getTitle()}</Text>
				<STriangle open={state.open} primary={props.primary} />
			</SMultiDropdownButton>

			<SMultiDropdownMenu primary={props.primary} active={state.open}>
				<SMultiDropdownList>
					{getItems()}
				</SMultiDropdownList>
			</SMultiDropdownMenu>
			{props.errorState?.displayMessage && <ErrorFieldContainer H5 bold>{props.errorState.customMessage ? props.errorState.customMessage : "Este campo es obligatorio"}</ErrorFieldContainer>}
		</SMultiDropdown>
	)
}

const SAmountSelector = styled.div`
	background-color: ${p => p.theme.primary};
	border-radius: ${p => p.theme.roundCorners};
	display: grid;
	place-items: center;
	grid-auto-flow: column;
	grid-auto-columns: auto;
	grid-gap: 1rem;
	padding: 0 .5rem;
`

export function AmountSelector(props: { value?: number, min?: number, max?: number, onChange(value: number): void }) {
	const value = props.value ?? props.min ?? 0

	function clickHandler(subtract: boolean): void {
		let newValue = value
		if (subtract) newValue -= 1
		else newValue += 1

		if (props.min && newValue < props.min) newValue = props.min
		if (props.max && newValue > props.max) newValue = props.max

		props.onChange(newValue)
	}

	return (
		<SAmountSelector>
			<IconButton
				icon={icons.subtract}
				onClick={() => clickHandler(true)}
			/>
			<Text bold H2 center middle>{value}</Text>
			<IconButton
				icon={icons.plus}
				onClick={() => clickHandler(false)}
			/>
		</SAmountSelector>
	)
}

const SCheckboxRow = styled.div<{ right?: boolean }>`
	display: grid;
	grid-template-columns: ${p => p.right ? 'auto 1rem' : '1rem auto'};
	grid-gap: ${p => p.theme.gridGap};
	cursor: pointer;
`

const SCheckbox = styled.div<{ selected: boolean }>`
	border: .2rem solid ${p => p.theme.primary};
	height: 1rem;
	width: 1rem;

	${p => p.selected && css`
		background-color: ${p => p.theme.primary};
	`}
	${p => p.isRequired && p.isInvalid && css`
		background-color: red;
	`}

	border-radius: .2rem;
	display: grid;
	place-items: center;
	* {
		width: 70%;
		height: 70%;
	}
`

export function CheckBox(props: { errorState?: Dictionary<{ customMessage: string, valid: boolean, displayMessage: boolean }>, isRequired?: boolean, right?: boolean, title: string, value: boolean, onClick(): void }) {
	const text = (<Text online dark middle>{props.title}</Text>)
	return (
		<>
			<SCheckboxRow right={props.right} onClick={() => props.onClick()}>
				{props.right && text}
				<SCheckbox selected={props.value}>
					{props.value && <SVG light image={icons.checkmark} />}
				</SCheckbox>
				{!props.right && text}
			</SCheckboxRow>
			{props.errorState?.displayMessage && <ErrorFieldContainer H5 bold>{props.errorState.customMessage ? props.errorState.customMessage : "Este campo es obligatorio"}</ErrorFieldContainer>}
		</>
	)
}
