import React, { useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { useStore, useAuth, useUserMoreInfo, useVideoCall, useLanguage } from '../hooks'
import { uploadDownloadableFile } from '../firebase/storage'
import { Text, InputField, SVG } from './UI'
import Linkify from 'react-linkify'

import sendIcon from '../graphics/icons/send.svg'
import videoIcon from '../graphics/icons/video.svg'
import messageIcon from '../graphics/icons/mail.svg'
import deleteIcon from '../graphics/icons/delete.svg'
import downloadIcon from '../graphics/icons/download.svg'
import linkIcon from '../graphics/icons/link.svg'
import Loading from './Loading'


const Container = styled.div`
    background-color: rgba(0, 0, 0, ${p => p.personal ? 0 : .7});
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    display: grid;
    grid-template-rows: ${p => p.canChat ? '2.45rem .05rem auto .05rem 2.5rem' : '2rem .05rem auto .05rem'};
`

const Line = styled.div` background-color: ${p => p.theme.light}; `

const PersonalTitle = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: min-content auto 1rem 1rem 1rem 2.5rem;
    grid-gap: 1rem;
`

const GroupTitle = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: min-content auto 1rem 1rem;
    grid-gap: 1rem;
`

// Get timestamp formatted for humans.
const getTimeStamp = (timestamp) => {
	if (timestamp) {
		const date = new Date(timestamp)
		return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
	}
}

export default function ChatView(props) {
	const [store, dispatch] = useStore()
	const auth = useAuth()
	const [userMoreInfo] = useUserMoreInfo()
	const [videoCall, setVideoCall] = useVideoCall() // Get video call context.
	const [state, set] = useState({ canChat: true })

	// If this is a only-admins chat, or this user is banned from chat. Dishable chat. (Can't send messages).
	useEffect(() => {
		let canChat = true
		if (props.onlyAdmins && auth.user && auth.user.type === 'normal') canChat = false
		if (auth?.user?.banFromChat) canChat = false

		set({ ...state, canChat: canChat })
	}, [auth.user])

	// Get chat group from database. (Group chat or personal chat (1-1 group combining user ids))
	const getGroup = () => {
		if (store.chatGroups && props.id) {
			if (props.id in store.chatGroups) return store.chatGroups[props.id]
			else if (props.personal) {
				const group = { id: props.id }
				dispatch({ type: 'set-chatGroup', payload: group })
				return group
			}
		}

		return {}
	}
	const group = getGroup()

	// Send new message to databse.
	const sendMessageHandler = message => {
		if (!group) return

		dispatch({
			type: 'send-message', payload: {
				message: message,
				chatGroup: group.id,
				sender: auth.user.id
			}
		})
	}

	// Access user info when clicking the name.
	const getTitleClickHandler = () => {
		if (props.personal) return () => {
			userMoreInfo.setUser(props.target)
		}
	}

	function canDownloadGroupChat() {
		return auth.isAdmin()
	}

	function truncateText(str, number) {
		if (str.length > number) {
			return str.substring(0, number) + '...'
		}
		return str
	}

	// Download chat.
	function downloadChatHandler() {
		if (!group?.messages) return

		let text = ''

		Object.values(group.messages).forEach(m => {
			const user = store?.users[m.user]
			text += `${user.name} ${user.lastName}: ${m.text}\n${getTimeStamp(m.timestamp)}\n\n`
		})

		const element = document.createElement('a')
		element.setAttribute('href', 'data:text/plain:charset=utf-8,' + encodeURIComponent(text))
		element.setAttribute('download', 'chat.txt')
		element.style.display = 'none'
		document.body.appendChild(element)
		element.click()
		document.body.removeChild(element)
	}

	const chatUsers = Object.keys(store?.chat || {});
	const callUsers = Object.keys(store?.call || {});
	let isOnline;
	let onACall;
		
	if (props?.target?.id) {
		// Validates if is a 1 to 1 chat first to set icons
		isOnline = chatUsers.find(id => id === props?.target?.id);
		onACall = callUsers.find(id => id === props?.target?.id);
	}

	return (
		<Container canChat={state.canChat} personal={props.personal}>
			{(props.personal && !props.videoChat) ?
				<PersonalTitle>
					<Text onClick={getTitleClickHandler()} style={{ marginLeft: '1rem' }} middle oneline bold H2>{truncateText(`${props.name}`, 15)}</Text>
					<div />
					{(store.configurations.allowVideoCalls && auth.user && auth.user.id && !onACall && isOnline) ? <SVG onClick={() => videoCall.startCall(props.target.id)} contain image={videoIcon} /> : <div />}
					<SVG onClick={() => window.open(`mailto:${props.target.email}`)} contain image={messageIcon} />
					<SVG onClick={() => downloadChatHandler()} contain image={downloadIcon} />
					<div />
				</PersonalTitle> :
				<GroupTitle>
					<Text style={{ marginLeft: '1rem' }} oneline middle bold H3>{props.videoChat ? props.name : group.name}</Text>
					<div />
					{canDownloadGroupChat() ? <SVG onClick={() => downloadChatHandler()} contain image={downloadIcon} /> : <div />}
					<div />
				</GroupTitle>
			}
			<Line />
			<MessagesView users={store.users} logged={auth.user} group={group} />
			<Line />
			{state.canChat && <InputText sendMessageHandler={sendMessageHandler} />}
		</Container>
	)
}

const InputTextContainer = styled.div`
	position: relative;
    display: grid;
    grid-template-columns: auto 3rem;
    grid-gap: 1rem;
`

const SendButton = styled.div`
    box-sizing: border-box;
	right: 2rem;
    padding: .6rem;
    * {
        cursor: pointer;
        :hover {
            background-color: ${p => p.theme.primary};
        }
    }
`

const SAddFile = styled.div`
    position: absolute;
    right: 45px;
    height: 2.2rem;
    width: 2.2rem;
	top: 2px;
    box-sizing: border-box;
    padding: 0.6rem;
    * {
        cursor: pointer;
        :hover {
            background-color: ${p => p.theme.primary};
        }
    }
`

function InputText(props) {
	const [text, setText] = useState('')
	const [sendPrimary, setSendPrimary] = useState(false)
	const [loading, setLoading] = useState(false)
	const hiddenFileInput = useRef()
	const language = useLanguage()

	// Oscillate send button color (sorry I'm new to React/css lol)
	useEffect(() => {
		const interval = setInterval(() => { setSendPrimary(prev => { return !prev }) }, 500)
		return () => clearInterval(interval)
	}, [])

	const sendHandler = () => {
		if (text === '') return
		props.sendMessageHandler(text)
		setText('')
	}

	const uploadHandlerClick =  () => {
		hiddenFileInput.current.click()
	}

	const uploadHandlerChange = async event => {
		try {
			setLoading(true)
			const file = event.target.files[0];
			const url = await uploadDownloadableFile(file)
			if (url === '') return
			await props.sendMessageHandler(url)
			setLoading(false)
		} catch (error) {
			console.log(error)
			setLoading(false)
		}
	  }

	return (
		<InputTextContainer>
			<input type="file" ref={hiddenFileInput} onChange={uploadHandlerChange} style={{display:'none'}} /> 
			<SAddFile>
				<SVG primary={false} contain image={linkIcon} onClick={uploadHandlerClick}/>
			</SAddFile>
			<InputField
				autoSelect
				onKeyDown={e => { if (e.keyCode === 13) sendHandler() }}
				style={{ border: 'none' }}
				placeholder={!loading ?  language.getText('Escriba su mensaje') : language.getText('Enviando') + '...'}
				value={!loading ? text: language.getText('Enviando') + '...'}
				onChange={setText}
				style={{width: '94%'}}
			/>
			<SendButton>
				<SVG style={{ transition: '.3s' }} primary={sendPrimary} onClick={sendHandler} contain image={sendIcon} />
			</SendButton>
		</InputTextContainer>
	)
}

const MessagesViewContainer = styled.div`
    overflow: auto;
`

const MessageGrid = styled.div`
    display: grid;
    grid-gap: 1rem;
    padding: 1rem;
    width: 100%;
    box-sizing: border-box;
`

const MessageBubbleContainer = styled.div`
    margin: auto;
    max-width: 80%;
    box-sizing: border-box;
    overflow-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;

	${p => p.loading ? css` visibility: hidden` : css`visibility: visible`};
    
	position: relative;

    ${p => p.mine ? css` margin-right: 0; ` : css` margin-left: 0; `}
`

const Bubble = styled.div`
    background-color: ${p => p.mine ? p.theme.primary : p.theme.darkerLight};
    padding: .5rem 1rem;
    border-radius: .5rem;
    border-bottom-left-radius: ${p => p.mine ? '.5rem' : 0};
    border-bottom-right-radius: ${p => !p.mine ? '.5rem' : 0};

    width: 100%;
    box-sizing: border-box;
`

const DeleteButton = styled.div`
    background-color: ${p => p.theme.primary};
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    top: .3rem;
    right: .3rem;
    border-radius: 50%;
    cursor: pointer;
    :hover {
        transform: scale(1.1);
    }
    display: flex;
    * {
        width: 70%;
        height: 70%;
        margin: auto;
    }
`

const DowloadButton = styled.div`
    background-color: ${p => p.theme.primary};
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    top: .5rem;
    right: 2.4rem;
    border-radius: 50%;
    cursor: pointer;
    :hover {
        transform: scale(1.1);
    }
    display: flex;
    * {
        width: 70%;
        height: 70%;
        margin: auto;
    }
`

const componentDecorator = (href, text, key) => (
	<a href={href} key={key} target="_blank">
		{text}
	</a>
)

function MessageBubble(props) {
	const [userMoreInfo] = useUserMoreInfo()
	const isMine = props.logged && props.logged.id === props.message.user
	const sender = props.message.user in props.users ? props.users[props.message.user] : {}
	const auth = useAuth()
	const [store, dispatch] = useStore()
	const language = useLanguage()

	// Admins can delete messages.
	function canDeleteMessage() {
		if (auth.isAdmin()) return true
		else if (auth.isOrganizer()) return store?.configurations?.featureDeleteMessage
	}

	function deleteMessageHandler() {
		dispatch({ type: 'delete-message', payload: { chatGroup: props.group.id, messageId: props.message.id } })
	}

	return (
		<MessageBubbleContainer mine={isMine} loading={props.loading}> 
			{canDeleteMessage() && <DeleteButton onClick={() => deleteMessageHandler()}><SVG contain image={deleteIcon} /></ DeleteButton>}
			<Bubble mine={isMine}>
				{props.message.text.includes("https://firebasestorage.googleapis.com/") ? 
				<Text dark={!isMine} H2  onClick={() => window.open(props.message.text, "_blank")} style={{color: 'white'}}>
					{!isMine && <Text onClick={() => userMoreInfo.setUser(sender)} dark bold> {`${sender.name} ${sender.lastName}`} </Text>}
					{language.getText('Archivo')} <DowloadButton><SVG contain image={downloadIcon} /></DowloadButton>
					<Text dark={!isMine} style={{ textAlign: 'right', marginTop: '.5rem' }}>{getTimeStamp(props.message.timestamp)}</Text>
				</Text> 
				: 
				<Linkify componentDecorator={componentDecorator}>
					<Text dark={!isMine} H2>
						{!isMine && <Text onClick={() => userMoreInfo.setUser(sender)} dark bold> {`${sender.name} ${sender.lastName}`} </Text>}
						{props.message.text}
						<Text dark={!isMine} style={{ textAlign: 'right', marginTop: '.5rem' }}>{getTimeStamp(props.message.timestamp)}</Text>
					</Text>
				</Linkify>}
			</Bubble>
		</MessageBubbleContainer>
	)
}

function MessagesView(props) {
	const [chatLoading, setChatLoading] = useState(true);
	const messagesEndRef = useRef()
	// Get list of messages, limit amount and sort them by timestamp.
	const getMessages = () => {
		if (props.group.messages) {
			let messages = Object.values(props.group.messages)
			messages = messages.sort((a, b) => a.timestamp > b.timestamp ? 1 : -1)
			const length = messages.length
			if (length > 100) messages = messages.slice(length - 100)
			return messages.map(m => <MessageBubble loading={chatLoading} group={props.group} users={props.users} logged={props.logged} key={m.id} message={m} />)
		}
	}

	const scrollToBottom = () => {
		messagesEndRef.current.scrollIntoView()
		setChatLoading(false);
	}

	// On new messages, scroll view to bottom.
	useEffect(scrollToBottom, [props.group.messages])

		return (<MessagesViewContainer>
			{chatLoading && 
				<Loading />
			}
			<MessageGrid>
				{getMessages()}
				<div ref={messagesEndRef} />
			</MessageGrid>
		</MessagesViewContainer>
		)
}
