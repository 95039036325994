import React, { useReducer, useState, useEffect } from 'react'
import { getContent, subscribeToContent, setContent, subscribeWithFilter, getContentNotNull, subscribeStartAt } from '../firebase/database'
import { databasePaths } from '../firebase'
import { getUnixTime } from '../utility'
import { key } from 'firebase-key'
import { useLanguage, useAuth } from '../hooks'

const Store = React.createContext()

let lastUserSnapshot = undefined

// Handle dictionary changes.
function dictionaryReducer(databasePath, dictionary, action, combine = false) {
    if (!dictionary) dictionary = {}
    if (!action.payload) return dictionary;

    if (action.type.includes('load')) {
        if (!combine) {
            dictionary = action.payload
        } else if (action.payload) {
            Object.keys(action.payload).map(d => {
                dictionary[d] = action.payload[d];
            })
        }
    }

    // Special action type since ticket #90321 for new unread notifications node
    if (action.type.includes('new') && action.payload.id) {
        dictionary[action.payload.id] = action.payload
        setContent(`${databasePath}/${action.payload.id}`, action.payload.data)
    }

    if (!action.payload.id) return dictionary

    if (action.type.includes('set') && action.payload.id) {
        dictionary[action.payload.id] = action.payload
        setContent(`${databasePath}/${action.payload.id}`, action.payload)
    }


    if (action.type.includes('delete') && action.payload.id) {
        delete dictionary[action.payload.id]
        setContent(`${databasePath}/${action.payload.id}`, {})
    }

    return dictionary
}

function objectReducer(databasePath, object, action) {
    if (!object) object = {}
    if (!databasePath) return object

    if (action.type.includes('set')) {
        object = action.payload
        setContent(`${databasePath}`, action.payload)
    }

    return object
}

async function sendMessage(payload) {
    if (!payload.chatGroup || !payload.message || !payload.sender) return

    let timeStamp = Date.now()

    try {
        timeStamp = (await getUnixTime()) * 1000
    } catch (error) {
        timeStamp = Date.now()
    }

    const message = {
        id: key(),
        text: payload.message,
        timestamp: timeStamp || Date.now(),
        user: payload.sender
    }

    setContent(`${databasePaths.chatGroups}/${payload.chatGroup}/messages/${message.id}`, message)
}

async function deleteMessage(payload) {
    if (!payload.chatGroup || !payload.messageId) return
    setContent(`${databasePaths.chatGroups}/${payload.chatGroup}/messages/${payload.messageId}`, {})
}

function currentUserReducer (state, action) {
    if (action.type.includes('set')) {
        return {...state, ...action.payload}
    }
}

function reducer(state, action) {
    if (action.type === 'init') {
        return { ...state, ...action.payload }
    }

    if (action.type === 'send-message') {
        sendMessage(action.payload)
        return { ...state }
    }

    if (action.type === 'delete-message') {
        deleteMessage(action.payload)
        return { ...state }
    }

    if (action.type.includes('users-loaded')) {console.log("action", action);return { ...state, usersLoaded: objectReducer(undefined, state.usersLoaded, action) }}

    if (action.type.includes('company')) return { ...state, companies: dictionaryReducer(databasePaths.companies, state.companies, action) }
    if (action.type.includes('stand')) return { ...state, stands: dictionaryReducer(databasePaths.stands, state.stands, action) }
    if (action.type.includes('pavillion')) return { ...state, pavillions: dictionaryReducer(databasePaths.pavillions, state.pavillions, action) }
    if (action.type.includes('user')) {
        if (action.type.includes('set')) {
            action.payload.lastUpdate = Date.now();
        }
        if (action.type.includes('update')) {
            return { ...state, users: dictionaryReducer(databasePaths.users, state.users, action, true) }
        } else {
            return { ...state, users: dictionaryReducer(databasePaths.users, state.users, action, false) }
        }
    }
    if (action.type.includes('current')) {
        return { ...state, currentUser: currentUserReducer(state.currentUser, action) }
    }

    if (action.type.includes('videoRoom')) return { ...state, videoRooms: dictionaryReducer(databasePaths.videoRooms, state.videoRooms, action) }
    if (action.type.includes('user')) return { ...state, users: dictionaryReducer(databasePaths.users, state.users, action) }
    if (action.type.includes('chatGroup')) return { ...state, chatGroups: dictionaryReducer(databasePaths.chatGroups, state.chatGroups, action) }
    if (action.type.includes('area')) return { ...state, areas: dictionaryReducer(databasePaths.areas, state.areas, action) }
    if (action.type.includes('chat')) return { ...state, chat: dictionaryReducer(databasePaths.chat, state.chat, action) }
    if (action.type.includes('call')) return { ...state, call: dictionaryReducer(databasePaths.call, state.call, action) }
    if (action.type.includes('ranking')) return { ...state, ranking: dictionaryReducer(databasePaths.ranking, state.ranking, action) }
    if (action.type.includes('productCategory')) return { ...state, productCategories: dictionaryReducer(databasePaths.productCategories, state.productCategories, action) }

    if (action.type.includes('notification')) {
        setUnreadNotifications(state, action)
        return { ...state, notifications: dictionaryReducer(databasePaths.notifications, state.notifications, action) }
    }
    if (action.type.includes('unread')) {
        return { ...state, unreadNotifications: dictionaryReducer(databasePaths.userNotifications, state.unreadNotifications, action) }
}

    if (action.type.includes('configurations')) return { ...state, configurations: objectReducer(databasePaths.configurations, state.configurations, action) }

    // if (action.type.includes('home')) return { ...state, home: objectReducer(databasePaths.home, state.home, action) }
    if (action.type.includes('home')) {
        const home = action.payload;
        return { ...state, areas: { ...state.areas, home  } }
    }
    if (action.type.includes('lobby')) return { ...state, lobby: objectReducer(databasePaths.lobby, state.lobby, action) }
    if (action.type.includes('networking')) return { ...state, networking: objectReducer(databasePaths.networking, state.networking, action) }

    return state
}
// Since ticket #90321 - Set new and unread userNotifications when user logs in
function setUnreadNotifications (state, action) {
    let allNotifications = Object.values(action.payload || {});
    let currentUnread = Object.values(state.unreadNotifications || {});
    let userId = state?.currentUser?.userId;
    
    let addNotification = {};

    if (allNotifications.length && currentUnread.length) {
        allNotifications.forEach((n) => {
            if (currentUnread.includes(n.id)) {
                addNotification[n.id] = {read : false}
            } else{
                addNotification[n.id] = {read : true}
            }
        })
    }

    let undefinedProp = addNotification.hasOwnProperty('undefined');

    if(userId && (Object.keys(addNotification).length) && !undefinedProp) {
        setContent(`${databasePaths.userNotifications}/${userId}`, addNotification)
    }
}

export async function getUsers(dispatch) {
    const minutesToDownload = 1;
    const time = new Date();
    time.setMinutes(time.getMinutes() - minutesToDownload);

    
    getContentNotNull(databasePaths.users, 'lastPing').then(users => {
        dispatch({type: "load-user", payload: users })

        subscribeStartAt(databasePaths.users, v => { 
            dispatch({ type: 'load-update-user', payload: v });
        }, 0, 'lastUpdate', time.getTime())
    })
    
}

async function getAreas(dispatch) {
    subscribeToContent(databasePaths.areas, v => {
        dispatch({type: "load-areas", payload: v })
    })
}

async function getNotifications(dispatch) {
    subscribeToContent(databasePaths.notifications, v => {
        dispatch({type: "load-notifications", payload: v})
    })
}

async function getPavillions(dispatch) {
    subscribeToContent(databasePaths.pavillions, v => {
        dispatch({type: "load-pavillions", payload: v })
    })
}

async function getChat(dispatch) {
    subscribeWithFilter(databasePaths.chat, v => { 
        dispatch({type: "load-chat", payload: v })
    }, 0, "online", true)
}

async function getCall(dispatch) {
    subscribeWithFilter(databasePaths.call, v => { 
        dispatch({type: "load-call", payload: v })
    }, 0, "onACall", true)
}

async function getVideoRooms(dispatch) {
    subscribeToContent(databasePaths.videoRooms, v => {
        dispatch({type: "load-videoRooms", payload: v })
    })
}

async function getStands(dispatch) {
    subscribeToContent(databasePaths.stands, v => {
        dispatch({type: "load-stands", payload: v })
    })
}

async function getCompanies(dispatch) {
    subscribeToContent(databasePaths.companies, v => {
        dispatch({type: "load-company", payload: v })
    })
}

async function getLobby(dispatch) {
    subscribeToContent(databasePaths.lobby, v => {
        dispatch({type: "load-lobby", payload: v })
    })
}

async function getNetworking(dispatch) {
    subscribeToContent(databasePaths.networking, v => {
        dispatch({type: "load-networking", payload: v })
    })
}

async function getChatGroups(dispatch) {
    subscribeToContent(databasePaths.chatGroups, v => {
        dispatch({type: "load-chatGroups", payload: v })
    })
}

async function getRanking(dispatch) {
    subscribeToContent(databasePaths.ranking, v => {
        dispatch({type: "load-ranking", payload: v })
    })
}

async function getAllData(state, dispatch) {
    if (!state.areas || Object.keys(state.areas).length < 2) {
        getAreas(dispatch);
    }
    if (!state.pavillions || Object.keys(state.pavillions).length < 1) {
        getPavillions(dispatch);
    }
    if (!state.notifications || Object.keys(state.notifications).length < 1) {
        getNotifications(dispatch);
    }
    if (!state.chat || Object.keys(state.chat).length < 1) {
        getChat(dispatch);
    }
    if (!state.call || Object.keys(state.call).length < 1) {
        getCall(dispatch);
    }
    if (!state.videoRooms || Object.keys(state.videoRooms).length < 1) {
        getVideoRooms(dispatch);
    }
    if (!state.stands || Object.keys(state.stands).length < 1) {
        getStands(dispatch);
    }
    if (!state.companies || Object.keys(state.companies).length < 1) {
        getCompanies(dispatch);
    }
    if (!state.lobby || Object.keys(state.lobby).length < 1) {
        getLobby(dispatch);
    }
    if (!state.chatGroup || Object.keys(state.chatGroup).length < 1) {
        getChatGroups(dispatch);
    }
    if (!state.networking || Object.keys(state.networking).length < 1) {
        getNetworking(dispatch);
    }
    if (!state.ranking || Object.keys(state.ranking).length < 1) {
        getRanking(dispatch);
    }
}

async function initFromFirebase(dispatch, selectLanguage, userId) {
    try {
        let home;
        let configurations;
        let productCategories;

        /* TO REMOVE 

        // Set offline users.
        if (users) {
            Object.values(users).forEach(user => {
                const online = Object.keys(chat).filter(u => u == user.id).length;
                if (online && (!user.lastPing || Date.now() - user.lastPing > 660000) && user.id)
                    // setContent(`${databasePaths.users}/${user.id}/online`, false)
                    setContent(`${databasePaths.chat}/${user.id}/online`, false)

                // Revoke universall access pass.
                // if(user.id) setContent(`${databasePaths.users}/${user.id}/universalAccess`, false)
            })
        }

        */

        home = await getContent(databasePaths.home)
        configurations = await getContent(databasePaths.configurations)
        productCategories = await getContent(databasePaths.productCategories)
        if (!configurations.forceLogin) {
            getAreas(dispatch);
            getPavillions(dispatch);
            getStands(dispatch);
            getCompanies(dispatch);
        }
        
        // call = await getContentEqualTo(databasePaths.call, 'onACall', true);

        {
            // TEMP local product categories.
            const initProductCategories = {
                'shoes': {
                    id: 'shoes',
                    name: 'Zapatos',
                    dropdowns: {
                        color: {
                            order: 1,
                            name: 'Color',
                            options: {
                                'blue': { id: 'blue', name: 'Azul', order: 1 },
                                'red': { id: 'red', name: 'Rojo', order: 2 },
                                'green': { id: 'green', name: 'Verde', order: 3 },
                            }
                        },
                        size: {
                            order: 2,
                            name: 'Talle',
                            options: {
                                's': { id: 's', name: 'S', order: 1 },
                                'm': { id: 'm', name: 'M', order: 2 },
                                'l': { id: 'l', name: 'L', order: 3 },
                            }
                        }
                    }
                }
            }

            if (!productCategories) productCategories = initProductCategories
        }

        // Set language.
        // selectLanguage(configurations?.language ?? 'spanish')

        function set() {
            dispatch({
                type: 'init',
                payload: {
                    configurations: configurations ? configurations : {},
                    productCategories,
                }
            })
            dispatch({ type: 'load-home', payload: home });
        }

        set()

        subscribeToContent(databasePaths.home, v => {
            home = v
            set()
        })
        
        subscribeToContent(databasePaths.configurations, v => {
            configurations = v
            set()
        })
        
    }

    catch (error) { console.error(error) }
}

let lastPingValidated = false;

export function StoreContext(props) {
    const [state, dispatch] = useReducer(reducer, {
        configurations: {},
        currentUser : {}
    })

    let userId = useAuth()?.user
    useEffect(()=> {
        if (userId && !state.currentUser.userId) {
            const user = {};
            user[userId.id] = userId;
            // Load the auth context information inside user store.
            dispatch({type: "load-user", payload: user })
            // Load users in case of the state is empty
            getAllData(state, dispatch);
            dispatch({type: "set-current", payload: {userId : userId.id}})
            dispatch({type: "load-notification", payload: state.notifications})
        }
    }, [userId])

    useEffect(()=> {
        /* Validate if more users than the current one are loaded in the store
        before applying logic to set offline users and trackUsers. */
        const users = state.users ? Object.keys(state.users).length : 0;

        if (users > 1 && state.chat && !lastPingValidated) {
            lastPingValidated = true;
            let trackUsersModified = false;

            getContent(databasePaths.trackUsers).then(trackUsers => {
                Object.values(state.users).forEach(user => {
                    const online = Object.keys(state.chat).filter(u => u == user.id).length;
                    if ((!user.lastPing || Date.now() - user.lastPing > 660000) && user.id) {
                        /* If the user is away for more than 10 minutes and still online,
                        set the user as offline in the database;
                        and kick him/her out of the current area.*/
                        if (online) {
                            setContent(`${databasePaths.chat}/${user.id}/online`, false)
                            // Clear all inactive users from trackUsers
                            const areas = Object.keys(trackUsers).filter(u => Object.keys(trackUsers[u]).includes(user.id));
                            areas.forEach(a => {
                                trackUsersModified = true
                                trackUsers[a][user.id] = null
                            })
                        }
                    }
                });
                if (trackUsersModified)
                    setContent(databasePaths.trackUsers, trackUsers);
            })
        }
    }, [userId, state.chat])

    const [init, setInit] = useState(true)
    const language = useLanguage()

    if (init) {
        setInit(false)
        initFromFirebase(dispatch, language.selectLanguage, userId)
    }

    return <Store.Provider value={[state, dispatch]}>{props.children}</Store.Provider>
}

export default Store
