import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Theme from '../../theme/index';

import { URLs } from '../../context/constants'
import { useHistory, useLocation } from 'react-router-dom'
import { useStore, useIsPortrait, useLanguage } from '../../hooks/'
import { SBackground, SArrow } from './SearchBar'
import { Inputfield } from '../NewUI'
import icons from '../../graphics/icons'
import { TextIcon, Text, Span } from '../UI'
import PoweredBy from '../PoweredBy'

// SVG Icon Components 
import ArrowDown  from '../../graphics/icons/iconComponents/ArrowDown';

import { ReactComponent as ArrowRight } from '../../graphics/icons/arrow-right.svg';
import Loading from '../Loading';

const SMenu = styled.div<{ active: boolean }>`
    position: absolute;

    width: 18rem;
    height: 25rem;
    bottom: ${p => p.active ? '-.7rem' : 0};
    left: .1rem;
    transform: translateY(100%);
    transition: .3s;
    opacity: ${p => p.active ? 1 : 0};
    pointer-events: ${p => p.active ? 'all' : 'none'};

    display: grid;
    grid-template-rows: min-content auto;
    grid-gap: 1rem;
    padding: .6rem;
`

export const SGridHolder = styled.div`
    overflow: auto;
    overflow-x: hidden;
`

export const SGridChildCounter = styled.div`
    color: ${p => p.theme.primary};
	background: ${p => p.theme.light};
	display: inline-block;
	border-radius: 50%;
	padding: 3px 6px;
	margin-left: 5px;
`

export const SChildAreas = styled.div<{ show: boolean}>`
	display: ${p => p.show ? 'grid' : 'none'};
    transform-origin: top;
    ${p => !p.show && css`
        transform: scaleY(0);
        height: 0;
    `}
    box-sizing: border-box;
    overflow: hidden;
    transition: .3s;
    grid-gap: 1rem;
    padding-left: 1rem;
`

export const SGrid = styled.div`
    display: grid;
    grid-gap: .7rem;
    height: min-content;
`

const SCollapsable = styled.div<{ show: boolean }>`
    display: grid;
    transform-origin: top;
    ${p => !p.show && css`
        transform: scaleY(0);
        height: 0;
    `}
    box-sizing: border-box;
    overflow: hidden;
    opacity: ${p => p.show ? 1 : 0};
    transition: .3s;
    grid-gap: 1rem;
    padding-left: 2.7rem;
`

interface IState {
	search: string,
	showAreas?: boolean, // Control the collapsable state of areas.
	showPavillions?: boolean, // Control the collapsable state of pavillions.
	showVideos?: boolean, // Control the collapsable state of video rooms.
	showOtherLinks?: boolean, // Control the collapsable state of otherLinks (Contacto, Soporte, términos y condiciones y política de privacidad).
	showChilds?: object,
	childsCounter?: object
}

export default function Menu(props: { active: boolean, close(): void }) {
	const [store] = useStore()
	const history = useHistory()
	const location = useLocation();
	const isPortrait = useIsPortrait()
	const language = useLanguage();
	const [state, set] = useState<IState>({
		search: '',
		showAreas: true,
		showPavillions: true,
		showVideos: true,
		showOtherLinks: true,
		showChilds: {},
		childsCounter: {}
	});
	const currentArea = getCurrentArea();

	// Clear search bar.
	useEffect(() => {
		set(p => ({ ...p, search: '' }))
	}, [props.active])

	// Go to selected page and close the menu.
	function goTo(to: string): void {
		history.push(to)
		props.close()
	}

	// Open a new tab in browser and close the menu.
	function goToExternal(to: string): void {
		window.open(to)
		props.close()
	}

	function transformOtherLinks(link, newArray) {
		if (link[0].includes('contact')) {
			let contactLink = {
				name: 'Contacto',
				link: link[1]
			}
			newArray.push(contactLink);
		} else if (link[0].includes('terms')) {
			let termsConditionsLink = {
				name: 'Términos y Condiciones',
				link: link[1]
			}
			newArray.push(termsConditionsLink);
		} else if (link[0].includes('privacy')) {
			let privacyLink = {
				name: 'Política de privacidad',
				link: link[1]
			}
			newArray.push(privacyLink);
		} else {
			let supportLink = {
				name: 'Soporte',
				link: link[1]
			}
			newArray.push(supportLink);
		}
	}

	function toggleChilds(area) {
		const areaId = area.id;
		const showChilds = state.showChilds;
		showChilds[area.id] = !showChilds[area.id];
		set(p => ({ ...p, showChilds }))
	}

	function getChildAreaIndicator(area) {
		if (state.childsCounter[area.id]) {
			if (!state.showChilds[area.id]) {
				return <Span onClick={() => toggleChilds(area)}><ArrowRight width={10} height={10} style={{ float: "right", marginRight: 20, marginTop: 5 }} /></Span>;
			} else {
				return <Span onClick={() => toggleChilds(area)}><ArrowDown width={10} height={10} fill={Theme.primaryHover} style={{ float: "right", marginRight: 20, marginTop: 5 }} /></Span>;
			}
		}
	}

	function addChildToArea(area, child) {
		const childsCounter = state.childsCounter;
		if (childsCounter[area] && childsCounter[area].includes(child)) return;

		if (childsCounter[area])
			childsCounter[area].push(child);
		else
			childsCounter[area] = [child];
	
		set(p => ({ ...p, childsCounter }));
	}

	function getCurrentArea(): string {
		const areaId = location.search.replace("?id=", '');
		
		return areaId;
	}

	function getChildAreas(area, areas = []) {
		if (!area.childAreas) return;

		const childs = (Object.values(area.childAreas) as any[])
			.sort((a, b) => a?.order > b?.order ? 1 : -1)

		areas.push(area.id);

		return <SChildAreas show={state.showChilds[area.id]}>{childs.map(c => {
				const childs = Object.values(store.areas as any[])
					.filter(a => (a.id == c.id && !areas.includes(a.id)));

				let child = undefined;
				if (childs.length) {
					child = childs[0]
				}

				if (!child) return;

				areas.push(child.id);
				addChildToArea(area.id, child.id);
				
				return <><Text
					bold
					active={currentArea === child.id}
					subtext><Span menu onClick={() => goTo(`${URLs.area}?id=${child.id}`)}>{child.name}</Span> {getChildAreaIndicator(child)}</Text>
					{getChildAreas(child, areas)}
					</>
		})}</SChildAreas>
	}

	let areas = []
	let pavillions = []
	let videoRooms = []
	let otherLinks = []

	// Get a sorted list of all available areas and map it to buttons.
	if (store.areas) {
		areas = (Object.values(store.areas) as any[])
			.sort((a, b) => a?.order > b?.order ? 1 : -1)
			.filter(a => a.id != 'home' && a.visibleInMenu)
			.filter(a => {
				if (state.search == '') return true
				return a.name.toLowerCase().includes(state.search.toLowerCase())
			})
			.map(a => <><Text
				bold
				active={currentArea === a.id}
				subtext><Span menu onClick={() => goTo(`${URLs.area}?id=${a.id}`)}>{a.name}</Span> {getChildAreaIndicator(a)}</Text>
				{getChildAreas(a)}
				</>)
	}

	// Get a sorted list of all available pavillions and map it to buttons.
	if (store.pavillions) {
		pavillions = (Object.values(store.pavillions) as any[])
			.sort((a, b) => a?.order > b?.order ? 1 : -1)
			.filter(a => {
				if (!a.visibleInMenu) return false
				if (state.search == '') return true
				return a.name.toLowerCase().includes(state.search.toLowerCase())
			})
			.map(a => <Text
				bold
				subtext
				onClick={() => goTo(`${URLs.pavillion}?id=${a.id}`)}>{a.name}</Text>)
	}

	// Get a sorted list of all available video rooms and map it to buttons.
	if (store.videoRooms) {
		videoRooms = (Object.values(store.videoRooms) as any[])
			.sort((a, b) => a?.order > b?.order ? 1 : -1)
			.filter(a => {
				if (!a.visibleInMenu) return false
				if (state.search == '') return true
				return a.name.toLowerCase().includes(state.search.toLowerCase())
			})
			.map(a => <Text
				bold
				subtext
				onClick={() => goTo(`${URLs.videoRoom}?id=${a.id}`)}>{a.name}</Text>)
	}

	// Get a list of all otherLinks and map it to buttons.
	if (store.configurations.otherLinks) {
		let otherLinksTransformedArray = [];
		
		Object.entries(store.configurations.otherLinks)
		.forEach((link) => transformOtherLinks(link, otherLinksTransformedArray));
			otherLinks = (Object.values(otherLinksTransformedArray) as any[])
				.filter(link => {
					if (state.search == '') return true
					return link.name.toLowerCase().includes(state.search.toLowerCase())
				})
				.map(link => {
					return <Text
					bold
					subtext
					onClick={() => goToExternal(link.link)}>{'getText' in language ? language.getText(link.name) : link.name}</Text>})
	}

	return (
		<SMenu active={props.active}>
			<SBackground>
				<SArrow left='1.2rem' />
			</SBackground>

			{isPortrait && <PoweredBy />}

			<Inputfield
				onChange={v => set(p => ({ ...p, search: v }))}
				value={state.search}
				placeholder='Buscar'
				icon={icons.lupa}
			/>

			<SGridHolder>
				<SGrid>
					{store?.areas?.home.visibleInMenu && <TextIcon onClick={() => goTo('')} image={icons.home}>Inicio</TextIcon>}
					{store?.areas?.home.visibleInMenu && <div />}

					{(!areas.length && !pavillions.length && !videoRooms.length && !otherLinks.length) && <Loading small />}
					{areas.length != 0 && <TextIcon onClick={() => set(p => ({ ...p, showAreas: !p.showAreas }))} image={icons.areas}>Áreas</TextIcon>}
					{areas.length != 0 && <SCollapsable show={state.showAreas || state.search}>
						<div />
						{areas}
						<div />
					</SCollapsable>}

					{pavillions.length != 0 && <TextIcon onClick={() => set(p => ({ ...p, showPavillions: !p.showPavillions }))} image={icons.pavillion}>Pabellones</TextIcon>}
					{pavillions.length != 0 && <SCollapsable show={state.showPavillions || state.search}>
						<div />
						{pavillions}
						<div />
					</SCollapsable>}

					{videoRooms.length != 0 && <TextIcon onClick={() => set(p => ({ ...p, showVideos: !p.showVideos }))} image={icons.video}>Salas de Video</TextIcon>}
					{videoRooms.length != 0 && <SCollapsable show={state.showVideos || state.search}>
						<div />
						{videoRooms}
						<div />
					</SCollapsable>}
					
					{otherLinks.length != 0 && <TextIcon onClick={() => set(p => ({ ...p, showOtherLinks: !p.showOtherLinks }))} image={icons.plus}>Otros links</TextIcon>}
					{otherLinks.length != 0 && <SCollapsable show={state.showOtherLinks || state.search}>
						<div />
						{otherLinks}
						<div />
					</SCollapsable>}
				</SGrid>
			</SGridHolder>
		</SMenu>
	)
}
