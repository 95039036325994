import React, { useState, useEffect } from 'react'
import { useAuth, useStore, useElementsManager } from '../hooks'
import { useHistory } from 'react-router-dom'

import { getContent, setContent } from '../firebase/database'
import { getUnixTime } from '../utility'
import { isEmptyBindingElement } from 'typescript'

const Analytics = React.createContext({})
const ANALYTICS_ROOT = 'analytics'

interface Dictionary<T> { [Key: string]: T; }

enum VisitStateKeys {
	anonymousVisits = 'anonymousVisits',
	totalVisits = 'totalVisits',
	users = 'users',
	id = 'id',
	buttons = 'buttons'
}

class VisitState {
	firebasePath: string
	currentState: IVisitState

	constructor(currentState: IVisitState, firebasePath: string) {
		this.firebasePath = firebasePath
		this.currentState = currentState
	}

	async increment(userId: string): Promise<void> {
		// Set id.
		setContent(`${this.firebasePath}/${VisitStateKeys.id}`, this.currentState.id)
		// Increment total Visits.
		setContent(`${this.firebasePath}/${VisitStateKeys.totalVisits}`, this.currentState.totalVisits + 1)

		// Increment anonymous or save user.
		if (userId === 'anonymous') setContent(`${this.firebasePath}/${VisitStateKeys.anonymousVisits}`, this.currentState.anonymousVisits + 1)

		else {
			let timeStamp = Date.now() / 1000
			timeStamp = Math.floor(timeStamp)

			try { setContent(`${this.firebasePath}/${VisitStateKeys.users}/${userId}/${timeStamp}`, timeStamp) } catch { }
		}
	}

	incrementButton(userId: string, button: any): void {
		this.increment(userId)
	}
}

export interface IVisitState {
	id: string,
	totalVisits: number,
	anonymousVisits: number,
	users: Dictionary<Dictionary<string>>,
	buttons?: Dictionary<IVisitState>
}

interface IPageInfo {
	page?: PageType,
	id?: string
}

type PageType = 'areas' | 'pavillions' | 'video-rooms' | 'stands'

interface IAnalyticsContext {
	logButtonClick(button: any): void,
	getDatabase(): Promise<any>
}

export function AnalyticsContext(props) {
	const history = useHistory()
	const auth = useAuth()
	const [store] = useStore()
	const elementsManager = useElementsManager() as any

	const [database, setDatabase] = useState()

	const getUserId = (): string => (auth.user && auth.user.id) ? auth.user.id : 'anonymous'

	async function getDatabase(): Promise<any> {
		// If already set, just return.
		if (database) return database

		// Download it, and save it.
		const content = await getContent(ANALYTICS_ROOT)
		setDatabase(content)

		return content
	}

	// Get Visit state from firebase.
	async function getVisitState(data: IPageInfo): Promise<VisitState> {
		const path: string = `${ANALYTICS_ROOT}/${data.page}/${data.id}`

		const id: string = await getContent(`${path}/${VisitStateKeys.id}`) || data.id
		const totalVisits = await getContent(`${path}/${VisitStateKeys.totalVisits}`) || 0
		const anonymousVisits = await getContent(`${path}/${VisitStateKeys.anonymousVisits}`) || 0

		const currentState: IVisitState = { id: id, totalVisits: totalVisits, anonymousVisits: anonymousVisits, users: {} }

		return new VisitState(currentState, path)
	}

	async function getButtonVisitState(data: IPageInfo, button: any): Promise<VisitState> {
		const path: string = `${ANALYTICS_ROOT}/${data.page}/${data.id}/${VisitStateKeys.buttons}/${button.id}`

		const id: string = await getContent(`${path}/${VisitStateKeys.id}`) || button.id
		const totalVisits = await getContent(`${path}/${VisitStateKeys.totalVisits}`) || 0
		const anonymousVisits = await getContent(`${path}/${VisitStateKeys.anonymousVisits}`) || 0

		const currentState: IVisitState = { id: id, totalVisits: totalVisits, anonymousVisits: anonymousVisits, users: {} }

		return new VisitState(currentState, path)
	}

	async function logVisit(data: IPageInfo): Promise<void> {
		const visitsState = await getVisitState(data)
		visitsState.increment(getUserId())
	}

	function getPageInfo(): IPageInfo {
		const params = new URLSearchParams(history.location.search)
		const id = params.get('id')
		const pathname: string = history.location.pathname

		const data: IPageInfo = { id: id }

		if (id && pathname === '/area') data.page = 'areas'
		if (id && pathname === '/pavillion') data.page = 'pavillions'
		if (id && pathname === '/video-room') data.page = 'video-rooms'
		if (id && pathname === '/stand') data.page = 'stands'
		if (!id && pathname === '/') {
			data.page = 'areas'
			data.id = 'home'
		}

		return data
	}

	async function logButtonClick(button: any): Promise<void> {
		const pageData = getPageInfo()

		// Check if analytics are disabled.
		if (!Object.keys(store?.configurations).length || store?.configurations?.disableAnalytics) return

		// Can't log component of non existing page.
		if (!pageData.id || !pageData.page) return

		const buttonState = await getButtonVisitState(pageData, button)
		console.log(buttonState)
		buttonState.incrementButton(getUserId(), button)
	}

	function onNewPage(): void {
		if (!elementsManager.state.pannels || !elementsManager.state.buttons) elementsManager.setBoth(true)

		// Check if analytics are disabled.
		if (!Object.keys(store?.configurations).length || store?.configurations?.disableAnalytics) return

		const pageData = getPageInfo()

		// Log new page visit.
		if (pageData.page && pageData.id) logVisit(pageData)
	}

	useEffect(onNewPage, [history.location, auth.user?.id])

	const state: IAnalyticsContext = {
		logButtonClick: logButtonClick,
		getDatabase: getDatabase
	}

	return <Analytics.Provider value={state}>{props.children}</Analytics.Provider>
}

export default Analytics