import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import fetch from 'node-fetch'

import { useAuth } from '../hooks'

const promosLoginUrl = 'https://us-central1-bigjonan-57cd9.cloudfunctions.net/easypromosautologin'

// Call cloud function to get access token.
const getPromos = (user: any, promosId: string): Promise<string> => new Promise(async (resolve, reject) => {
	if (promosId === '') reject()

	// request body { gameId: string, userId: string, userName: string, userLastname: string, userEmail: string }
	const data = {
		gameId: promosId,
		userId: user.id,
		userName: user.name,
		userLastname: user.lastName,
		userEmail: user.email
	}

	const promosResponse = await (await fetch(promosLoginUrl, {
		method: 'post',
		body: JSON.stringify(data),
		headers: { 'Content-Type': 'application/json' }
	})).json()

	if (promosResponse?.promosResponse?.status === 1) resolve(promosResponse.promosResponse.lt)
})

// Get promos ID from game's url.
function getPromosId(gameUrl: string): string {
	try {
		const parts = gameUrl.split('/')
		return parts[parts.length - 1]
	} catch { return '' }
}

function isEasyPromosURL(gameURL, url) {
	return gameURL.includes(url);
}

const SEasyPromo = styled.div`
	background-color: black;
	height: 100%;
`

const EasyPromo: FC<{ gameUrl: string }> = ({ gameUrl }) => {
	const auth = useAuth()
	const [token, setToken] = useState<string>()
	const easyPromosURL = "a.cstmapp.com";

	useEffect(() => {
		(async function () {
			if (auth?.user?.id && gameUrl) {
				const lt = await getPromos(auth.user, getPromosId(gameUrl))
				setToken(lt)
			}
		})()
	}, [auth?.user?.id])

	return (
		<SEasyPromo>
			{(token || !isEasyPromosURL(gameUrl, easyPromosURL)) &&
				<iframe width="100%" height="100%" src={`${gameUrl}?lt=${token}`} allowFullScreen frameBorder="0" allow="camera *;microphone *" />
			}
		</SEasyPromo>
	)
}

export default EasyPromo
