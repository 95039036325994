import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';

import { useStore, useAuth, usePersonalChat, useIsPortrait, useLanguage } from '../hooks'
import { setContent } from '../firebase/database'
import { databasePaths } from '../firebase'
import { getUsers as getUsersFromFirebase } from '../store';

import { Text, InputField, IconButton, SVG } from './UI'
import { MultiDropdown } from './NewUI'
import ChatView from './ChatView'
import backIcon from '../graphics/icons/arrow-left.svg'
import closeIcon from '../graphics/icons/close.svg'
import profileIcon from '../graphics/icons/profile.svg'
import phoneIcon from '../graphics/icons/phone.svg'

const Container = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    width: 20rem;
    right: ${p => p.active ? 0 : '-20rem'};

    ${p => p.isPortrait && css`
        width: 100vw;
        right: ${p.active ? 0 : '-100%'};
        padding-top: 2.5rem;
    `}

    background-color: rgba(0, 0, 0, .8);
    transition: .3s;
	z-index: 1;
`

function getChatId(a, b) {
	return a.id > b.id ? a.id + b.id : b.id + a.id
}

const BackButton = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    background-color: ${p => p.theme.primary};
    :hover { background-color: ${p => p.theme.primaryHover}; }
    display: flex;
    * {
        margin: auto;
        width: 60%;
        height: 60%;
    }
`

export default function PersonalChat(props) {
	const [store, dispatch] = useStore()
	const isPortrait = useIsPortrait()
	const auth = useAuth()
	const personalChat = usePersonalChat()

	const onlyDirectMessages =
		(store?.configurations?.showPersonalChatButton && auth?.user?.type === 'normal') ||
		(store?.configurations?.organizersChatOnlyDirect && auth?.user?.type === 'organizer') ||
		(store?.configurations?.expositorsChatOnlyDirect && auth?.user?.type === 'expositor')

	const getContent = () => {
		if (personalChat.menu === 0) return <UserList
			onlyDirectMessages={onlyDirectMessages}
			store={store}
			auth={auth}
			onUserClick={u => personalChat.openChat(u)}
		/>

		if (personalChat.menu === 1 && personalChat.selected) {
			return <Chat
				id={getChatId(auth.user, personalChat.selected)}
				selected={personalChat.selected}
				name={`${personalChat.selected.name} ${personalChat.selected.lastName ? personalChat.selected.lastName : ''}`}
				backHandler={() => personalChat.closeChat()}
			/>
		}
	}

	return (
		<Container isPortrait={isPortrait} active={props.active}>
			{isPortrait && <BackButton onClick={() => personalChat.toggle()}>
				<SVG image={backIcon} />
			</BackButton>}
			{getContent()}
		</Container>
	)
}

const UserListContainer = styled.div`
    height: 100%;
    display: grid;
    grid-gap: 1rem;
    grid-template-rows: 2rem 2rem auto;

	${p => p.showUserKeyWords && css`
		grid-template-rows: 2rem 2rem 2rem auto;
	`}

    padding: 2rem 1rem;
`

const UserListScroll = styled.div`
    padding-top: 1rem;
    overflow-x: hidden;
`

const Line = styled.div`
    background-color: ${p => p.theme.light};
    height: .05rem;
`

let loadingUsers = false;

function UserList(props) {
	const [store, dispatch] = useStore()
	const [search, setSearch] = useState('')
	const auth = useAuth()
	const language = useLanguage();

	const [state, set] = useState({
		filterOptions: {}
	})

	// Set user key words state.
	useEffect(() => {
		if (store?.configurations?.userKeyWords) {
			set(p => ({
				...p,
				filterOptions: Object.values(store.configurations.userKeyWords).reduce((obj, option) => ({
					...obj,
					[option.id]: {
						id: option.id,
						name: option.name,
						value: false
					}
				}), {})
			}))
		}
	}, [store.configurations])

	function getShowFilter() {
		if (!store?.configurations?.userKeyWords) return false
		return auth?.user?.type != 'normal'
	}

	const getUsers = () => {
		if (props.store?.users && !loadingUsers && Object.keys(props.store.users).length < 2) {
			loadingUsers = true;
			getUsersFromFirebase(dispatch)
		}
		const showFilter = getShowFilter()

		if (!props.auth.user) return []
		if (props.store.users) return Object.values(props.store.users)
			.filter(u => {
				if (!u.name || u.id === props.auth.user.id) return false
				if (store?.configurations?.allowHiddenInChat && u.idHiddenInChat) return false

				let filter = true

				if (showFilter) {
					const userKeyWords = Object.values(state.filterOptions)
					// If at least 1 is true, then filter.
					if (userKeyWords.some(o => o.value)) {
						// Foreach option, if it's on, return true only if user has it.
						for (const o of userKeyWords) {
							if (o.value) {
								if (u.keyWords && o.id in u.keyWords) filter = true
								else filter = false

								break
							}
						}
					}
				}

				if (search === '') return filter
				const s = search.toLowerCase()
				if (u.extraInfo) {
					for (const v of Object.values(u.extraInfo)) {
						if (v?.toLowerCase && v.toLowerCase().includes(s)) return filter
					}
				}
				return `${u.name} ${u.lastName}`.toLowerCase().includes(s) && filter

			})
	}

	const getMap = users => users.map(u => <UserTag online={chatUsers.find(id => id === u.id)} onClick={() => props.onUserClick(u)} key={u.id} user={u} />)

	let allUsers = getUsers()
	let chatUsers = Object.keys(props.store.chat || {});
	
	let recentUsers = []
	let onlineUsers = []
	let offlineUsers = []

	if (allUsers) {
		if (props.store.configurations.chatOnlyWithAdmins && props.auth.user && props.auth.user.type === 'normal')
			allUsers = allUsers.filter(u => u.type === 'admin' || u.type === 'organizer' || u.type === 'expositor')

		for (const u of allUsers) {
			const chatID = getChatId(u, props.auth.user)

			if (props.store.chatGroups && chatID in props.store.chatGroups && props.store.chatGroups[chatID].messages) recentUsers.push(u)
			else if (!props.onlyDirectMessages) {
				if (chatUsers.find(id => id === u.id)) onlineUsers.push(<UserTag online={true} onClick={() => props.onUserClick(u)} key={u.id} user={u} />)
				else offlineUsers.push(<UserTag onClick={() => props.onUserClick(u)} key={u.id} user={u} />)
			}
		}

		recentUsers = recentUsers.sort((a, b) => {
			const chatIDA = getChatId(a, props.auth.user)
			const chatIDB = getChatId(b, props.auth.user)
			const aMessages = Object.values(props.store.chatGroups[chatIDA].messages)
			const bMessages = Object.values(props.store.chatGroups[chatIDB].messages)

			return aMessages[aMessages.length - 1].timestamp < bMessages[bMessages.length - 1].timestamp ? 1 : -1
		})

		recentUsers = getMap(recentUsers)
	}


	let listItems = []
	if (recentUsers.length != 0) {
		listItems.push(<Text key='recent' style={{ padding: '.5rem', borderBottom: '.05rem solid white' }} bold H2>Mensajes Recientes</Text>)
		for (const u of recentUsers) listItems.push(u)
	}

	if (onlineUsers.length != 0) {
		listItems.push(<Text key='online' style={{ padding: '.5rem', borderBottom: '.05rem solid white' }} bold H2>Usuarios Conectados</Text>)
		for (const u of onlineUsers) listItems.push(u)
	}

	if (offlineUsers.length != 0) {
		listItems.push(<Text key='offline' style={{ padding: '.5rem', borderBottom: '.05rem solid white' }} bold H2>Usuarios Desconectados</Text>)
		for (const u of offlineUsers) listItems.push(u)
	}

	const rowRenderer = ({ key, index, style }) => {
		return (
			<div key={key} style={style}>
				{listItems[index]}
			</div>
		)
	}

	return (
		<UserListContainer showUserKeyWords={getShowFilter()}>
			<Text bold H12>{language.getText('Chat directo')}</Text>
			<InputField
				placeholder={language.getText('Buscar usuario')}
				value={search}
				onChange={setSearch}
			/>
			{getShowFilter() && <MultiDropdown
				clickHandler={id => {
					set(p => ({
						...p, filterOptions: {
							...p.filterOptions,
							[id]: {
								...p.filterOptions[id],
								value: !p.filterOptions[id].value
							}
						}
					}))
				}}
				options={state.filterOptions}
				title='Filtrar'
			/>}
			<UserListScroll>
				<AutoSizer>
					{
						({ height, width }) => (
							<List
								height={height}
								rowCount={listItems ? listItems.length : 0}
								rowHeight={64}
								rowRenderer={rowRenderer}
								width={width}
							/>
						)
					}
				</AutoSizer>
			</UserListScroll>
		</UserListContainer>
	)
}

const UserTagContainer = styled.div`
    position: relative;
    padding: .5rem;
    cursor: pointer;
    transition: .3s;
    :hover {
        background-color: ${p => p.theme.primary};
        transform: translateX(1rem);
    }

    display: grid;
    grid-template-columns: 2rem auto 2rem;
    grid-gap: 1rem;
    height: 2rem;
`

const ProfilePicture = styled.div`
    position: relative;
    border-radius: 50%;
    background-color: ${p => p.theme.light};
`

const UserNameContainer = styled.div`
    margin: auto 0;
`

const StatusContainer = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    background-color: ${p => p.status ? 'green' : 'gray'};
`

function Status(props) {
	const [status, setStatus] = useState(false)
	useEffect(() => {
		setStatus(props.user.online || props.online)
	}, [props.user.online || props.online])

	return (
		<StatusContainer status={status}>
		</StatusContainer>
	)
}

const UnreadMessagesContainer = styled.div`
    position: absolute;
    background-color: ${p => p.theme.primary};
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    * {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        width: min-content;
        height: min-content;
    }
`

function UserTag(props) {
	const [store, dispatch] = useStore()
	const auth = useAuth()
	const [unreadMessages, setUnreadMessages] = useState(0)
	const [chatId] = useState(getChatId(auth.user, props.user))

	const callUsers = Object.keys(store.call || {});
	const onACall = callUsers.find(id => id === props.user.id);

	useEffect(() => {
		if (store.chatGroups && chatId && chatId in store.chatGroups && store.chatGroups[chatId].messages) {
			setUnreadMessages(Object.values(store.chatGroups[chatId].messages).filter(m => !m.read && m.user != auth.user.id).length)
		}
	}, [store.chatGroups, chatId])

	function getExtraName() {
		try {
			for (const extraInfo of Object.values(store.configurations.userExtraInfo)) {
				if (extraInfo.primary) return ` - ${props.user.extraInfo[extraInfo.id]}`
			}

			return ''
		} catch { return '' }
	}

	return (
		<UserTagContainer onClick={props.onClick}>
			<ProfilePicture>
				<Status user={props.user} online={props.online} />
				<SVG style={{ backgroundColor: 'gray', maskSize: '70%', webkitMaskSize: '70%' }} image={profileIcon} />
			</ProfilePicture>
			<UserNameContainer>
				<Text oneline H2 bold>{`${props.user.name} ${props.user.lastName || ''} ${getExtraName()}`}</Text>
			</UserNameContainer>
			{onACall && <SVG style={{ maskSize: '60%', webkitMaskSize: '60%' }} image={phoneIcon} contain light />}
			{unreadMessages != 0 && <UnreadMessagesContainer><Text center bold>{unreadMessages}</Text></UnreadMessagesContainer>}
		</UserTagContainer>
	)
}

const ChatContainer = styled.div`
    height: 100%;
`

const CloseButton = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 2.4rem;
    height: 2.4rem;
`

function Chat(props) {
	const [store] = useStore()
	const auth = useAuth()
	let messages = {} 
	
	if (store?.chatGroups) {
		if (store?.chatGroups[props.id] && store?.chatGroups[props.id].messages) messages = store.chatGroups[props.id].messages
	}

	useEffect(() => {
		if (store?.chatGroups) {
			if (store.chatGroups[props.id] && store.chatGroups[props.id].messages) {
				Object.values(store.chatGroups[props.id].messages).filter(m => !m.read && m.user != auth.user.id).forEach(m => {
					setContent(`${databasePaths.chatGroups}/${props.id}/messages/${m.id}/read`, true)
				})
			}
		}
	}, [props.id, messages])

	return (
		<ChatContainer>
			<CloseButton><IconButton onClick={props.backHandler} image={closeIcon} /></CloseButton>
			<ChatView
				personal
				id={props.id}
				target={props.selected}
				name={props.name}
			/>
		</ChatContainer>
	)
}
