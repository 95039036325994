import React, { useState } from 'react'
import styled, { keyframes, css } from 'styled-components'
import { useHistory } from 'react-router-dom'

import { URLs } from '../context/constants'
import { IconButton, FadeInUp, Image, Text, SVG } from './UI'
import { useUrl, usePopup, useAuth, useNotifications, usePersonalChat, useStore, useUserMoreInfo, useAnalytics, useExternalAnalytics, useIsPortrait, useViewport, useLanguage, useCatalog } from '../hooks'
import { IViewport } from '../context/ViewportContext'
import { sendEmail } from '../utility'
import { isMobile } from 'react-device-detect'
import icons from '../graphics/icons'
import Embed from './Embed'

const pulseAnimation = keyframes`
    0% { transform: scale(0.8); }
    50% { transform: scale(1); }
    100% { transform: scale(0.8); }
`

const Panel = styled.div`
    ${p => p.isPortrait && css`
        position: absolute;
        width: 100%;
        height: 100%;
    `}

    ${p => !p.isPortrait && css`
        position: absolute;
        width: 12rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -100%);
        opacity: 0;
    `}
    
    background-color: ${p => p.theme.light};
    border-radius: ${p => p.theme.roundCorners};
    overflow: hidden;
    z-index: -1;
    transition: .5s;

    display: grid;
    grid-template-rows: ${p => p.description ? 'min-content min-content' : 'min-content'} ${p => p.image ? p.isPortrait ? 'auto' : '6rem' : '1rem'};

    ${p => (p.isPortrait && !p.image) && css`
        grid-template-rows: auto;
        grid-template-columns: auto auto;
    `}

    grid-gap: .4rem;
    grid-gap: 0;
    pointer-events: none;

    ${p => (p.panelMode && !p.isPortrait) && css`
        z-index: 10;
        transition: 0s;
        position: fixed;
        top: var(--mouse-y);
        left: var(--mouse-x);
        transform: translate(-50%, -105%);
    `}
`

const RectContainer = styled.div`
    position: relative;
    ${p => !p.isPortrait && css`
        position: absolute;
        z-index: 1;
        top: ${p => p.y};
        left: ${p => p.x};
    `}
    
    ${p => p.isPortrait && css` 
        height: ${p.image ? '10rem' : '3rem'}; 
    `}
`

const Contianer = styled.div`
    ${p => p.isPortrait && css`
        position: absolute;
        top: ${p => p.image ? '100%' : '50%'};
        left: ${p => p.image ? '50%' : '90%'};
        transform: translate(-50%, -50%);
    `}

    ${p => !p.isPortrait && css`
        :hover {
            transform: scale(1.2);
            ${Panel} {
                opacity: 1;
            }
        }
    `}

    *:nth-child(2) {
        pointer-events: none;
        height: 100%;
    }

    width: 4rem;
    height: auto;
    cursor: pointer;

    transition: ${p => p.theme.transitionDuration};
`

const WhiteCircle = styled.div`
    background-color: rgba(255, 255, 255, .5);
    border-radius: 50%;
    padding: .5rem;
    height: 100%;
    box-sizing: border-box;
    animation: ${pulseAnimation} 2s infinite;
`

const RankingVotePanel = styled.div`
    background-color: ${p => p.theme.light};
    border-radius: ${p => p.theme.roundCorners};
    padding: .3rem 1rem;

    ${p => !p.isPortrait && css`
        position: absolute;
        left: 50%;
        top: 100%;

        transform: translate(-50%, .5rem);
    `}
`

const SPanelMode = styled.div`
    position: absolute;

    top: ${p => p.button.position.y};
    left: ${p => p.button.position.x};

    width: ${p => p.button.size.x};
    height: ${p => p.button.size.y};

    cursor: pointer;
    :hover {
        ${Panel} {
            opacity: 1;
        }
    }
`

async function sendEmails(message, emails, user, notify, store) {
	if (message === '') {
		notify('El mensaje está vacio.')
		return
	}

	if (emails.lenght === 0) return

	try {
		let text = `Pregunta: ${message}\n\nInfo del usuario:\nEmail: ${user.email}\n`
		if (user.name) text += `Name: ${user.name}\n`
		if (user.lastName) text += `Apellido: ${user.lastName}\n`
		if (user.phone) text += `Teléfono: ${user.phone}\n`
		if (user.company) text += `Organización: ${user.company}\n`
		if (user.charge) text += `Cargo/Función: ${user.charge}\n`
		if (user.country) text += `Pais: ${user.country}\n`
		if (user.state) text += `Provincia: ${user.state}\n`
		if (user.city) text += `Ciudad: ${user.city}\n`

		const response = await sendEmail(emails, {
			subject: 'Pregunta',
			text: text,
			from: store.configurations.cloudFunctionPrivateMessageEmail,
			pass: store.configurations.cloudFunctionPrivateMessageEmailPass
		})
		notify('Pregunta enviada.')
	} catch (error) {
		console.log(error)
	}
}

export default function FloatingButton(props) {
	const isPortrait = useIsPortrait()
	const notifications = useNotifications()
	const auth = useAuth()
	const history = useHistory()
	const iconUrl = useUrl(props.button.icon)
	const [popup] = usePopup()
	const personalChat = usePersonalChat()
	const [store, dispatch] = useStore()
	const [descriptionBackground, setDescriptionBackground] = useState()
	const [userMoreInfo] = useUserMoreInfo()
	const externalAnalytics = useExternalAnalytics();
	const analytics = useAnalytics()
	const viewport = useViewport()
	const language = useLanguage()
	const [catalog, setCatalog] = useCatalog()

	if (!props.button.position) props.button.position = { x: '50%', y: '50%' }
	if (!props.button.size) props.button.size = { x: '50', y: '40' }

	const getBackground = a => {
		if (a.backgrounds && a.backgrounds.images) return Object.values(a.backgrounds.images)[0]
		return a.background
	}

	if (props.button.type === 'navegation' && descriptionBackground === undefined) {
		setDescriptionBackground(false)
		let value
		if (props.button.data.includes('?')) {
			const parts = props.button.data.split('?')
			try {
				const params = new URLSearchParams(`?${parts[1]}`)
				const id = params.get('id')

				if (parts[0] === URLs.area && store.areas && store.areas[id]) value = getBackground(store.areas[id])
				if (parts[0] === URLs.pavillion && store.pavillions && store.pavillions[id]) value = getBackground(store.pavillions[id])
				if (parts[0] === URLs.videoRoom && store.videoRooms && store.videoRooms[id]) value = getBackground(store.videoRooms[id])
			} catch { }
		}
		else if (props.button.data === '/') {
			if (store.areas && store.areas.home) value = getBackground(store.areas.home)
		}

		if (value) setDescriptionBackground(value)
	}

	const clickHandler = () => {
		if (props.button.type === 'hardcoded') props.button.data()
		if (props.button.type === 'navegation') history.push(props.button.data)
		if (props.button.type === 'download' || props.button.type === 'link') {
			const link = document.createElement('a')
			link.setAttribute('href', props.button.data)
			link.setAttribute('target', '_blank')
			link.style.visibility = 'hidden'
			link.click()
		}

		// Use viewport.
		if (props.button.type === 'internalLink') {
			viewport.setTitle(props.button.name)
			viewport.setContent(<Embed url={props.button.data} />)
			viewport.setActive(true)
		}

		if (props.button.type === 'message' && auth.user) {
			popup.askForText(
				language.getText('Escriba su pregunta'),
				value => sendEmails(value, Object.values(props.button.data.split(',')), auth.user, notifications.notify, store),
				'',
				() => { },
				language.getText('Enviar'),
				language.getText('Cancelar'),
			)
		}
		if (props.button.type === 'chat') {
			if (store.users) {
				try {
					const target = Object.values(store.users).find(u => {
						if (!u.email) return false
						return u.email.toLowerCase() === props.button.data.toLowerCase()
					})
					if (target) userMoreInfo.setUser(target)
				} catch { }
			}
		}

		if (props.button.type === 'catalog') {
			const stand = store?.stands[props.button.data]
			if (stand) setCatalog(p => ({
				...p,
				stand,
				active: true
			}))
		}

		if (props.button.type === 'ranking') {
			if (!auth?.user?.id) return
			let data = store?.ranking[props.button.id]
			if (!data) data = {
				id: props.button.id,
				users: {}
			}

			if (data.name != props.button.data) data.name = props.button.data

			if (!data.users) data.users = {}

			if (auth.user.id in data.users) delete data.users[auth.user.id]
			else data.users[auth.user.id] = auth.user.id

			dispatch({ type: 'set-ranking', payload: data })
		}

		// Analytics.
		analytics.logButtonClick(props.button)

		// External analytics.
		externalAnalytics.logButtonClick(props.button)
	}

	function isRankingVoted() {
		if (!isRanking()) return false
		try { return auth.user.id in store.ranking[props.button.id].users }
		catch { return false }
	}

	function isRanking() { return props.button.type === 'ranking' }

	let panel
	if (props.button.name || descriptionBackground) {
		panel = (
			<Panel panelMode={props.button.panelMode} description={props?.button?.description} isPortrait={isPortrait} image={descriptionBackground}>
				<Text style={{ padding: '.3rem' }} middle center={descriptionBackground} dark H2 bold>{props.button.name}</Text>
				{props?.button?.description && <Text style={{ padding: '.3rem' }} middle center={descriptionBackground} dark H3>{props.button.description}</Text>}
				{descriptionBackground && <Image url={descriptionBackground} />}
			</Panel>
		)
	} else {
		panel = false
	}


	function getRankingCount() {
		try {
			return Object.values(store.ranking[props.button.id].users).length
		} catch { return '0' }
	}

	const getRankingPanel = () => (
		<RankingVotePanel isPortrait={isPortrait}>
			<Text bold dark H2>{getRankingCount()}</Text>
		</RankingVotePanel>
	)

	function getIcon() {
		if (props.button.icon === 'cart.svg') return icons.cart
		return iconUrl
	}

	let content =
		// Desktop version.
		!isPortrait ?
			<RectContainer image={descriptionBackground} onClick={() => clickHandler()} x={props.button.position.x} y={props.button.position.y} isPortrait={isPortrait}>
				{isPortrait && panel}
				{props.button.type === 'ranking' && getRankingPanel()}
				<Contianer image={descriptionBackground} isPortrait={isPortrait}>
					<div> {!isPortrait && panel} </div>
					{!isPortrait && <FadeInUp>
						<WhiteCircle>
							<IconButton
								style={{ borderRadius: '50%' }}
								light={!isRanking()}
								primary={isRankingVoted()}
								gray={isRanking() && !isRankingVoted()}
								image={getIcon()}
							/>
						</WhiteCircle>
					</FadeInUp>}
				</Contianer>
			</RectContainer>

			// Mobile version.
			: <SPortraitButton onClick={() => clickHandler()}>
				<SPortraitIcon>
					<SVG image={getIcon} primary contain />
				</SPortraitIcon>
				{props.button.name && <Text bold>{props.button.name}</Text>}
				{props.button.description && <Text>{props.button.description}</Text>}
			</SPortraitButton>


	// Set invisible click area.
	if (!isPortrait && props.button.panelMode) content = <SPanelMode onClick={() => clickHandler()} button={props.button}>{panel}</ SPanelMode>

	if (isMobile && props.button.type === 'question') content = null

	if ((isPortrait && props.button.hideInMobile) || (!isPortrait && props.button.hideInDesktop)) content = null

	return content
}

const SPortraitButton = styled.div`
	display: grid;
	grid-gap: .2rem;
	cursor: pointer;
	height: min-content;
`

const SPortraitIcon = styled.div`
	background-color: ${p => p.theme.light};
	border-radius: ${p => p.theme.roundCorners};
	aspect-ratio: 1;
	height: 2rem;
	padding: 20%; 
`
