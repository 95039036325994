import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useArea, useIsPortrait } from '../hooks'

const Container = styled.div`
    position: relative;
    height: ${p => p.isPortrait ? '40vh' : '100vh'};
`

export default function Home() {
	const isPortrait = useIsPortrait()
	const components = useArea('home')

	return (
		<Container isPortrait={isPortrait}>
			{components}
		</Container>
	)
}