import { sendResetPasswordEmail } from '../firebase/auth'
import { firebaseConfig } from '../context/Brand'

const fetch = require('node-fetch')

const sendEmailCloudFunction = 'https://us-central1-bigjonan-57cd9.cloudfunctions.net/sendEmail'
const treeviewCORS = 'https://treeview-cors.herokuapp.com/'


// data: { subject: string, text?: string, html?: string, picture?: url, from?: string, pass?: string}
export const sendEmail = (emails, data) => new Promise(async (resolve, reject) => {
    if (emails.length === 0) return

    let to = emails[0]
    if (emails.length > 1) emails.forEach((e, i) => { if (i != 0) to += `, ${e}` })

    const body = {
        to: to,
        subject: data.subject,
        text: data.text,
        html: data.html,
        picture: data.picture,
        from: data.from,
        pass: data.pass
    }

    fetch(sendEmailCloudFunction, {
        method: 'post',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' },
    })
    .then(res => res.json())
    .then(json => { 
        console.log(json)
        resolve(json)})
    .catch(error => {
        console.log(error)
        reject(error)
    })
})

export const getUnixTime = () => new Promise(async (resolve, reject) => {
    try {
        const timeStamp = await (await fetch(`https://showcase.api.linx.twenty57.net/UnixTime/tounix?date=now`)).text()
        resolve(timeStamp)
    } catch (error) { reject(error) }
})

export function deleteUser(id) {
    fetch(`https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net/deleteuser`, {
        method: 'POST',
        body: JSON.stringify({id: id}),
        headers: { 'Content-Type': 'application/json' }
    })
}
