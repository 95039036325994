import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { URLs } from '../context/constants'
import { Button } from './UI'
import { useAuth, useParam, useStore } from '../hooks'

const Container = styled.div`
    position: absolute;
    right: 0;
    width: 10rem;
    height: 100%;
    bottom: -.5rem;
    transform: translateY(100%);
`

export default function EditAdminButton() {
    const auth = useAuth()
    const history = useHistory()
    const id = useParam('id')
    const [store] = useStore()

    function canEdit() {
        const path = history.location.pathname

        if (!auth.user) return false
        if (auth.isAdmin()) return true

        if (!store?.configurations) return false

        if (auth.isExpositor()) {
            if (path != URLs.stand) return false
            if (!store.configurations['efeatureEditStandButton']) return false

            try {
                return store.stands[id].company === auth.user.typeData.company
            } catch { return false }
        }

        if (auth.isOrganizer()) {
            if (path === URLs.area || URLs.home === path) return store.configurations['featureEditAreaButton']
            if (path === URLs.pavillion) return store.configurations['featureEditPavillionButton']
            if (path === URLs.videoRoom) return store.configurations['featureEditVideoRoomButton']
            if (path === URLs.stand) return store.configurations['featureEditStandButton']
        }
    }

    if (!canEdit()) return null

    function clickHandler() {
        const path = history.location.pathname

        if (path === URLs.home) history.push(`${URLs.areaEditor}?type=areas&id=home`)
        if (path === URLs.area) history.push(`${URLs.areaEditor}?type=areas&id=${id}`)
        if (path === URLs.pavillion) history.push(`${URLs.areaEditor}?type=pavillions&id=${id}`)
        if (path === URLs.videoRoom) history.push(`${URLs.areaEditor}?type=videoRooms&id=${id}`)
        if (path === URLs.stand) history.push(`${URLs.areaEditor}?type=stands&id=${id}`)
    }

    return (
        <Container>
            <Button onClick={clickHandler}>Editar Área</Button>
        </Container>
    )
}
