import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import ResizableContainer from './ResizableContainer'

import { Text, InputField, Button, SVG, Image, UploadFile } from '../components/UI'
import { uploadImage, uploadDownloadableFile } from '../firebase/storage'
import Dropdown from '../components/Dropdown'
import { key } from 'firebase-key'

import videoIcon from '../graphics/icons/video.svg'

import { useNotifications, useStore, useLanguage } from '../hooks'
import { isMetaProperty } from 'typescript'
import icons from '../graphics/icons'

type tabType = 'list' | 'selected'
interface IViewSettingsState {
	tab: tabType,
	selected?: any,
	setTab(t: tabType): void,
	select(i: any): void,
	save(): void
}

const ViewSettingsContainer = styled.div`
    height: 100%;
`

export default function ViewSettings({ view, deleteView, saveView, editPositionHandler, editListPositionHandler }) {
	const [state, setState] = useState<IViewSettingsState>({
		tab: 'list',
		setTab(t) {
			this.tab = t
			this.save()
		},
		select(i) {
			this.selected = i
			this.setTab('selected')
		},
		save() { setState({ ...this }) }
	})

	function setListPosition(): void {
		let y = parseFloat(view?.position?.y)
		let ySize = parseFloat(view?.size?.y)
		if (y && ySize) y += ySize + 1
		try {
			view.list = {
				position: {
					x: view.position.x,
					y: `${y}%`
				},
				size: {
					x: view.size.x,
					y: '20%'
				}
			}

		} catch {
			view.list = {
				position: { x: '20%', y: '20%' },
				size: { x: '20%', y: '20%' }
			}
		}
		saveView(view)
	}

	if (view.items && Object.keys(view.items).length > 1) { if (!view.list) setListPosition() }

	else if (view.list) {
		delete view.list
		saveView(view)
	}


	function getContent() {
		if (state.tab === 'list') return <List
			selectHandler={i => state.select(i)}
			deleteView={() => deleteView()}
			newItem={i => {
				if (!view.items) view.items = {}
				view.items[i.id] = i
				saveView(view)
			}}
			view={view}
			saveView={saveView}
			editPositionHandler={() => editPositionHandler()}
			editListPositionHandler={() => editListPositionHandler()}
			items={view.items || {}} />

		if (state.tab === 'selected' && state.selected) return <Edit
			back={() => state.setTab('list')}
			saveView={v => saveView(v)}
			view={view}
			item={state.selected} />
	}

	return (
		<ViewSettingsContainer>
			{getContent()}
		</ViewSettingsContainer>
	)
}

const ListContainer = styled.div`
    height: 100%;
    display: grid;
    grid-gap: 1rem;

    grid-template-rows: min-content .1rem 2rem 2rem .1rem 10rem 2rem .1rem 2rem 2rem 2rem .1rem min-content 2rem 1rem;
`

const ListItemsContainer = styled.div`
    overflow: scroll;
`

const ListItemsGrid = styled.div`
    display: grid;
    grid-gap: 1rem;
    grid-auto-rows: 2rem;
`

const Line = styled.div`
    background-color: ${p => p.theme.light};
    height: .1rem;
`

const Checkbox = styled.div`
    background-color: ${p => p.selected ? p.theme.primary : p.theme.light};
    border: .2rem solid ${p => p.theme.light};
    box-sizing: border-box;
    transition: .3s;
`

const ToggleRow = styled.div`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 2rem 2rem auto;
    height: 2rem;
    cursor: pointer;
    transition: .3s;
    :hover {
        transform: translateY(.2rem);
    }
`

function List({ items, selectHandler, deleteView, newItem, editPositionHandler, saveView, view, editListPositionHandler }) {
	function newItemHandler() {
		const id = key()
		const item = { name: 'Nuevo Item', id: id, type: 'video' }
		newItem(item)
	}

	const language = useLanguage()

	return (
		<ListContainer>
			<Text H1 bold>{language.getText('Configuración del Panel')}</Text>
			<Line />
			<Button onClick={() => editPositionHandler()}>{language.getText('Editar Posición')}</Button>
			<Button onClick={() => editListPositionHandler()}>{language.getText('Editar Posición de lista')}</Button>
			<Line />
			<ListItemsContainer>
				<ListItemsGrid>
					{(Object.values(items) as any[]).map(i => <Button key={i.id} onClick={() => selectHandler(i)}>{i.name || 'Item'}</Button>)}
				</ListItemsGrid>
			</ListItemsContainer>
			<Button onClick={() => newItemHandler()}>Nuevo Item</Button>
			<Line />
			<ToggleRow onClick={() => saveView({ ...view, playlist: !view.playlist, loop: true })}>
				<Checkbox selected={view.playlist} />
				<SVG image={videoIcon} />
				<Text middle H2 bold>Playlist autoplay</Text>
			</ToggleRow>
			<ToggleRow onClick={() => saveView({ ...view, hideInDesktop: !view.hideInDesktop })}>
				<Checkbox selected={!view.hideInDesktop} />
				<SVG image={icons.monitor} />
				<Text middle H2 bold>{language.getText('Desktop')}</Text>
			</ToggleRow>
			<ToggleRow onClick={() => saveView({ ...view, hideInMobile: !view.hideInMobile })}>
				<Checkbox selected={!view.hideInMobile} />
				<SVG image={icons.phone} />
				<Text middle H2 bold>{language.getText('Mobile')}</Text>
			</ToggleRow>
			<Line />
			<Text bold H2>{language.getText('Otros')}</Text>
			<Button onClick={() => deleteView()}>{language.getText('Eliminar')}</Button>
			<div />
		</ListContainer>
	)
}

const EditContainer = styled.div`
    display: grid;
    grid-gap: 2rem;
    /* grid-template-rows: min-content .1rem 2rem 2rem .1rem min-content 2.5rem min-content 2rem .1rem 2rem 2rem; */
`

type ItemTypes = 'video' | 'vimeo' | 'image' | 'web' | 'chat' | 'stands'
interface IItem {
	id: string,
	name: string,
	data?: string,
	order: number,
	type: ItemTypes,
	onlyAdmins?: boolean,
	userParams?: boolean,
	travelWayLogin?: boolean,
}

const ImageHolder = styled.div`
    height: 12rem;
    border: .1rem solid ${p => p.theme.light};
`

function Edit(props) {
	const [item, setItem] = useState<IItem>(props.item)
	const [store, dispatch] = useStore()
	const [state, setState] = useState<any>({})
	const notifications = useNotifications()
	const language = useLanguage();

	if (!item.id) setItem({ ...item, id: 'a' })

	const itemOptions = {
		'video': { name: 'Youtube', id: 'video', dataDescription: 'ID del video' },
		'vimeo': { name: 'Vimeo', id: 'vimeo', dataDescription: 'ID del video' },
		'image': { name: 'Imagen', id: 'image', dataDescription: 'Subir Imagen' },
		'web': { name: 'Web', id: 'web', dataDescription: 'Enlace de página web' },
		'promos': { name: 'Promos', id: 'promos', dataDescription: 'Enlace de la promoción' },
		'chat': { name: 'Chat', id: 'chat', dataDescription: 'Nombre del grupo de chat' },
		'stands': { name: 'Buscador de Stands', id: 'stands', dataDescription: '' },
		'companies': { name: 'Buscador de Empresas', id: 'companies', dataDescription: '' },
	}

	const saveViewData = v => {
		let inputValue = v
		let data = v

		if (item.type === 'vimeo') {
			try {
				const parts = data.split('/')
				data = parts[parts.length - 1]
				inputValue = data
			} catch { }
		}

		if (item.type === 'video' && data.includes('?')) {
			try {
				data = (new URL(data).searchParams).get('v')
				inputValue = data
			} catch { }
		}

		if (item.type === 'chat') {
			const chatGroup = props.view.id in store.chatGroups ? store.chatGroups[props.view.id] : { id: props.view.id }
			chatGroup.name = v
			dispatch({ type: 'set-chatGroup', payload: chatGroup })
			data = props.view.id
		}

		setItem({ ...item, data: inputValue })
	}

	function save() {
		props.view.items[item.id] = item
		props.saveView({ ...props.view })
		props.back()
	}

	async function uploadImageHandler(): Promise<void> {
		const image = state.backgroundImage
		setState({ ...state, backgroundImage: null, loading: true })
		try {
			const id = await uploadImage(image)
			saveViewData(id)
		} catch (error) {
			(notifications as any).notify('La imagen no puede superar los 5mb')
		}
		setState({ ...state, loading: false })
	}

	return (
		<EditContainer>
			<Text H1 bold>{language.getText('Configuración de ')} {item.name}</Text>
			<Line />
			<InputField
				value={item.name}
				onChange={v => setItem({ ...item, name: v })}
				placeholder='Nombre'
			/>
			<InputField
				value={item.order}
				onChange={v => setItem({ ...item, order: v })}
				placeholder='Order'
			/>
			<Line />
			<Text H2>{language.getText('Tipo de item')}</Text>
			<Dropdown
				options={Object.values(itemOptions)}
				selected={itemOptions[item.type]}
				onSelect={id => { setItem({ ...item, type: id, data: '' }) }}
			/>

			<Text H2>{itemOptions[item.type]?.dataDescription}</Text>
			{item.type === 'image' && <ImageHolder><Image contain url={item.data} /></ImageHolder>}
			{item.type === 'image' && (state.loading ? <Text H2 bold>{language.getText('Subiendo...')}</Text> : <UploadFile style={{ height: '2rem', width: '15rem' }} fileHanlder={file => setState({ ...state, backgroundImage: file })}>Subir nueva imagen</UploadFile>)}
			{(item.type === 'image' && state.backgroundImage) && <Button onClick={uploadImageHandler} style={{ height: '2rem', width: '15rem' }}>Subir</Button>}

			{item.type === 'chat' &&
				<ToggleRow
					onClick={() => setItem({ ...item, onlyAdmins: !item.onlyAdmins })}>
					<Checkbox selected={item.onlyAdmins} />
					<div />
					<Text oneline H2 bold>
						Solo para Admins/Organizadores
					</Text>
				</ToggleRow>
			}

			{item.type === 'web' &&
				<ToggleRow
					onClick={() => setItem({ ...item, userParams: !item.userParams })}>
					<Checkbox selected={item.userParams} />
					<div />
					<Text oneline H2 bold>
						Params de Usuario
					</Text>
				</ToggleRow>
			}

			{item.type === 'web' &&
				<ToggleRow
					onClick={() => setItem({ ...item, travelWayLogin: !item.travelWayLogin })}
				>
					<Checkbox selected={item.travelWayLogin} />
					<div />
					<Text oneline H2 bold>
						TravelWay Login
					</Text>
				</ToggleRow>
			}

			{item.type != 'image' && <InputField
				value={item.data}
				onChange={v => saveViewData(v)}
			/>}
			<Line />
			<Button style={{ height: '2rem', marginBottom: '2rem' }} onClick={() => save()}>Guardar</Button>
			{item.id != 'a' && <Button onClick={() => {
				delete props.view.items[item.id]
				props.saveView(props.view)
				props.back()
			}}>Eliminar</Button>}
		</EditContainer>
	)
}

const ListPositionContainer = styled.div`
    background-color: red;
    position: fixed;
    width: 100vw;
    height: 100vh;
`

const ListPositionTitle = styled.div`
    background-color: ${p => p.theme.dark};
`

function ListPosition(props) {
	const language = useLanguage()
	return (
		<ResizableContainer
			updatePositionHandler={props.updatePositionHandler}
			position={props.view.list.position}
			size={props.view.list.size}
			onClick={props.selectHandler}
			resizable
			editable={props.selected && props.editingPosition}>
			<ListPositionTitle>
				<Text H1 bold center middle>{language.getText('LISTA')}</Text>
			</ListPositionTitle>
		</ResizableContainer>
	)
}