import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { useCompanyPoster, useAwakeBool, useIsPortrait, useStore, useUserMoreInfo } from '../../hooks'
import { SBackground, SGrid, SGridHolder } from '../topbar/SearchBar'
import { Text, SVG, Image } from '../UI'
import { CloseButton } from '../NewUI'
import icons from '../../graphics/icons'

const SCompanyPoster = styled.div<{ active: boolean }>`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2;

	display: ${p => p.active ? 'grid' : 'none'};	
	place-items: center;
`

const SPanel = styled.div<{ isPortrait: boolean }>`
	position: relative;
	background-color: ${p => p.theme.light};
	border-radius: ${p => p.theme.roundCorners};
	padding: 1rem;
	padding-top: 2rem;

	display: grid;
	grid-template-columns: 1fr 1fr min-content;
	grid-gap: 1rem;
	width: 40vw;
	/* height: 80vh; */
	height: 70vh;

	/* aspect-ratio: 2; */

	transition: .3s;
	${p => !p.active && css`
		opacity: 0;
		transform: translateY(1rem);
	`}

	${p => p.isPortrait && css`
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr min-content;

		overflow: auto;

		width: 80vw;
		height: 90vh;
	`}
`

const SLeft = styled.div`
	display: grid;
	grid-gap: 1rem;
	grid-template-rows: auto 3rem;
`

const SRight = styled.div<{ isPortrait: boolean }>`
	display: grid;
	grid-gap: 1rem;
	grid-template-rows: 1fr .1rem 1fr;
	height: inherit;
	border-right: .1rem solid ${p => p.theme.dark};
	padding-right: 1rem;
	overflow: hidden;

	${p => p.isPortrait && css`
		height: min-content;
		min-height: 10rem;
		grid-template-rows: min-content .1rem min-content;
	`}
`

const SImage = styled.div<{ isPortrait: boolean }>`
	border-radius: ${p => p.theme.roundCorners};
	overflow: hidden;
	aspect-ratio: 1/1.4;
	width: 100%;

	${p => p.isPortrait && css`
		height: 40vh;
	`}

	cursor: pointer;
`

const SBigImage = styled.div<{ active: boolean }>`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: ${p => p.active ? 'grid' : 'none'};
	place-items: center;
	cursor: pointer;
`

const SLine = styled.div`
	background-color: ${p => p.theme.dark};
`

export default function CompanyPoster() {
	const isPortrait = useIsPortrait()
	const [companyPoster, setCompanyPoster] = useCompanyPoster()
	const awakeActive = useAwakeBool(companyPoster.active)
	const [bigImage, setBigImage] = useState(false)

	function closeHandler(): void {
		setCompanyPoster(p => ({ ...p, active: false, company: undefined }))
	}

	function getValues(): any[] {
		const list = []
		function add(name: string, value: string): void {
			if (!name || !value) return

			// list.push(<Text key={name} bold dark oneline H2>{name}</Text>)
			list.push(<Text key={value} dark bold H2>{value}</Text>)
			list.push(<div key={`${name}div`} />)
		}

		add('Empresa', companyPoster?.company?.name)
		add('Subtitle', companyPoster?.company?.subName)
		add('Contacto', companyPoster?.company?.email)
		add('Teléfono', companyPoster?.company?.phone)

		return list
	}

	return (
		<SCompanyPoster active={companyPoster.active}>
			<SBackground onClick={() => closeHandler()} />
			<SPanel isPortrait={isPortrait} active={awakeActive}>
				<CloseButton onClick={() => closeHandler()} />
				<SLeft>
					<SImage onClick={() => { setBigImage(true) }} isPortrait={isPortrait}><Image url={companyPoster?.company?.fullImage} /></SImage>
					<SocialsRow company={companyPoster?.company} />
				</SLeft>
				<SRight isPortrait={isPortrait}>
					<SGridHolder>
						<SGrid style={{ gridGap: '.2rem' }}>
							{getValues()}
						</SGrid>
					</SGridHolder>
					<SLine />
					<SGridHolder>
						<Text dark bold H3>{companyPoster?.company?.description ?? ''}</Text>
					</SGridHolder>
				</SRight>
				<Column isPortrait={isPortrait} company={companyPoster?.company} />
			</SPanel>

			<SBigImage active={bigImage} onClick={() => setBigImage(false)}>
				<SBackground />
				<CloseButton onClick={() => setBigImage(false)} />
				<Image contain style={{ width: '85%', height: '85%' }} url={companyPoster?.company?.fullImage} />
			</SBigImage>
		</SCompanyPoster>
	)
}

const SSocialsRow = styled.div`
	overflow: hidden;
	overflow-x: auto;
`

const SHorizontalGrid = styled.div`
	display: grid;
	grid-gap: .5rem;
	grid-auto-flow: column;
	width: min-content;
	height: 100%;
`

const SSocial = styled.div`
	background-color: ${p => p.theme.primary};
	border-radius: ${p => p.theme.roundCorners};
	width: 1.5rem;
	height: 1.5rem;
	cursor: pointer;
	padding: .5rem;
`

function SocialsRow({ company }: { company: any }) {

	function getButtons(): any[] {
		const buttons = []
		function add(url: string, icon: string): void {
			if (!url) return

			buttons.push(
				<SSocial key={icon} onClick={() => window.open(url)}>
					<SVG contain image={icon} />
				</SSocial>)
		}

		add(company?.facebook, icons.facebook)
		add(company?.instagram, icons.instagram)
		add(company?.twitter, icons.twitter)
		add(company?.linkedin, icons.linkedin)
		add(company?.youtube, icons.youtube)
		add(company?.whatsapp, icons.whatsapp)


		return buttons
	}

	return (
		<SSocialsRow>
			<SHorizontalGrid>
				{getButtons()}
			</SHorizontalGrid>
		</SSocialsRow>
	)
}

const SColumn = styled.div`
	overflow: auto;
`

const SButton = styled.div`
	display: grid;
	grid-gap: .5rem;
	place-items: center;
`

function Column({ company, isPortrait }: { company: any, isPortrait: boolean }) {
	const [store] = useStore()
	const [userMoreInfo] = useUserMoreInfo()

	function getButtons(): any[] {
		const buttons = []
		function add(url: string, icon: string, title: string): void {
			if (!url) return

			buttons.push(
				<SButton>
					<SSocial key={icon} onClick={() => window.open(url)}>
						<SVG contain image={icon} />
					</SSocial>
					<Text center bold online dark>{title}</Text>
				</SButton>)
		}

		try {
			const user = (Object.values(store.users) as any[]).find(u => u.email === company.email)

			if (user) buttons.push(
				<SButton>
					<SSocial key='contacto' onClick={() => userMoreInfo.setUser(user)}>
						<SVG contain image={icons.chat} />
					</SSocial>
					<Text center bold online dark>Contacto</Text>
				</SButton>
			)

		} catch { }

		add(company?.email ? `mailto:${company.email}?subject=Consulta` : undefined, icons.email, 'Email')
		add(company?.web, icons.web, 'Website')
		add(company?.file, icons.downlaod, 'Descarga')

		return buttons
	}

	const style = isPortrait ? {
		gridAutoFlow: 'column',
		width: 'min-content',
		paddingBottom: '2rem'
	} : {}

	return (
		<SColumn>
			<SGridHolder>
				<SGrid style={style}>
					{getButtons()}
				</SGrid>
			</SGridHolder>
		</SColumn>
	)
}
