// firebase/index
export const firebaseConfig = {
	apiKey: "AIzaSyCsw4Ni_P2iR3WnvBl_Rg8xVRe-f5UBfkw",
	authDomain: "ucvopencampuss.firebaseapp.com",
	projectId: "ucvopencampuss",
	storageBucket: "ucvopencampuss.appspot.com",
	messagingSenderId: "370564109283",
	appId: "1:370564109283:web:74b3e3109ead77fced06d6",
	measurementId: "G-ZKE8SVSC1H"
}

// firebase/storage
export const storageUrl = 'https://firebasestorage.googleapis.com/v0/b/ucvopencampuss.appspot.com/o/'