import React, { useState } from 'react'
import styled from 'styled-components'
import { useStore, useAuth, usePopup } from '../../hooks'
import { Text, Button, Background, Image, FadeInUp, UploadFile, InputField, IconButton, SVG } from '../UI'
import Dropdown from '../Dropdown'
import closeIcon from '../../graphics/icons/arrow-left.svg'
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer'
import List from 'react-virtualized/dist/commonjs/List'
import { deleteUser } from '../../utility'

import { downloadUsersCSV } from '../../utility/analyticsCSV'
import { getContent } from '../../firebase/database'
import { databasePaths } from '../../firebase'
import Loading from '../Loading'

const LIST = 0
const SELECTED = 1

export default function Users(props) {
	const [state, setState] = useState({
		menu: LIST,
		selected: null,
	})

	// Get UI content.
	const getContent = () => {
		if (state.menu === LIST) return <UserList
			onUserSelected={u => setState({ ...state, selected: u, menu: SELECTED })}
		/>
		if (state.menu === SELECTED) return <Edit
			backHandler={() => {
				setState({ ...state, selected: null, menu: LIST })
			}}
			user={state.selected}
			showAnalytics={props.showAnalytics}
		/>
	}

	return getContent()
}

const ListContainer = styled.div`
    padding: 1rem;
    height: 100%;
`

const TitleRow = styled.div`
    height: 3rem;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
`

const FilterRow = styled.div`
    height: 2rem;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(4, 1fr);
`

const CheckBoxRow = styled.div`
    height: 100%;
    display: grid;
    grid-template-columns: 2rem auto;
    grid-gap: 1rem;
    cursor: pointer;
    transition: .3s;
    :hover {
        transform: translateY(.2rem);
    }
`

let controlPanelLoadingUsers = false;

function UserList(props) {
	const [store, dispatch] = useStore()
	const auth = useAuth()
	const [state, setState] = useState({
		search: '',
		onlyAdmins: false,
		onlyOrganizers: false,
		onlyExpositors: false,
		onlyViewers: false,
		loading: false
	})

	if ((!store?.loadedUsers || store.loadedUsers !== "all") && !controlPanelLoadingUsers) {
		setState(prev => { return { ...prev, loading: true } });
		controlPanelLoadingUsers = true;
		getContent(databasePaths.users).then(users => {
			dispatch({type: "load-update-user", payload: users })
			dispatch({type: "load-users-loaded", payload: 'all' });
			setState(prev => { return { ...prev, loading: false } });
		})
	}

	const filteredUsers = store.users ? Object.values(store.users)
		.filter(u => u.email && u.name)
		.filter(u => (u.email.toLowerCase().includes(state.search.toLowerCase()) ||
			u.name.toLowerCase().includes(state.search.toLowerCase())))
		.filter(u => {
			if (!state.onlyAdmins && !state.onlyOrganizers && !state.onlyExpositors && !state.onlyViewers) return true
			if (u.type === 'admin') return state.onlyAdmins
			if (u.type === 'organizer') return state.onlyOrganizers
			if (u.type === 'expositor') return state.onlyExpositors
			if (u.type === 'normal') return state.onlyViewers
		})
		.sort((a, b) => {
			const aDate = new Date(a.creationTime ?? '01/01/2020')
			const bDate = new Date(b.creationTime ?? '01/01/2020')

			return aDate < bDate
		}) : []

	// Get users list, filtered by search.
	const getList = () => {
		return filteredUsers.map(u => <UserTag onClick={() => props.onUserSelected(u)} key={u.id} user={u} />)
	}

	const list = getList()

	list.unshift(
		<FilterRow>
			<CheckBoxRow onClick={() => setState(prev => { return { ...prev, onlyAdmins: !prev.onlyAdmins } })}>
				<Checkbox selected={state.onlyAdmins} />
				<Text bold middle H2>Administradores</Text>
			</CheckBoxRow>
			<CheckBoxRow onClick={() => setState(prev => { return { ...prev, onlyOrganizers: !prev.onlyOrganizers } })}>
				<Checkbox selected={state.onlyOrganizers} />
				<Text bold middle H2>Organizadores</Text>
			</CheckBoxRow>
			<CheckBoxRow onClick={() => setState(prev => { return { ...prev, onlyExpositors: !prev.onlyExpositors } })}>
				<Checkbox selected={state.onlyExpositors} />
				<Text bold middle H2>Expositores</Text>
			</CheckBoxRow>
			<CheckBoxRow onClick={() => setState(prev => { return { ...prev, onlyViewers: !prev.onlyViewers } })}>
				<Checkbox selected={state.onlyViewers} />
				<Text bold middle H2>Visitantes</Text>
			</CheckBoxRow>
		</FilterRow>
	)

	function canDownloadUsers() {
		if (auth.isAdmin()) return true
		if (auth.isOrganizer()) return store.configurations?.featureDownloadAllUsers
		return false
	}

	list.unshift(
		<TitleRow key='search'>
			<Text H1 bold oneline>Usuarios: {list?.length || 0}</Text>
			{canDownloadUsers() && <Button onClick={() => downloadUsersCSV(store, store?.configurations?.userExtraInfo, filteredUsers)}>Descargar</Button>}
			<InputField
				value={state.search}
				onChange={v => setState({ ...state, search: v })}
				placeholder='Buscar usuario'
			/>
		</TitleRow>)

	const rowRenderer = ({ key, index, style }) => {
		return (
			<div key={key} style={style}>
				{list[index]}
			</div>
		)
	}

	return ((state.loading && <Loading />) || (!state.loading &&
		<ListContainer>
			<AutoSizer>
				{
					({ height, width }) => (
						<FadeInUp>
							<List
								height={height}
								rowCount={list ? list.length : 0}
								rowHeight={64}
								rowRenderer={rowRenderer}
								width={width}
							/>
						</FadeInUp>
					)
				}
			</AutoSizer>
		</ListContainer>)
	)
}

const UserTagContainer = styled.div`
    display: grid;
    cursor: pointer;
    grid-template-columns: 2rem 1fr 1fr min-content min-content;
    padding: .5rem 1rem;
    grid-gap: 1rem;
    background-color: ${p => p.theme.medium};
    transition: .3s;
    height: 2rem;
    :hover {
        transform: translateY(.2rem);
    }
`

const TagIcon = styled.div`
    border-radius: 50%;
    box-sizing: border-box;
    overflow: hidden;
`

function UserTag(props) {
	const getTypeText = () => {
		if (props.user.type === 'admin') return 'Administrador'
		if (props.user.type === 'organizer') return 'Organizador'
		if (props.user.type === 'expositor') return 'Expositor'
		if (props.user.type === 'normal') return 'Visitante'
	}

	return (
		<UserTagContainer onClick={props.onClick}>
			<TagIcon><Image url={props.user.profile ? props.user.profile : 'profile.svg'} /></TagIcon>
			<Text H2 bold>{`${props.user.name ? props.user.name : ''} ${props.user.lastName ? props.user.lastName : ''}`}</Text>
			<Text H2 bold>{props.user.email}</Text>
			<Text oneline H2 bold>{props.user.creationTime ?? ''}</Text>
			<Text oneline H2 bold>{getTypeText()}</Text>
		</UserTagContainer>
	)
}

const EditContainer = styled.div`
    display: grid;
    grid-gap: 2rem;
    padding: 2rem;
`

const Checkbox = styled.div`
    background-color: ${p => p.selected ? p.theme.primary : p.theme.light};
    border: .2rem solid ${p => p.theme.light};
    box-sizing: border-box;
    transition: .3s;
`

const CloseButton = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
`

const PermissionRow = styled.div`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 2rem 2rem auto;
    height: 2rem;
    cursor: pointer;
    transition: .3s;
    :hover {
        transform: translateY(.2rem);
    }
`

function Edit(props) {
	const [store, dispatch] = useStore()
	const [user, setUser] = useState(props.user)
	const auth = useAuth()

	// Get companies options.
	const companiesOptions = store.companies ? Object.values(store.companies) : []

	const selectCompanyHandler = id => {
		const u = user
		if (!u.typeData) u.typeData = {}
		u.typeData.company = id
		saveUser(u)
	}

	const isAdmin = auth.isAdmin()
	const isOrganizer = auth.isOrganizer()

	const saveUser = u => {
		setUser(u)
		dispatch({ type: 'set-user', payload: u })
	}

	function deleteHandler() {
		if (!props.user.id) return

		props.backHandler()
		deleteUser(props.user.id)
	}

	// Check if user is enabled to delete users.
	function canDeleteUsers() {
		if (isAdmin) return true
		if (isOrganizer) return store.configurations?.featureDeleteUsers
		return false
	}

	return (
		<EditContainer>
			<CloseButton><IconButton onClick={props.backHandler} image={closeIcon} /></CloseButton>
			<TagIcon style={{ height: '7rem', width: '7rem' }}><Image url={user.profile ? user.profile : 'profile.svg'} /></TagIcon>
			<Text H1 bold>{`${user.name ? user.name : ''} ${user.lastName ? user.lastName : ''}`}</Text>
			<Text H2 bold>Fecha de Ingreso: {user.creationTime}</Text>
			<Button onClick={() => props.showAnalytics(user.id)} style={{ width: '20rem', height: '2rem' }}>Ver Actividad</Button>
			<Text H1 bold>Pase universal de area</Text>
			<PermissionRow onClick={() => saveUser({ ...user, universalAccess: !user.universalAccess })}>
				<Checkbox selected={user.universalAccess} />
				<SVG />
				<Text H2 bold>Permiso universal</Text>
			</PermissionRow>
			<Text H1 bold>Permisos de usuario</Text>
			{isAdmin && <PermissionRow onClick={() => saveUser({ ...user, type: 'admin' })}>
				<Checkbox selected={user.type === 'admin'} />
				<SVG />
				<Text H2 bold>Administrador</Text>
			</PermissionRow>}
			<PermissionRow onClick={() => saveUser({ ...user, type: 'organizer' })}>
				<Checkbox selected={user.type === 'organizer'} />
				<SVG />
				<Text H2 bold>Organizador</Text>
			</PermissionRow>
			<PermissionRow onClick={() => saveUser({ ...user, type: 'expositor' })}>
				<Checkbox selected={user.type === 'expositor'} />
				<SVG />
				<Text H2 bold>Expositor</Text>
			</PermissionRow>
			<PermissionRow onClick={() => saveUser({ ...user, type: 'normal' })}>
				<Checkbox selected={user.type === 'normal'} />
				<SVG />
				<Text H2 bold>Visitante</Text>
			</PermissionRow>
			{user?.type === 'expositor' && <Text H1 bold>Empresa del Expositor</Text>}
			{user?.type === 'expositor' && <Dropdown
				style={{ width: '20rem' }}
				placeholder='Seleccionar una empresa'
				options={companiesOptions}
				onSelect={selectCompanyHandler}
				selected={store?.companies && store?.companies[user?.typeData?.company]}
			/>}

			<Text H1 bold>Bloquear usuario</Text>
			<PermissionRow onClick={() => saveUser({ ...user, banFromChat: !user.banFromChat })}>
				<Checkbox selected={user.banFromChat} />
				<SVG />
				<Text H2 bold>Bloquear acceso al chat.</Text>
			</PermissionRow>
			<PermissionRow onClick={() => saveUser({ ...user, banFromNavegation: !user.banFromNavegation })}>
				<Checkbox selected={user.banFromNavegation} />
				<SVG />
				<Text H2 bold>Bloquear navegación.</Text>
			</PermissionRow>

			{canDeleteUsers() && <Button onClick={() => deleteHandler()} style={{ width: '20rem', height: '2rem' }}>Eliminar Usuario</Button>}

			<div /><div /><div /><div /><div /><div />
		</EditContainer>
	)
}