import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { Image } from '../components/UI'
import View from '../components/View'
import FloatingButton from '../components/FloatingButton'
import MobileArea from '../components/MobileArea'
import PresentationImages from '../components/PresentationImages'
import { useParam, useStore } from '../hooks'
import PasswordPanel from '../components/PasswordPanel'
import { logEvent } from '../firebase/analytics'

import { useIsPortrait, useAuth, useElementsManager } from '../hooks'

const Container = styled.div`
    position: relative;
    height: ${p => p.isPortrait ? '40vh' : '100vh'};
`

export default function VideoRoom() {
	const isPortrait = useIsPortrait()
	const id = useParam('id')
	const [store, dispatch] = useStore()
	const [videoRoom, setVideoRoom] = useState({})
	const [components, setComponents] = useState([])
	const auth = useAuth()
	const elementsManager = useElementsManager()

	useEffect(() => {
		if (!store || !store.videoRooms || !store.videoRooms[id]) return
		let vr = store.videoRooms[id]
		setVideoRoom(vr)

		const c = []
		if (vr.backgrounds) c.push(<PresentationImages key='backgrounds' backgrounds={vr.backgrounds} />)
		if (vr.background) c.push(<Image fit={!isPortrait} cover={isPortrait} key='bg' url={vr.background} />)
		if (vr.password && !auth?.user?.universalAccess && (!vr.whitelist || !(auth?.user?.id in vr.whitelist))) c.push(<PasswordPanel password={vr.password} title={vr.passwordTitle} />)

		if (isPortrait) {
			if (vr.views || vr.buttons)
				c.push(<MobileArea
					views={elementsManager.state.panels ? vr.views : undefined}
					buttons={elementsManager.state.buttons ? vr.buttons : undefined} />
				)
		}
		else {
			if (vr.views && elementsManager.state.panels) c.push(Object.values(vr.views).map(view => <View key={view.id} view={view} />))
			if (vr.buttons && elementsManager.state.buttons) c.push(Object.values(vr.buttons).map(btn => <FloatingButton key={btn.id} button={btn} />))
		}
		setComponents(c)
	}, [store, elementsManager.state])

	useEffect(() => {
		if (videoRoom.name) logEvent(videoRoom.name)
	}, [videoRoom.id])

	return (
		<Container isPortrait={isPortrait}>
			{components}
		</Container>
	)
}