import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { SVG } from './UI'

import buttonsIcon from '../graphics/icons/grid.svg'
import FloatingButton from './FloatingButton'
import View from './View'

import chatIcon from '../graphics/icons/chat.svg'
import videoIcon from '../graphics/icons/video.svg'
import imageIcon from '../graphics/icons/photo.svg'
import webIcon from '../graphics/icons/monitor.svg'
import searchIcon from '../graphics/icons/search.svg'
import icons from '../graphics/icons'

const ShowNavegationButtons = false

const Container = styled.div`
    position: fixed;
    width: 100vw;
	top: 40vh;
    bottom: 0;
	box-sizing: border-box;
	padding-top: 1rem;
    background: linear-gradient(0deg, rgba(40, 40, 40, 1) 70%, rgba(40, 40, 40, .3) 100%);
`

const SGrid = styled.div<{ topBar?: boolean, stand?: boolean }>`
	height: 100%;
    display: grid;
    ${p => p.topBar && css` grid-template-rows: auto 5rem; `}
`

const TopBarContainer = styled.div`
    /* overflow: auto; */
    border-bottom: solid .1rem ${p => p.theme.light};
`

const TopBarGrid = styled.div`
    display: grid;
    /* grid-auto-columns: 5rem; */
    grid-auto-flow: column;
    height: 100%;
    grid-gap: 1rem;
`

const TabButtonContainer = styled.div<{ selected?: boolean }>`
    transition: .1s;
    border-bottom: solid ${p => p.selected ? '.2rem' : 0} ${p => p.theme.light};

    display: flex;
    justify-content: center;
    align-items: center;
`

const TabIconContainer = styled.div`
    width: 2rem;
    height: 2rem;
`

const ContentContainer = styled.div`
    overflow: auto;
    overflow-x: hidden;
    padding: 0 1rem;
`

const ContentButtonsContainer = styled.div`
    display: grid;
    grid-gap: 1rem;
	grid-template-columns: repeat(3, 1fr);
`

const ContentViewContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
`

interface IProps {
	views: any[],
	buttons: any[]
}

interface IMobileArea {
	selectedTab?: string,
	setSelectedTab(t: string): void,
	save(): void
}

export default function MobileArea(props) {
	const [state, set] = useState<IMobileArea>({
		setSelectedTab(t) {
			this.selectedTab = t
			this.save()
		},
		save() { set({ ...this }) }
	})

	useEffect(() => {
		let hasButtons = false

		if (props.buttons && (Object.values(props.buttons) as any[]).some(b => !b.hideInMobile)) {
			if (ShowNavegationButtons && (Object.values(props.buttons) as any[]).some(b => b.type === 'navegation')) state.setSelectedTab('navegation')
			else state.setSelectedTab('buttons')

			hasButtons = true
		}

		if (props.views) {
			for (const view of Object.values(props.views) as any[]) {
				if (view.items && !view.hideInMobile) {
					let doBreak = false
					for (const item of Object.values(view.items) as any[]) {
						// if (item.type === 'video' || item.type === 'vimeo' || !hasButtons) {
						if (!hasButtons) {
							state.setSelectedTab(view.id)
							doBreak = true
							break
						}
					}

					if (doBreak) break
				}
			}
		}

	}, [props.views, props.buttons])

	function getViewIcon(view) {
		if (view.items) {
			const type = (Object.values(view.items) as any[])[0].type
			if (type === 'video' || type === 'vimeo') return videoIcon
			if (type === 'image') return imageIcon
			if (type === 'chat') return chatIcon
			if (type === 'stands') return searchIcon
			if (type === 'web') return webIcon
			if (type === 'companies') return icons.stand
			if (type === 'promos') return icons.game
		}
	}

	function getTabButtons() {
		const tabButtons = []
		if (props.buttons) {
			const navegationButtons = []
			const otherButtons = [];

			(Object.values(props.buttons) as any[]).forEach(button => {
				if (!button.hideInMobile) {
					if (button.type === 'navegation' && ShowNavegationButtons) navegationButtons.push(button)
					else otherButtons.push(button)
				}
			})

			if (navegationButtons.length != 0 && ShowNavegationButtons) {
				tabButtons.push(<TabButtonContainer key='navegation' selected={state.selectedTab === 'navegation'} onClick={() => state.setSelectedTab('navegation')}>
					<TabIconContainer>
						<SVG image={icons.menu} />
					</TabIconContainer>
				</TabButtonContainer>)

				if (!state.selectedTab) state.setSelectedTab('navegation')
			}

			if (otherButtons.length != 0) {
				tabButtons.push(<TabButtonContainer key='buttons' selected={state.selectedTab === 'buttons'} onClick={() => state.setSelectedTab('buttons')}>
					<TabIconContainer>
						<SVG image={buttonsIcon} />
					</TabIconContainer>
				</TabButtonContainer>)

				if (!state.selectedTab) state.setSelectedTab('buttons')
			}
		}

		if (props.views) {
			(Object.values(props.views) as any[]).forEach(view => {
				if (!view.hideInMobile) {
					tabButtons.push(<TabButtonContainer key={view.id} selected={state.selectedTab === view.id} onClick={() => state.setSelectedTab(view.id)}>
						<TabIconContainer>
							<SVG image={getViewIcon(view)} />
						</TabIconContainer>
					</TabButtonContainer>)

					if (!state.selectedTab && view.id) state.setSelectedTab(view.id)
				}
			})
		}

		return tabButtons
	}

	function getButtons() {
		try {
			return (Object.values(props.buttons) as any[])
				.filter(b => {
					const isNav = b.type === 'navegation'
					return state.selectedTab === 'navegation' ? isNav : !isNav
				})
				.map(b => <FloatingButton key={b.id} button={b} />)
		} catch { }
	}

	function getView() {
		try {
			if (props.views[state.selectedTab]) return <View view={props.views[state.selectedTab]} />
		} catch { }
	}

	function getContent() {
		if (state.selectedTab) {
			if (state.selectedTab === 'buttons' || state.selectedTab === 'navegation') return <ContentButtonsContainer> {getButtons()} </ContentButtonsContainer>
			else return <ContentViewContainer>{getView()}</ContentViewContainer>
		}
	}

	const tabButtons = getTabButtons()

	return (
		<Container>
			<SGrid topBar={tabButtons.length > 1} stand={window.location.pathname === '/stand'}>
				<ContentContainer> {getContent()} </ContentContainer>
				{tabButtons.length > 1 && <TopBarContainer><TopBarGrid>{tabButtons}</TopBarGrid></TopBarContainer>}
			</SGrid>
		</Container>
	)
}
