import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useCatalog, useStore, useShoppingList, useIsPortrait } from '../../hooks'

import { IShoppingList } from '../../context/ShoppingListContext'

import { Text, Image, Button } from '../UI'
import { MultiDropdown, CloseButton, AmountSelector } from '../NewUI'
import { useHistory } from 'react-router-dom'

interface Dictionary<T> { [key: string]: T }

const SProduct = styled.div<{ active: boolean }>`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 3;

    opacity: ${p => p.active ? 1 : 0};
    pointer-events: ${p => p.active ? 'all' : 'none'};
	${p => !p.active && css`
		transform: translateY(-.5rem);
	`}
`

const SPanel = styled.div<{ isPortrait: boolean }>`
	background-color: ${p => p.theme.light};
	border-radius: ${p => p.theme.roundCorners};
	width: 60vw;
	height: 80vh;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	box-sizing: border-box;
	position: relative;
	/* overflow: hidden; */

	${p => p.isPortrait && css`
		width: 95vw;
		height: 95vh;
	`}
`

const SPanelGrid = styled.div<{ isPortrait: boolean }>`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 1rem ;
	display: grid;
	grid-template-columns: 1fr 1.2fr;
	grid-template-rows: 100%;

	${p => p.isPortrait && css`
		grid-template-columns: 100%;
		grid-template-rows: min-content min-content;
		overflow: auto;
	`}

	grid-gap: 1rem;
`

const SBackground = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${p => p.theme.dark};
    opacity: .85;
	cursor: pointer;
`

const SColumn = styled.div`
	display: grid;
	grid-gap: .5rem;
	box-sizing: border-box;
	overflow: auto;
	grid-template-rows: auto min-content min-content min-content;
`

const SLeftColumn = styled.div`
	display: grid;
	grid-gap: .5rem;
	box-sizing: border-box;
	grid-template-rows: min-content min-content min-content auto;
`

const SGridHolder = styled.div`
	overflow: auto;
`

const SGrid = styled.div`
	display: grid;
	grid-gap: .5rem;
`

const SAmount = styled.div`
	display: grid;
	grid-auto-flow: column;
	grid-gap: 1rem;
	margin-left: auto;
`

interface IStateProduct {
	dropdowns: Dictionary<Dictionary<string>>,
}

export default function Product() {
	const [catalog, setCatalog] = useCatalog()
	const [store] = useStore()
	const shoppingList = useShoppingList() as IShoppingList
	const history = useHistory()
	const [state, set] = useState<IStateProduct>({
		dropdowns: {},
	})
	const isPortrait = useIsPortrait()
	const product = catalog.product ?? {}
	const category = store?.productCategories ? store?.productCategories[product.category] ?? {} : {}

	function addItemHandler(amount: number): void {
		if (!catalog.stand || !product.id) return

		if (catalog.purchaseId) shoppingList.editItem(catalog.purchaseId, amount) // Edit item.
		else shoppingList.addItem(catalog.stand.id, product.id, amount) // Add new item to shopping list.

		closeHandler()
	}

	function getDropdownOptions(dropdown): Dictionary<{ id: string, name: string, value: boolean }> {
		if (!category?.dropdowns[dropdown]) return {}

		return (Object.values(category.dropdowns[dropdown].options) as { id: string, name: string, order: string }[])
			.sort((a, b) => {
				const av = a.order ? parseFloat(a.order) : Number.MAX_SAFE_INTEGER
				const bv = b.order ? parseFloat(b.order) : Number.MAX_SAFE_INTEGER
				return av > bv ? 1 : -1
			})
			.reduce((obj, option) => ({
				...obj,
				[option.id]: {
					id: option.id,
					name: option.name,
					value: state.dropdowns[dropdown] && option.id in state.dropdowns[dropdown]
				}
			}), {})
	}

	function getImages(): string[] {
		const images: string[] = []

		if (product.image) images.push(product.image)
		if (product.images) (Object.values(product.images) as string[]).forEach(i => images.push(i))

		return images
	}

	function viewCatalogHandler(): void {
		setCatalog(p => ({
			...p,
			product: product,
			search: '',
			active: true
		}))
		try { history.push(`/stand?pavillionId=${store?.pavillions[catalog.stand.pavillion].id}&id=${catalog.stand.id}`) } catch { }
		closeHandler()
	}

	function closeHandler(): void {
		setCatalog(p => ({ ...p, product: undefined, productActive: false }))
	}

	function getExtraData() {
		const ignore = ['description', 'id', 'image', 'images', 'name', 'EAN', 'price']
		const list = []
		for (const [key, value] of Object.entries(product)) {
			if (ignore.includes(key)) continue
			list.push(<Text oneline H2 bold dark key={key}>{key}</Text>)
			list.push(<Text oneline H2 dark key={value}>{value}</Text>)
		}

		return list
	}

	return (
		<SProduct active={catalog.productActive}>
			<SBackground onClick={() => closeHandler()} />
			<SPanel isPortrait={isPortrait}>
				<CloseButton onClick={() => closeHandler()} />
				<SPanelGrid isPortrait={isPortrait}>
					<SLeftColumn>
						<Text H2 bold dark>{catalog?.stand?.name}</Text>
						<Text oneline H1 bold dark>$ {product.price ?? 0}</Text>
						<Text H1 bold dark>{product.name}</Text>
						<SGridHolder>
							<SGrid>
								<Text oneline H2 bold dark>Descripción</Text>
								<Text H2 dark>{product.description}</Text>
								{getExtraData()}
							</SGrid>
						</SGridHolder>
						{category?.dropdowns && <SGridHolder styled={{ height: '10rem' }}>
							<SGrid>
								{Object.keys(category.dropdowns).map(dropdown =>
									<MultiDropdown
										singleOption
										primary
										clickHandler={id => {
											set(p => {
												if (!p.dropdowns[dropdown]) p.dropdowns[dropdown] = {}

												if (id in p.dropdowns[dropdown]) delete p.dropdowns[dropdown][id]
												else p.dropdowns[dropdown][id] = id

												return { ...p }
											})
										}}
										options={getDropdownOptions(dropdown)}
										title={category?.dropdowns[dropdown]?.name ?? ''}
									/>
								)}
							</SGrid>
						</SGridHolder>}
					</SLeftColumn>
					<SColumn>
						<Images images={getImages()} />
						<SAmount>
							<Text oneline dark bold H2 middle>Cantidad</Text>
							<AmountSelector
								min={1}
								onChange={amount => setCatalog(p => ({ ...p, amount }))}
								value={catalog.amount}
							/>
						</SAmount>
						<Button onClick={() => addItemHandler(catalog.amount)}>{catalog.purchaseId ? 'Editar' : 'Agregar al Carrito'}</Button>
						<Button onClick={() => viewCatalogHandler()}>Ver Catálogo</Button>
					</SColumn>
				</SPanelGrid>
			</SPanel>
		</SProduct >
	)
}

const SImages = styled.div<{ onClick?: any }>`
	display: grid;
	grid-template-rows: auto 4rem;
	grid-gap: 1rem;
	${p => p.onClick && css`
		cursor: pointer;
		filter: brightness(110%);
	`}
`

const SImage = styled.div<{ isPortrait: boolean }>`
	overflow: hidden;
	${p => p.isPortrait && css`
		height: 10rem;
	`}
`

const SSmallImages = styled.div`
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: 7rem;
	grid-gap: 1rem;
`

interface IImagesState {
	selected?: string,
}

function Images(props: { images?: string[] }) {
	const [state, set] = useState<IImagesState>({})
	const isPortrait = useIsPortrait()

	useEffect(() => {
		if (props.images) set(p => ({ ...p, selected: props.images[0] }))
	}, [props.images])

	function getImages() {
		if (props.images) return props.images.map((image, i) =>
			<SImage key={i} onClick={() => set(p => ({ ...p, selected: image }))}>
				<Image contain url={image} />
			</SImage>
		)
	}

	return (
		<SImages>
			<SImage isPortrait={isPortrait}>
				<Image contain url={state.selected} />
			</SImage>
			<SSmallImages>
				{getImages()}
			</SSmallImages>
		</SImages>
	)
}
