import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import ViewSettings from '../components/ViewSettings'

import { Text, TextIcon, FadeInUp, IconButton, Button } from '../components/UI'
import { URLs } from '../context/constants'
import Auth from '../context/AuthContext'

import { useStore, useIsPortrait, useLanguage } from '../hooks'

import Companies from '../components/control-panel/Companies'
import Stands from '../components/control-panel/Stands'
import Profile from '../components/control-panel/Profile'
import Areas from '../components/control-panel/Areas'
import Users from '../components/control-panel/Users'
import Configurations from '../components/control-panel/Configurations'
import Analytics from '../components/control-panel/Analytics'
import ExternalAnalytics from '../components/control-panel/ExternalAnalytics'
import Ranking from '../components/control-panel/Ranking'
import Notifications from '../components/control-panel/Notifications'

import closeIcon from '../graphics/icons/close.svg'
import menuIcon from '../graphics/icons/burgerMenu.svg'

import profileIcon from '../graphics/icons/profile.svg'
import usersIcon from '../graphics/icons/team.svg'
import areasIcon from '../graphics/icons/monitor.svg'
import standsIcon from '../graphics/icons/stand.svg'
import companyIcon from '../graphics/icons/company.svg'
import configIcon from '../graphics/icons/settings.svg'
import analyticsIcon from '../graphics/icons/line-chart.svg'
import rankingIcon from '../graphics/icons/star.svg'
import bellIcon from '../graphics/icons/bell.svg'

import config from '../config';

const Container = styled.div`
    position: fixed;
    height: 100vh;
    width: 100vw;
    background-color: ${p => p.theme.dark};

    display: grid;
    grid-template-columns: ${p => p.menu ? '25rem' : 0} auto;
    transition: .3s;
    overflow: scroll;
    -webkit-overflow-scrolling: scroll;

    z-index: 4;
`

const Left = styled.div`
    transition: .3s;
    overflow: hidden;
    border-right: .1rem solid ${p => p.theme.light};
    position: relative;
`

const Right = styled.div`
    padding: 3rem;
    position: relative;
    overflow: scroll;
    -webkit-overflow-scrolling: scroll;
`

const Menu = styled.div`
    padding: 4rem;
    display: grid;
    grid-auto-flow: row;
    grid-gap: 2rem;
`

const CloseButton = styled.div`
    position: absolute;
    top: .5rem;
    left: .5rem;
    width: 2.5rem;
    height: 2.5rem;
    z-index: 4;
`

const BurgerButton = styled.div`
    position: absolute;
    top: 0;
    left: 3.5rem;
    width: 2.5rem;
    height: 2.5rem;
    z-index: 4;
`

const LogoutButton = styled.div`
    position: absolute;

    bottom: 1rem;
    right: 1rem; 
    left: 1rem;
    height: 2rem;
`

const Version = styled.div`
    position: absolute;
    bottom: 4rem;
    width: 100%;
`

export default function ControlPanel(props) {
	const history = useHistory()
	const auth = useContext(Auth)
	const [store] = useStore()
	const [tab, setTab] = useState('profile')
	const isPortrait = useIsPortrait()
	const [menu, setMenu] = useState(!isPortrait)
	const language = useLanguage()

	const [state, set] = useState({})

	if (!auth.user) history.push(URLs.home)

	const isAdmin = auth.isAdmin()
	const isOrganizer = auth.isOrganizer()
	const isExpositor = auth.isExpositor()

	function isFeatureEnabled(feature) {
		if (!store?.configurations) return false
		return store?.configurations[feature]
	}

	const getTabs = () => {
		const tabs = [<TextIcon selected={tab === 'profile'} onClick={() => setTab('profile')} image={profileIcon}>{language.getText('Perfil')}</TextIcon>]
		if (isAdmin || (isOrganizer && isFeatureEnabled('featureUsers'))) tabs.push(<TextIcon key='users' selected={tab === 'users'} onClick={() => setTab('users')} image={usersIcon}>Usuarios</TextIcon>)
		if (isAdmin || (isOrganizer && isFeatureEnabled('featureAreas'))) tabs.push(<TextIcon key='areas' selected={tab === 'areas'} onClick={() => setTab('areas')} image={areasIcon}>Áreas</TextIcon>)
		if (isAdmin || (isOrganizer && isFeatureEnabled('featureStands') || (isExpositor && isFeatureEnabled('efeatureStands')))) tabs.push(<TextIcon key='stands' selected={tab === 'stands'} onClick={() => setTab('stands')} image={standsIcon}>Stands</TextIcon>)
		if (isAdmin || (isOrganizer && isFeatureEnabled('featureCompanies')) || (isExpositor && isFeatureEnabled('efeatureCompanies'))) tabs.push(<TextIcon key='companies' selected={tab === 'companies'} onClick={() => setTab('companies')} image={companyIcon}>Empresas</TextIcon>)
		if (isAdmin || (isOrganizer && isFeatureEnabled('featureConfigurations'))) tabs.push(<TextIcon key='configurations' selected={tab === 'configurations'} onClick={() => setTab('configurations')} image={configIcon}>Configuración</TextIcon>)
		if (!store.configurations.disableAnalytics && isAdmin || (isOrganizer && isFeatureEnabled('featureAnalytics')) || (isExpositor && isFeatureEnabled('efeatureAnalytics'))) tabs.push(<TextIcon key='analytics' selected={tab === 'analytics'} onClick={() => setTab('analytics')} image={analyticsIcon}>Estadísticas</TextIcon>)
		if (!store.configurations.disableAnalytics && isAdmin || (isOrganizer && isFeatureEnabled('featureAnalytics')) || (isExpositor && isFeatureEnabled('externalAnalytics'))) tabs.push(<TextIcon key='external-analytics' selected={tab === 'external-analytics'} onClick={() => setTab('external-analytics')} image={analyticsIcon}>Integrar analíticas externas</TextIcon>)
		if (isAdmin || (isOrganizer && isFeatureEnabled('featureRanking'))) tabs.push(<TextIcon key='ranking' selected={tab === 'ranking'} onClick={() => setTab('ranking')} image={rankingIcon}>Ranking</TextIcon>)
		if (isAdmin || (isOrganizer && isFeatureEnabled('featureNotifications'))) tabs.push(<TextIcon key='notifications' selected={tab === 'notifications'} onClick={() => setTab('notifications')} image={bellIcon}>Notificación</TextIcon>)

		return tabs
	}

	const getTabContent = () => {
		switch (tab) {
			case 'profile': return <Profile showCloseButton={showCloseButton} onSave={() => history.goBack()} />
			case 'users': return <Users showAnalytics={userId => {
				if (!store.configurations.disableAnalytics && isAdmin || (isOrganizer && isFeatureEnabled('featureAnalytics')) || (isExpositor && isFeatureEnabled('efeatureAnalytics'))) {
					set(p => ({ ...p, analyticsSelectedUserId: userId }))
					setTab('analytics')
				}
			}} />
			case 'areas': return <Areas />
			case 'stands': return <Stands />
			case 'companies': return <Companies />
			case 'configurations': return <Configurations />
			case 'analytics': return <Analytics selectedUserId={state.analyticsSelectedUserId} clearSelectedUser={() => set(p => ({ ...p, analyticsSelectedUserId: undefined }))} />
			case 'external-analytics': return <ExternalAnalytics />
			case 'ranking': return <Ranking />
			case 'notifications': return <Notifications />
		}
	}

	const showCloseButton = () => {
		if (!auth.user) return false

		const keys = ['name', 'lastName']
		for (let key of keys) { if (!auth.user[key]) return false }
		if (store.configurations && store.configurations.userExtraInfo) {
			for (const data of Object.values(store.configurations.userExtraInfo)) {
				if ((data.type === 'string' || !data.type) && data.required && (!auth.user.extraInfo || !auth.user.extraInfo[data.id])) return false
			}
		}
		return true
	}

	return (
		<Container menu={menu}>
			{showCloseButton() && <CloseButton><IconButton onClick={() => history.goBack()} image={closeIcon} /></CloseButton>}
			{/* {isPortrait && <BurgerButton><IconButton onClick={() => setMenu(!menu)} image={menuIcon}/></BurgerButton>} */}

			<Left> <FadeInUp> <Menu>
				<Text H1 bold>{language.getText('Panel de control')}</Text>
				<div />
				{getTabs()}
			</Menu> </FadeInUp>

				{isAdmin && <Version><Text center H2>Version: {config.version}</Text></Version>}
				<LogoutButton onClick={() => auth.logOut()}><Button>{language.getText('Cerrar Sesión')}</Button></LogoutButton>
			</Left>
			<Right>{getTabContent()}</Right>
		</Container>
	)
}
